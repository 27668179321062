import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import Chart from "react-apexcharts";
import DataTableComponentForArea from "../../../Tables/DatatableForArea/DataTable/DataTableComponentForArea";
import Swal from "sweetalert2";
import UpdateTargetModel from "./Components/update_target_model";
import moment from "moment";
import Loader1 from "../../../../CommonElements/Spinner/loader";
import { getWeek } from "../../../../_helper/helper";

const TargetsAndControls = () => {
  const now = new Date();
  const year = now.getFullYear();
  const week = getWeek(now);
  const maxWeek = `${year}-W${String(week).padStart(2, "0")}`;

  let lastWeekYear = year;
  let lastWeek = week - 1;

  // Handle case when it’s the first week of the year
  if (lastWeek === 0) {
    lastWeekYear = year - 1;
    lastWeek = getWeek(new Date(lastWeekYear, 11, 31)); // Get the last week of the previous year
  }
  const minWeek = `${lastWeekYear}-W${String(lastWeek).padStart(2, "0")}`;

  const [filters, setFilters] = useState({
    week: "",
  });
  const [targetweek, setTargetweek] = useState("");
  const [factoryAreas, setFactoryAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [toggleSelection, setToggleSelection] = useState(false);
  const [loader, setLoader] = useState(false);

  const dummydata = [
    {
      week: 41,
      year: 2024,
      areas: [
        {
          area_id: "SA-1",
          owner: "Adil",
          last_week: {
            target: 100,
            alerts: 110,
          },
          current_week: {
            target: 100,
            alerts: 90,
          },
        },
        {
          area_id: "SA-2",
          owner: "Aftab",
          last_week: {
            target: 120,
            alerts: 130,
          },
          current_week: {
            target: 115,
            alerts: 95,
          },
        },
        {
          area_id: "SA-3",
          owner: "Arslan",
          last_week: {
            target: 140,
            alerts: 150,
          },
          current_week: {
            target: 135,
            alerts: 110,
          },
        },
        {
          area_id: "SA-4",
          owner: "Ayesha Khaliq",
          last_week: {
            target: 160,
            alerts: 170,
          },
          current_week: {
            target: 155,
            alerts: 125,
          },
        },
        {
          area_id: "SA-5",
          owner: "Dr Amjad",
          last_week: {
            target: 180,
            alerts: 185,
          },
          current_week: {
            target: 170,
            alerts: 140,
          },
        },
        {
          area_id: "SA-6",
          owner: "Meraj",
          last_week: {
            target: 130,
            alerts: 140,
          },
          current_week: {
            target: 125,
            alerts: 105,
          },
        },
        {
          area_id: "SA-7",
          owner: "Moazzam Ali",
          last_week: {
            target: 90,
            alerts: 100,
          },
          current_week: {
            target: 85,
            alerts: 70,
          },
        },
        {
          area_id: "SA-8",
          owner: "Muhammad Shahbaz",
          last_week: {
            target: 200,
            alerts: 210,
          },
          current_week: {
            target: 195,
            alerts: 165,
          },
        },
        {
          area_id: "SA-9",
          owner: "Muhammad Wasi",
          last_week: {
            target: 70,
            alerts: 80,
          },
          current_week: {
            target: 65,
            alerts: 55,
          },
        },
        {
          area_id: "SA-10",
          owner: "Nazir Sb",
          last_week: {
            target: 160,
            alerts: 170,
          },
          current_week: {
            target: 150,
            alerts: 130,
          },
        },
        {
          area_id: "SA-11",
          owner: "Sadia",
          last_week: {
            target: 100,
            alerts: 115,
          },
          current_week: {
            target: 95,
            alerts: 85,
          },
        },
        {
          area_id: "SA-12",
          owner: "Shafiq",
          last_week: {
            target: 110,
            alerts: 120,
          },
          current_week: {
            target: 105,
            alerts: 90,
          },
        },
        {
          area_id: "SA-13",
          owner: "Shahbaz",
          last_week: {
            target: 145,
            alerts: 155,
          },
          current_week: {
            target: 140,
            alerts: 120,
          },
        },
        {
          area_id: "SA-14",
          owner: "Sheraz",
          last_week: {
            target: 190,
            alerts: 200,
          },
          current_week: {
            target: 180,
            alerts: 160,
          },
        },
        {
          area_id: "SA-15",
          owner: "Umair Pervaiz",
          last_week: {
            target: 130,
            alerts: 140,
          },
          current_week: {
            target: 120,
            alerts: 100,
          },
        },
      ],
    },
    {
      week: 42,
      year: 2024,
      areas: [
        {
          area_id: "SA-1",
          last_week: {
            target: 100,
            alerts: 90,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Adil",
        },
        {
          area_id: "SA-2",
          last_week: {
            target: 115,
            alerts: 95,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Aftab",
        },
        {
          area_id: "SA-3",
          last_week: {
            target: 135,
            alerts: 110,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Arslan",
        },
        {
          area_id: "SA-4",
          last_week: {
            target: 155,
            alerts: 125,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Ayesha Khaliq",
        },
        {
          area_id: "SA-5",
          last_week: {
            target: 170,
            alerts: 140,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Dr. Amjad",
        },
        {
          area_id: "SA-6",
          last_week: {
            target: 125,
            alerts: 105,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Meraj",
        },
        {
          area_id: "SA-7",
          last_week: {
            target: 85,
            alerts: 70,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Moazzam Ali",
        },
        {
          area_id: "SA-8",
          last_week: {
            target: 195,
            alerts: 165,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Muhammad Shahbaz",
        },
        {
          area_id: "SA-9",
          last_week: {
            target: 65,
            alerts: 55,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Muhammad Wasi",
        },
        {
          area_id: "SA-10",
          last_week: {
            target: 150,
            alerts: 130,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Nazir Sb",
        },
        {
          area_id: "SA-11",
          last_week: {
            target: 95,
            alerts: 85,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Sadia",
        },
        {
          area_id: "SA-12",
          last_week: {
            target: 105,
            alerts: 90,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Shafiq",
        },
        {
          area_id: "SA-13",
          last_week: {
            target: 140,
            alerts: 120,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Shahbaz",
        },
        {
          area_id: "SA-14",
          last_week: {
            target: 180,
            alerts: 160,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Sheraz",
        },
        {
          area_id: "SA-15",
          last_week: {
            target: 120,
            alerts: 100,
          },
          current_week: {
            target: 0,
            alerts: 0,
          },
          owner: "Umair Pervaiz",
        },
      ],
    },
  ];

  const dummyAlertCount=[
    { area: "SA-1", current_week_target: 40, actual: 30 },
    { area: "SA-2", current_week_target: 10, actual: 10 },
    { area: "SA-3", current_week_target: 20, actual: 12 },
    { area: "SA-4", current_week_target: 56, actual: 22 },
    { area: "SA-5", current_week_target: 26, actual: 12 },
    { area: "SA-6", current_week_target: 76, actual: 56 },
    { area: "SA-7", current_week_target: 40, actual: 35 },
    { area: "SA-8", current_week_target: 20, actual: 8 },
    { area: "SA-9", current_week_target: 98, actual: 89 },
    { area: "SA-10", current_week_target: 112, actual: 100 },
    { area: "SA-11", current_week_target: 145, actual: 123 },
    { area: "SA-12", current_week_target: 40, actual: 14 },
    { area: "SA-13", current_week_target: 27, actual: 22 },
    { area: "SA-14", current_week_target: 59, actual: 46 },
    { area: "SA-15", current_week_target: 80, actual: 73 },
  ]
  const [targetsData, setTargetsData] = useState({
    safety_compliance: [
      { week: "Week 40", current_week_target: 15, actual: 90 },
      { week: "Week 41", current_week_target: 70, actual: 10 },
    ],
    target_areas: dummydata,
    filter_target_areas: dummydata,
    alert_count: [],
  });
  const [complianceChartData, setComplianceChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 300,
        stacked: false,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
          columnWidth: "25px",
          dataLabels: {
            position: "top",
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 280,
            },
            plotOptions: {
              bar: {
                barHeight: "30px",
                horizontal: true,
              },
            },
            yaxis: {
              labels: {
                rotate: -45,
                rorateAlways: true,
                formatter: function (value) {
                  return "W" + value.toString()?.split(" ")[1];
                },
              },
            },
            grid: {
              show: true,
              borderColor: "#90A4AE",
              strokeDashArray: 0,
              position: "back",
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
          },
        },
      ],
      colors: ["#0b76b7", "#41b2ef"],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        show: true,
        categories: targetsData?.safety_compliance?.map((i) => i?.week),
        labels: {
          show: true,
          offsetY: 0,
        },
      },
      yaxis: {
        show: true,
        forceNiceScale: true,
        min: 0,
        max: undefined,
        labels: {
          show: true,
          trim: true,
          style: {
            fontSize: "12px",
            fontWeight: "semibold",
            cssClass: "apexcharts-xaxis-label",
          },
          offsetY: 0,
        },
      },

      colors: ["#0b76b7", "#41b2ef"],
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        intersect: false,
        shared: true,
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#90A4AE",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        fontSize: "13px",
        fontFamily: "Proxima Nova, sans-serif",
        fontWeight: 400,
        // offsetX: -30,
        offsetY: 10,
        labels: {
          colors: "#757575",
        },
        markers: {
          fillColors: ["#0b76b7", "#41b2ef", "#83cdf6"], // Match legend colors to bars
          radius: 5, // Rounded borders of the legend markers
        },
      },
    },
    series: [
      {
        name: "Target",
        data: targetsData?.safety_compliance?.map((i) => i.current_week_target),
      },
      {
        name: "Alerts",
        data: targetsData?.safety_compliance?.map((i) => i.actual),
      },
    ],
  });

  const [alertCountChartData, setAlertCountChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 300,
        stacked: false,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 600,
            },
            plotOptions: {
              bar: {
                columnWidth: "60px",
                horizontal: true,
              },
            },
            yaxis: {
              labels: {
                rotate: -45,
                rorateAlways: true,
              },
            },
            grid: {
              show: true,
              borderColor: "#90A4AE",
              strokeDashArray: 0,
              position: "back",
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
          },
        },
      ],

      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
          columnWidth: "60%",
          dataLabels: {
            position: "top",
          },
        },
      },
      xaxis: {
        show: true,
        categories: targetsData?.alert_count?.map((i) => i?.area),
        labels: {
          show: true,
          offsetY: 0,
        },
      },
      yaxis: {
        show: true,
        forceNiceScale: true,
        min: 0,
        max: undefined,
        labels: {
          show: true,
          trim: true,
          style: {
            fontSize: "12px",
            fontWeight: "semibold",
            cssClass: "apexcharts-xaxis-label",
          },
          offsetY: 0,
        },
      },

      colors: ["#0b76b7", "#41b2ef"],
      fill: {
        opacity: 1,
      },

      dataLabels: {
        enabled: false,
        offsetX: -1,
        offsetY: 1,
        style: {
          fontSize: "9px",
          fontFamily: "Proxima Nova, sans-serif",
          colors: ["white", "black"],
        },
      },
      tooltip: {
        enabled: true,
        intersect: false,
        shared: true,
        x: {
          show: true,
          formatter: function (value) {
            return "Area: " + value;
          },
        },
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#90A4AE",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },

      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        fontSize: "13px",
        fontFamily: "Proxima Nova, sans-serif",
        fontWeight: 400,
        // offsetX: -30,
        offsetY: 10,
        labels: {
          colors: "#757575",
        },
        // formatter: (w) => {
        //   return w?.toUpperCase();
        // },
      },
    },
    series: [
      {
        name: "Target",
        // data:[],
        data: dummyAlertCount?.map((i) => i.current_week_target),
      },
      {
        name: "Alerts",
        data:[],
        data: dummyAlertCount?.map((i) => i.actual),
      },
    ],
  });

  useEffect(() => {
    setLoader(true);
    setFilters({
      ...filters,
      week: maxWeek,
    });

    setTargetweek(maxWeek);
    const current_week = moment().week();
    const current_year = moment().year();
    const current_week_data = dummydata?.filter(
      (item) => item?.week === current_week && item?.year === current_year
    );
    if (current_week_data?.length > 0) {
      setFactoryAreas(current_week_data[0]?.areas);
    }

    const sortedAlertCount = [...dummyAlertCount].sort((a, b) => {
      if (b.target === a.target) {
        return b.actual - a.actual; // If target is equal, sort by actual
      }
      return b.target - a.target; // Otherwise, sort by target
    });
    setTargetsData({...targetsData, alert_count: sortedAlertCount})
    setAlertCountChartData({...alertCountChartData,
      options:{
        ...alertCountChartData?.options,
        xaxis: {
          ...alertCountChartData?.options?.xaxis,
          categories: sortedAlertCount?.map((i) => i?.area)
        }
      },

      series: [ 
        {
          name: "Target",
          data: sortedAlertCount?.map((i) => i.current_week_target),
        },
        {
          name: "Alerts",
          data: sortedAlertCount?.map((i) => i.actual),
        },
      ]
    })

    const timer = setTimeout(() => { 
      setLoader(false);
    }, 500);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleweekChange = (e) => {
    const { name, value } = e.target;
    setLoader(true);
    if (value?.trim() !== "") {
      setFilters({
        ...filters,
        [name]: value,
      });
    } else {
      setFilters({
        ...filters,
        [name]: moment().format("YYYY") + "-" + moment().format("MM"),
      });
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };
  const style = {
    width: "155px",
    height: "38px",
    fontSize: 13,
    margin: "10px 3px",
  };

  const columns = [
    {
      name: "Area ID",
      selector: (row) => row.area_id,
      sortable: true,
      center: true,
    },

    {
      name: "Area Owner",
      selector: (row) => row?.owner,
      sortable: true,
      center: true,
    },
    {
      name: `Week(${moment(targetweek).subtract(1, "weeks").week()}) Target`,
      selector: (row) => {
        return row?.last_week?.target || 0;
      },
      center: true,
      sortable: true,
    },
    {
      name: `Week(${moment(targetweek).subtract(1, "weeks").week()}) Alerts`,
      selector: (row) => {
        return row?.last_week?.alerts || 0;
      },
      center: true,
      sortable: true,
    },
    {
      name: `Week(${moment(targetweek).week()}) Target`,
      selector: (row) => {
        return row?.current_week?.target || 0;
      },
      sortable: true,
      center: true,
    },
    {
      name: `Week(${moment(targetweek).week()}) Alerts`,
      selector: (row) => {
        return row?.current_week?.alerts || 0;
      },
      sortable: true,
      center: true,
    },
  ];
  const handleRowSelected = useCallback((state) => {
    setSelectedAreas(state.selectedRows);
  }, []);

  const [show, setShow] = useState(false);
  const handleShow = (value) => {
    setShow(value);
  };

  const handleUpdate = () => {
    if (selectedAreas?.length > 0) {
      setShow(!show);
    } else {
      Swal.fire({
        text: "Please select atleast one area",
        icon: "warning",
        iconColor: "#bd2130",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        reverseButtons: true,
        cancelButtonColor: "#bd2130",
        showConfirmButton: true,
      });
    }
  };

  const handleSave = (updated_target) => {
    const selected_target_date = moment(targetweek)
      .format("WW-YYYY")
      .split("-");
    let updatedTargetAreas = [];
    setTargetsData((prevData) => {
      updatedTargetAreas = prevData?.target_areas?.map((targetWeek, index) => {
        // Check each area in the selectedAreas list
        return {
          ...targetWeek,
          areas: targetWeek?.areas?.map((area) => {
            // Check if the area is in selectedAreas
            const isSelected = selectedAreas.some(
              (selectedArea) => selectedArea?.area_id === area?.area_id
            );

            if (isSelected) {
              // Update the current week's target for the matched area
              const value_reduction = Math.round(
                (area?.last_week?.alerts *
                  parseInt(updated_target.split("-")[1])) /
                  100
              );
              const updatedArea = {
                ...area,
                current_week: {
                  ...area.current_week,
                  target:
                    targetWeek?.week === parseInt(selected_target_date[0]) &&
                    targetWeek?.year === parseInt(selected_target_date[1])
                      ? area?.last_week?.alerts - value_reduction
                      : area?.current_week?.target,
                },
              };

              // If this is not the last week, update the next week's last week target
              if (index + 1 < prevData.target_areas.length) {
                prevData?.target_areas[index + 1]?.areas?.forEach(
                  (nextWeekArea) => {
                    if (nextWeekArea?.area_id === area?.area_id) {
                      nextWeekArea.last_week.target =
                        area?.last_week?.alerts - value_reduction;
                    }
                  }
                );
              }

              return updatedArea;
            }
            return area;
          }),
        };
      });

      // Return the updated targets
      return {
        ...prevData,
        target_areas: updatedTargetAreas,
        target_areas: updatedTargetAreas,
      };
    });

    const current_week_data = updatedTargetAreas?.filter(
      (item) =>
        item?.week === parseInt(selected_target_date[0]) &&
        item?.year === parseInt(selected_target_date[1])
    );
    if (current_week_data?.length > 0) {
      setFactoryAreas(current_week_data[0]?.areas);
    }

    // Reset the areas after saving
    setSelectedAreas([]);
    setToggleSelection(!toggleSelection);
  };

  const handleTargetweek = (e) => {
    const { name, value } = e.target;
    setSelectedAreas([]);
    setFactoryAreas([]);
    setToggleSelection(!toggleSelection);

    if (value?.trim() !== "") {
      setTargetweek(value);
      if (value !== "") {
        const selected_target_date = moment(value).format("WW-YYYY").split("-");
        const current_week_data = targetsData?.target_areas?.filter(
          (item) =>
            item?.week === parseInt(selected_target_date[0]) &&
            item?.year === parseInt(selected_target_date[1])
        );
        if (current_week_data?.length > 0) {
          setFactoryAreas(current_week_data[0]?.areas);
        }
      }
    } else {
      setTargetweek("");
    }
  };

  return (
    <Fragment>
      <br />
      <Container fluid>
        {/* HEADER & FILTER */}
        <Row style={{ marginBottom: 0, alignItems: "flex-start" }}>
          <Col
            className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <h5>Targets And Controls (ICF) </h5>
          </Col>
          <Col
            className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Input
              className="form-control rounded-3 m-1"
              type="week"
              name="week"
              id="role"
              min={minWeek}
              max={maxWeek}
              value={filters?.week}
              style={style}
              onChange={handleweekChange}
            />
          </Col>
        </Row>
        {loader ? (
          <Loader1 />
        ) : (
          <>
            {/* SAFETY COMPLIANCE  & ALERTS COUNT */}
            <Row>
              <Col className="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-4 col-xxl-4">
                <p style={{ fontSize: 16, fontWeight: 400, marginBottom: 5 }}>
                  Compliance Targets
                </p>
                <Card style={{ marginRight: -5, marginBottom: 10 }}>
                  <CardBody style={{padding:"20px 20px 20px 0px"}}>
                    <Chart
                      options={complianceChartData?.options}
                      series={complianceChartData?.series}
                      type="bar"
                      height={complianceChartData?.options?.chart?.height}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-8 col-xxl-8">
                <p style={{ fontSize: 16, fontWeight: 400, marginBottom: 5 }}>
                  Alert Count
                </p>
                <Card style={{ marginLeft: -5, marginBottom: 10 }}>
                  <CardBody style={{padding:"20px 20px 20px 0px"}}>
                    <Chart
                      options={alertCountChartData?.options}
                      series={alertCountChartData?.series}
                      type="bar"
                      height={alertCountChartData?.options?.chart?.height}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* FACTORY DROPDOWN & TABLE */}
            <Row style={{ paddingBottom: 20 }}>
              <Col>
                <p style={{ fontSize: 18, fontWeight: 500, marginBottom: 5 }}>
                  Set Factory (ICF) Targets
                </p>
                <Input
                  className="rounded-3 d-inline-block"
                  type="week"
                  name="target_week"
                  id="target_week"
                  min={maxWeek}
                  max={moment().add(1, "weeks").format("YYYY-[W]WW")}
                  value={targetweek}
                  style={style}
                  onChange={handleTargetweek}
                />
                {targetweek !== "" && (
                  <>
                    <p className="font-danger mb-2">
                      Note: To update Current Week Target, please select
                      relevant areas and Press Update{" "}
                    </p>
                    <DataTableComponentForArea
                      dummytabledata={factoryAreas}
                      pagination={true}
                      pagination_options={[5, 10, 15]}
                      paginationPerPage={15}
                      selectableRows={true}
                      tableColumns={columns}
                      responsive
                      highlightOnHover
                      persistTableHead
                      handleRowSelected={handleRowSelected}
                      clearSelectedRows={toggleSelection}
                    />
                    <br />
                    <Button className="m-1 pull-right" onClick={handleUpdate}>
                      Update
                    </Button>
                    <br />
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        {show && (
          <UpdateTargetModel
            handleSave={handleSave}
            handleShow={handleShow}
            showModal={show}
            selectedAreas={selectedAreas}
            selectedweek={targetweek}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default TargetsAndControls;

