import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Row, Col, Card, CardBody, Input } from "reactstrap";
import { ArrowLeft } from "react-feather";
// import "./hot_lead.css";
import { useNavigate } from "react-router-dom";
import Loader1 from "../../../../CommonElements/Spinner/loader";
import CustomPagination from "./Components/pagination/pagination";
import CameraImage from "../.../../../../../assets/images/cameras/camera.jpeg";
import CommonFIlterButton from "../../../Common/commonFilterButton/CommonFIlterButton";
import { CamersStatus } from "../../../../Data/staticData/data";
import SingleImage from "../../../../Gallery/zoomin/SingleImage";
import ContextData from "../../../../Hooks/useAuth";
import KPICard from "./Components/KPICards/kpi_card";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import AddCameraModel from "./Components/add_camera_model";
import moment from "moment";

const AllCamerasScreen = () => {
  const { Cameras, setCameras } = ContextData();
  const navigate = useNavigate();
  const [allCameras, setAllCameras] = useState([]);
  const [filterCameras, setFilterCameras] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [imageData, setImageData] = useState({});

  const [filters, setFilters] = useState({
    area: [],
    status: "",
    sub_area: [],
  });
  const [dropdownOptions, setDropdownOptions] = useState({
    area_list: [
      {
        id: 1,
        area: "SA-1",
        areaOwner: "Adil",
        sub_area: [
          { id: 1, name: "Palletizing 1,2 & 3" },
          { id: 2, name: "Palletizing office" },
          { id: 3, name: "Palletizing corridor" },
          { id: 4, name: "Waste window area" },
        ],
      },
      {
        id: 2,
        area: "SA-2",
        areaOwner: "Aftab",
        sub_area: [
          { id: 1, name: "Ammonia Compressor room" },
          { id: 2, name: "Catch pit area" },
          { id: 3, name: "Ref workshop" },
          { id: 4, name: "Ref Control Room" },
          { id: 5, name: "Ammonia CCR" },
          { id: 6, name: "Diffusion tank" },
        ],
      },
      {
        id: 3,
        area: "SA-3",
        areaOwner: "Arslan",
        sub_area: [
          { id: 1, name: "Admin Building Roof Top" },
          { id: 2, name: "AHU Room above Canteen" },
          { id: 3, name: "Main Asset scrap yard" },
          { id: 4, name: "Motor / panel scrap yard" },
          { id: 5, name: "R&D front side scrap yard" },
          { id: 6, name: "Contractor Workshops" },
          { id: 7, name: "DP store area" },
          { id: 8, name: "Engineering store" },
          { id: 9, name: "Safety office" },
          { id: 10, name: "Safety storage area" },
          { id: 11, name: "Engineering store placement yard" },
          { id: 12, name: "Fabrication workshop & surrounding area" },
          { id: 13, name: "Lathe Machine Workshop" },
          { id: 14, name: "MAMz workshop" },
        ],
      },
      {
        id: 4,
        area: "SA-4",
        areaOwner: "Ayesha Khaliq",
        sub_area: [
          { id: 1, name: "Cone Baking" },
          { id: 2, name: "Mixing" },
          { id: 3, name: "LI room" },
          { id: 4, name: "Aging room" },
          { id: 5, name: "Chocolate plant" },
          { id: 6, name: "Mixing pits" },
          { id: 7, name: "Oil/Glucose decanting area" },
          { id: 8, name: "Sauce plant" },
          { id: 9, name: "Chilled room" },
          { id: 10, name: "Day store area" },
          { id: 11, name: "Mixing control room" },
          { id: 12, name: "Tank form" },
        ],
      },
      {
        id: 5,
        area: "SA-5",
        areaOwner: "Dr. Amjad",
        sub_area: [{ id: 1, name: "Medical Roof Top" }],
      },
      {
        id: 6,
        area: "SA-6",
        areaOwner: "Meraj",
        sub_area: [
          { id: 1, name: "Roof Tops (Dry Store)" },
          { id: 2, name: "Roof Tops (Pulp Store)" },
          { id: 3, name: "Scrap Yard (Packmat area/ drums)" },
          { id: 4, name: "Dry Store 1, 2" },
          { id: 5, name: "Chemical store" },
          { id: 6, name: "Dry store driver room" },
          { id: 7, name: "Docking stations" },
          { id: 8, name: "Washrooms" },
          { id: 9, name: "Pulp Store" },
          { id: 10, name: "Hot room" },
          { id: 11, name: "Flavour room" },
          { id: 12, name: "Pallet washing room" },
        ],
      },
      {
        id: 7,
        area: "SA-7",
        areaOwner: "Moazzam Ali",
        sub_area: [
          { id: 1, name: "Machine Parts Room" },
          { id: 2, name: "Ultra Clean" },
          { id: 3, name: "Production floor" },
          { id: 4, name: "Production offices" },
          { id: 5, name: "TPM room" },
          { id: 6, name: "Day store" },
          { id: 7, name: "Parts room" },
          { id: 8, name: "Room 10" },
          { id: 9, name: "OPC chemical room" },
        ],
      },
      {
        id: 8,
        area: "SA-8",
        areaOwner: "Muhammad Shahbaz",
        sub_area: [
          { id: 1, name: "ETP" },
          { id: 2, name: "Boiler" },
          { id: 3, name: "Air compressor" },
          { id: 4, name: "Boiler control room" },
          { id: 5, name: "HFO tank" },
          { id: 6, name: "Water filter area" },
        ],
      },
      {
        id: 9,
        area: "SA-9",
        areaOwner: "Muhammad Wasi",
        sub_area: [
          { id: 1, name: "Roof Tops (Canteen)" },
          { id: 2, name: "Roof Tops (Security)" },
          { id: 3, name: "Time Office" },
          { id: 4, name: "ETMS" },
          { id: 5, name: "Medical OHC" },
          { id: 6, name: "Security Office" },
          { id: 7, name: "Parkings" },
          { id: 8, name: "Cycle Stand" },
          { id: 9, name: "Smoking Area" },
          {
            id: 10,
            name: "Area between Multan road gate to inner factory entrance gate",
          },
          { id: 11, name: "Admin Building" },
          { id: 12, name: "Reception" },
          { id: 13, name: "Canteen" },
          { id: 14, name: "Kitchen" },
          { id: 15, name: "Galleries" },
          { id: 16, name: "Washrooms" },
          { id: 17, name: "Locker area" },
          { id: 18, name: "Masjid" },
          { id: 19, name: "Changing rooms" },
          { id: 20, name: "Waiting area" },
          { id: 21, name: "Girls room" },
          { id: 22, name: "Exit routes" },
          { id: 23, name: "Brains lab" },
          { id: 24, name: "Recharge room" },
          { id: 25, name: "Humail's office" },
          { id: 26, name: "Meeting rooms" },
          { id: 27, name: "IT room" },
          { id: 28, name: "Outside Taris" },
        ],
      },
      {
        id: 10,
        area: "SA-10",
        areaOwner: "Nazir Sb",
        sub_area: [
          {
            id: 1,
            name: "Solar Area (Panels, Transformer rooms & entire area)",
          },
          { id: 2, name: "Diesel Storage area" },
          { id: 3, name: "Earth pit area" },
          { id: 4, name: "Electrical power house" },
          { id: 5, name: "LT room" },
          { id: 6, name: "HT room" },
          { id: 7, name: "Gen set area" },
          { id: 8, name: "Transformer room" },
          { id: 9, name: "Ammonia soft starter room" },
        ],
      },
      {
        id: 11,
        area: "SA-11",
        areaOwner: "Sadia",
        sub_area: [{ id: 1, name: "R&D Innovation Centre (Complete)" }],
      },
      {
        id: 12,
        area: "SA-12",
        areaOwner: "Shafiq",
        sub_area: [
          { id: 1, name: "QA" },
          { id: 2, name: "Pathogen Lab" },
          { id: 3, name: "QA storeroom" },
        ],
      },
      {
        id: 13,
        area: "SA-13",
        areaOwner: "Shahbaz",
        sub_area: [
          { id: 1, name: "LPG Area" },
          { id: 2, name: "Pump House" },
          { id: 3, name: "Water treatment plant & roof" },
          { id: 4, name: "Biomass Boiler (including fuel storage shed)" },
        ],
      },
      {
        id: 14,
        area: "SA-14",
        areaOwner: "Sheraz",
        sub_area: [
          { id: 1, name: "Roof Tops (Cold Stores)" },
          { id: 2, name: "Wooden Pallets Area" },
          { id: 3, name: "FG BOF" },
          { id: 4, name: "Cold Store 1&2" },
          { id: 5, name: "Cold store offices" },
          { id: 6, name: "Dispatch area" },
        ],
      },
      {
        id: 15,
        area: "SA-15",
        areaOwner: "Umair Pervaiz",
        sub_area: [
          { id: 1, name: "UE Projects" },
          { id: 2, name: "Projects Store" },
        ],
      },
    ],
    module_options: [
      "Helmet",
      "Vest",
      "Emergency Exit",
      "Machine Guard",
      "MMHE",
    ],
    sub_area_list: [],
    camera_status: [
      { id: 1, title: "Active" },
      { id: 2, title: "InActive" },
    ],
  });

  useLayoutEffect(() => {
    // Scroll to the top before painting the screen
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setAllCameras(Cameras);
    setFilterCameras(Cameras);
    setShowTable(true);
  }, []);

  const RenderText = ({ title, value, gate }) => {
    return (
      <div className="camera_details">
        <span className="f-w-500">{title}: </span>
        <span>{value}</span>
      </div>
    );
  };

  const Items = (items) => {
    return (
      <Row>
        {items &&
          items?.map((item, index) => {
            return (
              <Col
                key={index}
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <Card
                  key={index + 1}
                  style={{
                    margin: "5px -7px",
                    backgroundColor: item?.active ? "white" : "#e2e2e2",
                  }}
                  onClick={() => handleRowClick(item)}
                >
                  <CardBody className="camera_card_body">
                    <Row
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Col className="custom-col-9 col-sm-9 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                        <div className="gateNumber">Camera #{item?.gate}</div>
                        <RenderText
                          title={"Module"}
                          value={item?.module}
                          gate={item}
                        />
                        <RenderText
                          title={"Camera ID"}
                          value={item?.cameraId}
                          gate={item}
                        />
                        <RenderText
                          title={"Area"}
                          value={item?.area}
                          gate={item}
                        />
                        <RenderText
                          title={"Sub Area"}
                          value={item?.subArea}
                          gate={item}
                        />
                        <RenderText
                          title={"Last Active"}
                          value={`${moment(item?.date).format("DD MMM, YYYY")} ${item?.time}`}
                          gate={item}
                        />

                        <div className="camera_details">
                          <span className="f-w-500">Status: </span>
                          <span
                            className={`badge ${
                              item?.active ? "bg-success" : "bg-danger"
                            }`}
                            style={{
                              fontWeight: 500,
                              fontSize: 13,
                            }}
                          >
                            {" "}
                            {item?.active ? "Active" : "Inactive"}
                          </span>
                        </div>
                      </Col>
                      <Col className="custom_camera_card col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-end">
                        <img
                          src={item?.image_url}
                          alt="Camera Image"
                          className="camera_image"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Row>
    );
  };

  const handleFilterChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
  };
  useEffect(() => {
    applyFilters(filters, filterCameras);
  }, [filters, filterCameras]);

  const applyFilters = (currentFilters, dataList) => {
    let filtered = dataList;

    // Apply area filter
    if (currentFilters.area) {
      const selected_areas = currentFilters?.area?.map((i) => i.area);
      filtered = filtered.filter(
        (item) =>
          selected_areas.length === 0 || selected_areas?.includes(item.area)
      );
    }
    // Apply sub area filter
    if (currentFilters.sub_area) {
      const selected_sub_areas = currentFilters?.sub_area?.map((i) =>
        i.name?.trim()?.toLowerCase()
      );
      filtered = filtered.filter((item) => {
        return (
          selected_sub_areas.length === 0 ||
          selected_sub_areas?.includes(item.subArea?.trim()?.toLowerCase())
        );
      });
    }

    // Apply module filter
    if (currentFilters.status) {
      const val = currentFilters.status === "Active" ? true : false;
      filtered = filtered.filter((item) => item.active === val);
    }

    const updated_data = filtered?.map((i, index) => ({
      ...i,
      sr: index + 1,
    }));
    setAllCameras(updated_data);
  };

  const style = {
    width: "155px",
    height: "38px",
    fontSize: 13,
    margin: "5px 3px",
    display: "inline-block",
  };

  const handleRowClick = (item) => {
    setImageData({
      cameraName: item?.camera_name,
      date: item?.date,
      time: item?.time,
    });
    setModalData(item);
    setShowModal(!showModal);
  };

  const handleAreaChange = (selected) => {
    const subareas = selected?.reduce((acc, currentArea) => {
      return [...acc, ...currentArea?.sub_area];
    }, []);
    setFilters({ ...filters, area: selected, sub_area: [] });
    setDropdownOptions({ ...dropdownOptions, sub_area_list: subareas });
  };

  const handleSubAreaChange = (selected) => {
    setFilters({ ...filters, sub_area: selected });
  };

  const handleSaveCamera = (record) => {
    const new_item = {
      active: true,
      cameraIP: record?.camera_ip,
      area: record?.area,
      subArea: record?.sub_area,
      cameraId: record?.camera_id,
      cameraName: "Ezviz",
      date: moment().format("YY-MM-DD"),
      time: moment().format("hh:mm A"),
      module: "Helmet",
      gate: 1,
      roomName: "Ezviz",
      image_url: CameraImage,
    };
    const updated_list = [new_item, ...Cameras];
    setCameras(updated_list);
    setAllCameras(updated_list);
    setFilterCameras(updated_list);
  };
  return (
    <>
      <br />
      <Container fluid>
        <Row style={{ marginBottom: 0, alignItems: "flex-start" }}>
          <Col className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
            <h5 className="d-inline-block">Cameras</h5>
          </Col>
          <Col className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 text-end">
            <div className="filter-row">
              <Typeahead
                id="area-typeahead"
                name="area"
                className="custom-typeahead rounded-3"
                labelKey="area"
                multiple
                options={dropdownOptions?.area_list}
                selected={filters?.area}
                onChange={handleAreaChange}
                placeholder="Select Areas"
                style={{
                  display: "inline-block",
                  textTransform: "capitalize",
                  maxWidth: "255px",
                }}
              />
              <Typeahead
                id="sub-area-typeahead"
                name="sub-area"
                className="custom-typeahead rounded-3"
                labelKey="name"
                multiple
                options={dropdownOptions?.sub_area_list}
                selected={filters?.sub_area}
                onChange={handleSubAreaChange}
                placeholder="Select Sub Areas"
                style={{
                  display: "inline-block",
                  textTransform: "capitalize",
                  maxWidth: "255px",
                }}
              />
              <CommonFIlterButton
                data={CamersStatus}
                handleInputChange={handleFilterChange}
                style={style}
                selectedItem={filters?.status}
                firstOption={"Select Status"}
                inputChangeOption={"status"}
              />
              {JSON.parse(localStorage.getItem("role")) === "it-officer" && (
                <AddCameraModel
                  areas_list={dropdownOptions?.area_list}
                  handleSave={handleSaveCamera}
                />
              )}
            </div>
          </Col>
        </Row>

        {loading ? (
          <Loader1 />
        ) : showTable ? (
          <>
            <Row style={{ marginTop: 5 }}>
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <KPICard
                  data={{
                    title: "Total Cameras",
                    gros: 50,
                    total: allCameras?.length || 0,
                    showPercentage: false,
                    marginRight: -5,
                  }}
                />
              </Col>
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <KPICard
                  data={{
                    title: "Live Cameras",
                    gros: allCameras?.length > 0 ? (
                      Math.round(
                        (allCameras?.filter((i) => i?.active === true)?.length / allCameras?.length) * 100
                      )).toFixed(0) : 0,
                    total:
                      allCameras?.filter((i) => i?.active === true)?.length ||
                      0,
                    showPercentage: true,
                    marginLeft: -5,
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{ marginBottom: 20, padding: "0px 10px", marginTop: 5 }}
            >
              <Col>
                <CustomPagination
                  data={allCameras}
                  itemsPerPage={10}
                  renderItems={Items}
                />
              </Col>
            </Row>
          </>
        ) : (
          showError && (
            <center
              style={{ textAlign: "center", marginTop: 60, marginBottom: 40 }}
            >
              <h5> No Data Found </h5>
            </center>
          )
        )}
        {showModal && (
          <SingleImage
            // photo={modalData?.image}
            photo={CameraImage}
            setIsOpen={setShowModal}
            imageData={imageData}
          />
        )}
      </Container>
    </>
  );
};

export default AllCamerasScreen;
