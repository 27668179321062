
import React, { useState, Fragment, useEffect } from 'react';
import { Card, Col, Container, Row, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, CardBody } from 'reactstrap';
import TicketCard from './Component/TicketCard'
import { Link } from 'react-router-dom';
import PreviousSupportTicketCard from './Component/PreviousSupportTicketCard';
import GenerateOReditTicket from './Component/Modal_Generate_Ticket'
import Practise from './Component/GenerateTicket'
import EditTicketModal from './Component/EditModal';
const SupportAndTicketsScreen = () => {
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('role')));
  const [type, settype] = useState('')
  const [tickets, settickets] = useState(
    [
      {
        areaOwner: 'Aftab',
        AreaID: ['SA-1', 'SA-14'],
        subArea: ['Roof Tops (Palletizing)', 'Roof Tops (Cold Stores)', 'FG BOF',],
        query: 'Camera 4 is not working',
        response: 'Resolved',
        result: 'solved',
        ticketDate: '2024-09-02',
        responseDate: '2024-09-03',
        flag: true,
        id: 1,
        model: ['Helmet'],
        Priority: 'High',
      },
      {
        areaOwner: 'Meraj',
        AreaID: ['SA-1', 'SA-14'],
        subArea: ['Roof Tops (Palletizing)', 'Dispatch area', 'Cold store offices', 'Cold Store 1&2',],
        query: 'Camera visibility issue',
        response: 'Pending',
        ticketDate: '2024-09-01',
        flag: false,
        id: 2,
        model: ['Vest'],
        Priority: 'Low',

      },

    ]
  )

  const formatDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };

  const [ticketForm, setTicketForm] = useState({
    areaOwner: '',
    subArea: [],
    model: [],
    query: '',
    AreaID: [],
    ticketDate: formatDate(),
    Priority: '',
  });
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const editmodalToggle = () => seteditmodal(!editmodal)

  const handleEditTicket = (data) => {
    console.log(data)
    settype('Edit')
    setTicketForm(data);
    toggleModal();
  };
  const generateTicket = () => {
    settype('Generate');
    toggleModal();
    // setTicketForm({
    //   areaOwner: '',
    //   subArea: [],
    //   model: [],
    //   query: '',
    //   ticketDate: formatDate(),
    // });
  };

  // -------------------x-----------------------x-----------------------
  const [TicketsData, setTicketsData] = useState(
    [
      {
        id: '550055',
        owner: "Adil",
        areas: ["SA-1"],
        subAreas: [
          "Roof Tops (Palletizing)",
          "Palletizing 1,2 & 3"
        ],
        models: ["Helmet", "Forklift"],
        priority: "High",
        query: "Check for safety violations in palletizing areas.",
        ticketDate: "2024-10-07",
        status: 'Resolved',
        responseDate: '2024-10-09'
      },
      {
        id: '550056',
        owner: "Ayesha Khaliq",
        areas: ["SA-4"],
        subAreas: [
          "Cone Baking",
          "Mixing"
        ],
        models: ["Vest"],
        priority: "Medium",
        query: "Routine check for operation efficiency in cone baking section.",
        ticketDate: "2024-10-08",
        status: 'Pending'
      },

    ]
  )
  const [editmodal, seteditmodal] = useState(false)
  const [updatedTicket, setupdatedTicket] = useState({})
  const handleEditRequest = (data) => {
    console.log(data)
    setupdatedTicket(data)
    settype('Edit')
    // toggleModal()
    editmodalToggle()
  }
  return (
    <Fragment>
      <br />
      <Container fluid={true}>
        <Row>
          <Col xs='12'>
            <h5>SUPPORT AND TICKETS</h5>
          </Col>
          <Col xs='12' sm='6' lg='4' className='mb-4'>
            <TicketCard onClick={() => { generateTicket() }} heading={'Generate Support Ticket'} img={'https://cdn-icons-png.flaticon.com/512/10729/10729082.png'} />
          </Col>
          <Col xs='12' sm='6' lg='4' className='mb-4'>
            <Link to={'https://calendly.com/'} target="_blank">
              <TicketCard heading={'Schedule Now'} img={'https://i.pinimg.com/736x/f0/a5/bc/f0a5bc76afc2563bf5475eddbe936f7e.jpg'} />
            </Link>
          </Col>
          <Col xs='12' sm='6' lg='4' className='mb-4'>
            <Link to={`${process.env.PUBLIC_URL}/support/documentationfaqs/${role}`}>
              <TicketCard heading={'FAQs'} img={'https://cdn3d.iconscout.com/3d/premium/thumb/faq-10358250-8356545.png'} />
            </Link>
          </Col>
          <h5>Previous Support Tickets</h5>
          {TicketsData && TicketsData.map((data, key) => (
            <Col xl='12' xxl='6'>
              <PreviousSupportTicketCard dataa={data} ticketno={key + 1} handleEditTicket={() => { handleEditRequest(data); settype('Edit') }} />
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal */}
      <EditTicketModal toggleModal={editmodalToggle} isOpen={editmodal} formatDate={formatDate} setTicketsData={setTicketsData} TicketsData={TicketsData} updatedTicket={updatedTicket} type={type} />
      <Practise toggleModal={toggleModal} isOpen={modal} formatDate={formatDate} setTicketsData={setTicketsData} TicketsData={TicketsData} type={type} />
    </Fragment>
  );
};

export default SupportAndTicketsScreen;
