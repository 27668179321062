import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import cubaimg from "../../assets/images/logo/login-logo.svg"
import CustomizerContext from '../../_helper/Customizer';
import uniIcon from '../../assets/images/logo/uni-logo.jpeg';

const SidebarIcon = () => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
        <img
          className="img-fluid logo-sv-img"
          src={uniIcon}
          alt=""
        />
      </Link>
    </div>
  );
};

export default SidebarIcon;