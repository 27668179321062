import React from 'react'
import { H2 } from '../../../../AbstractElements'
import ActivityMonitor from '../../Super Admin Dashboard/Pages/Activity_Monitor'

const Index = () => {
  return (
    <>
    <ActivityMonitor />
  </>
  )
}

export default Index
