import React, { useState, useEffect } from 'react';
import {Modal} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button } from 'react-bootstrap';
import { Col, Input, Row } from 'reactstrap';
 

const modelOptions = ['Helmet', 'Vest', 'Emergency Exit', 'Forklift', 'Machine Guard'];

const priorities = [
  { label: 'High', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'Low', value: 3 }
];

const areaOwnerData = [
  {
    name: 'Adil',
    areas: [
      {
        id: 'SA-1',
        subareas: [
          'Roof Tops (Palletizing)',
          'Palletizing 1,2 & 3',
          'palletizing office',
          'palletizing corridor',
          'waste window area'
        ]
      }
    ]
  },
  {
    name: 'Aftab',
    areas: [
      {
        id: 'SA-2',
        subareas: [
          'Roof Tops (Service Building)',
          'Ammonia Compressor room',
          'Catch pit area',
          'Ref workshop',
          'Ref Control Room',
          'Ammonia CCR',
          'Diffusion tank'
        ]
      }
    ]
  },
  {
    name: 'Arslan',
    areas: [
      {
        id: 'SA-3',
        subareas: [
          'Void Area (Production, Mixing)',
          'Admin Building Roof Top',
          'AHU Room above Canteen',
          'Main Asset scrap yard',
          'motor / panel scrap yard',
          'R&D front side scrap yard',
          'Contractor Workshops',
          'DP store area',
          'engineering store',
          'safety office',
          'safety storage area',
          'engineering store placement yard',
          'Fabrication workshop & surrounding area',
          'Lathe Machine Workshop',
          'MAMz workshop'
        ]
      }
    ]
  },
  {
    name: 'Ayesha Khaliq',
    areas: [
      {
        id: 'SA-4',
        subareas: [
          'Roof Tops (Cone Baking)',
          'Cone Baking',
          'Mixing',
          'LI room',
          'aging room',
          'chocolate plant',
          'mixing pits',
          'oil/glucose decanting area',
          'sauce plant',
          'chilled room',
          'day store area',
          'mixing control room',
          'tank form'
        ]
      }
    ]
  },
  {
    name: 'Dr.Amjab',
    areas: [
      {
        id: 'SA-5',
        subareas: [
          'OHC',
          'Medical Roof Top'
        ]
      }
    ]
  },
  {
    name: 'Meraj',
    areas: [
      {
        id: 'SA-6',
        subareas: [
          'Roof Tops (Dry Store)',
          'Roof Tops (Pulp Store)',
          'Scrap Yard (Packmat area/ drums)',
          'Dry Store 1, 2',
          'chemical store',
          'dry store driver room',
          'docking stations',
          'washrooms',
          'Pulp Store',
          'Hot room',
          'flavour room',
          'Pallet washing room'
        ]
      }
    ]
  },
  {
    name: 'Moazzam Ali',
    areas: [
      {
        id: 'SA-7',
        subareas: [
          'Machine Parts Room',
          'Ultra Clean',
          'Production floor',
          'production offices',
          'TPM room',
          'Day store',
          'Parts room',
          'room 10',
          'OPC chemical room'
        ]
      }
    ]
  },
  {
    name: 'Muhammza Shahbaz',
    areas: [
      {
        id: 'SA-8',
        subareas: [
          'ETP',
          'Boiler',
          'Air compressor',
          'boiler control room',
          'HFO tank',
          'water filter area'
        ]
      }
    ]
  },
  {
    name: 'Muhammza Wasi',
    areas: [
      {
        id: 'SA-9',
        subareas: [
          'Roof Tops (Canteen)',
          'Roof Tops (Security)',
          'Time Office',
          'ETMS',
          'Medical OHC',
          'Security Office',
          'Parkings',
          'Cycle Stand',
          'Smoking Area',
          'Area between Multan road gate to inner factory entrance gate',
          'Admin Building',
          'reception',
          'canteen',
          'kitchen',
          'galleries',
          'washrooms',
          'locker area',
          'masjid',
          'changing rooms',
          'waiting area',
          'girls room',
          'exit routes',
          'brains lab',
          'recharge room',
          "Humail's office",
          'meeting rooms',
          'IT room',
          'Outside Taris'
        ]
      }
    ]
  },
  {
    name: 'Nazir Sb',
    areas: [
      {
        id: 'SA-10',
        subareas: [
          'Solar Area (Panels, Transformer rooms & entire area)',
          'Diesel Storage area',
          'earth pit area',
          'electrical power house',
          'LT room',
          'HT room',
          'gen set area',
          'transformer room',
          'ammonia soft starter room'
        ]
      }
    ]
  },
  {
    name: 'Sadia',
    areas: [
      {
        id: 'SA-11',
        subareas: [
          'R&D Innovation Centre (Complete)'
        ]
      }
    ]
  },
  {
    name: 'Shafiq',
    areas: [
      {
        id: 'SA-12',
        subareas: [
          'QA',
          'Pathogen Lab',
          'QA storeroom'
        ]
      }
    ]
  },
  {
    name: 'Shahbaz',
    areas: [
      {
        id: 'SA-13',
        subareas: [
          'LPG Area',
          'Pump House',
          'Water treatment plant & roof',
          'Biomass Boiler (including fuel storage shed)'
        ]
      }
    ]
  },
  {
    name: 'Sheraz',
    areas: [
      {
        id: 'SA-14',
        subareas: [
          'Roof Tops (Cold Stores)',
          'Wooden Pallets Area',
          'FG BOF',
          'Cold Store 1&2',
          'Cold store offices',
          'Dispatch area'
        ]
      }
    ]
  },
  {
    name: 'Umair Pervaiz',
    areas: [
      {
        id: 'SA-15',
        subareas: [
          'UE Projects',
          'Projects Store'
        ]
      }
    ]
  }
];

const TicketFormModal = ({ isOpen, closeModal,toggleModal,setTicketsData,TicketsData,updatedTicket,type }) => {
  const [selectedOwner, setSelectedOwner] = useState(null); 
  const [selectedAreas, setSelectedAreas] = useState([]); 
  const [allAreas, setAllAreas] = useState([]);
  const [selectedSubareas, setSelectedSubareas] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [priority, setPriority] = useState();
  const [query, setQuery] = useState('');
  const [clearAreaid, setclearAreaid] = useState(false);
  const [clearSubArea, setclearSubArea] = useState(false)
  const [clearModel, setclearModel] = useState(false)

  useEffect(() => {
    setAllAreas(areaOwnerData.flatMap(owner => owner.areas));
    if (selectedOwner) {
      setSelectedAreas(selectedOwner.areas);
    }
  }, [selectedOwner]);

  const formatDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };
  const handleSubmit = () => {
    const ticketData = {
      owner: selectedOwner ? selectedOwner.name : null,
      areas: selectedAreas.map(area => area.id),
      subAreas: selectedSubareas.map(sub => sub.label),
      models: selectedModels.map(model => model.label),
      priority,
      query,
      status:'Pending',
      id: formatDate(),
      ticketDate:formatDate()
    };
    if(ticketData.areas.length<0 || ticketData.models.length<0 || ticketData.subAreas.length<0 || !ticketData.owner || !ticketData.priority || !ticketData.query){
      alert('Please fill all the fields')
      return
  }
     
    if (updatedTicket && Object.keys(updatedTicket).length > 0) {
      console.log('isko t set krlooo',updatedTicket)
      const updatedTicketsData = TicketsData.map(ticket =>
          ticket.id === updatedTicket.id ? ticketData : ticket
      ); 
      setTicketsData(updatedTicketsData);
  } else  { 
    setTicketsData(prevTicketsData => [...prevTicketsData, ticketData]);
      }

      toggleModal();
    };
  
  const handleClearAll = () => {
    setclearAreaid(false)
    setSelectedAreas([]);
    setSelectedSubareas([]);
    setSelectedModels([]);
  };
  const handleSelectAllModels = () => {
    setSelectedModels(modelOptions.map(model => ({ label: model })));
  };


  useEffect(() => {
     setSelectedSubareas([])
     setSelectedAreas([])
     setSelectedModels([])
     setSelectedOwner(null)
     setPriority()
     setQuery('')
  }, [isOpen])
  

  // useEffect(() => {
  //   if(updatedTicket){
  //     console.log(updatedTicket,'all data')
  //     console.log(updatedTicket.areas,'area')
  //     console.log(updatedTicket.subAreas,'subAreas')
       
  //     const ownerObject = areaOwnerData.find(owner => owner.name === updatedTicket.owner);
  //   setSelectedOwner(ownerObject);
  //   const areas = ownerObject ? ownerObject.areas.filter(area => updatedTicket.areas.includes(area.id)) : [];
  //   setSelectedAreas(areas);

  //   const subareas = areas.flatMap(area => area.subareas).filter(subarea => updatedTicket.subAreas.includes(subarea));
  //   setSelectedSubareas(subareas.map(sub => ({ label: sub })));

  //   const models = updatedTicket.models || [];
  //   setSelectedModels(models.map(model => ({ label: model })));

  //   setPriority(updatedTicket.priority);
  //   setQuery(updatedTicket.query);
  //   }
  //   else{
  //     console.log('not found')
  //   }
  // }, [updatedTicket]) 


  
  
  return (
    <Modal
      show={isOpen}
      onHide={toggleModal} 
      size="lg"
    >
      <Modal.Header> 
      <Modal.Title>Create a New Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row> 
        <Col lg='6' md='12' className='mb-2'> 
          <Typeahead
            id="area-owner-typeahead"
            labelKey="name"
            options={areaOwnerData}
            placeholder="Choose an Area Owner..."
            onChange={(selected) => setSelectedOwner(selected[0] || null)}
            selected={selectedOwner ? [selectedOwner] : []}
            clearButton
          />
        </Col>
        <Col lg='6' md='12' className='mb-2'>
        <Input 
        type='select'
        onChange={(e) => {setPriority(e.target.value);console.log(e.target.value)}}
        value={priority}
        >
        <option>Select Priority</option>
            {priorities.map(p => (
              <option value={p.label}>{p.label}</option>
            ))}
        </Input>
        </Col>
        <Col lg='6' md='12' className='mb-2'>
          <Typeahead
            id="area-id-typeahead"
            labelKey="id"
            options={allAreas}
            placeholder="Select an Area ID..."
            onChange={setSelectedAreas}
            selected={selectedAreas}
            multiple
            clearButton
          />
         
        <Button variant="primary" className='m-1' onClick={() => {setSelectedAreas(allAreas);setclearAreaid(true)}}>Select All </Button>
          {selectedAreas.length>0?
        <Button variant="danger" className='m-1'  onClick={()=>{setSelectedAreas([]);setSelectedSubareas([])}}>Clear All </Button> 
        :''  
        }
          
          
        </Col>
        {selectedAreas.length > 0 && (
          <Col lg='6' md='12' className='mb-2'>
            <>
              <Typeahead
                id="subarea-typeahead"
                labelKey="label"
                options={selectedAreas.flatMap(area => area.subareas).map(subarea => ({ label: subarea }))}
                placeholder="Select a Subarea..."
                onChange={setSelectedSubareas}
                selected={selectedSubareas}
                multiple
                clearButton
              />
              <Button variant="primary" className='m-1' onClick={() => {setSelectedSubareas(selectedAreas.flatMap(area => area.subareas).map(subarea => ({ label: subarea })));setclearSubArea(true)}}>Select All </Button>
              {selectedSubareas.length>0?
              <Button variant="danger" className='m-1' onClick={() =>{ setSelectedSubareas([]);setclearSubArea(false)}}>Clear All </Button> 
              :''}
             
            </>
          </Col>
        )}
        <Col lg='6' md='12' className='mb-2'>
          <Typeahead
            id="model-typeahead"
            labelKey="label"
            options={modelOptions.map(model => ({ label: model }))}
            placeholder="Select a Model..."
            onChange={setSelectedModels}
            selected={selectedModels}
            multiple
            clearButton
          />
          <Button variant="primary" className='m-1' onClick={()=>{setclearModel(true);handleSelectAllModels()}}>Select All </Button>
          {selectedModels.length>0?
          <Button variant="danger" className='m-1' onClick={() => {setSelectedModels([]);setclearModel(false)}}>Clear All </Button>  
          :''}
        </Col>
        
        <Col lg='6' md='12' className='mb-2'>
          <textarea
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter your query..."
            className="form-control"
          />
        </Col>
      </Row>
      </Modal.Body>
      <Modal.Footer>
      <div>
        <Button variant="primary" onClick={handleSubmit} className='m-1'>Submit Ticket</Button>
        <Button variant="dark" onClick={toggleModal} className='m-1'>Close</Button>
      </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketFormModal;
