import React from 'react'
import Production from '../../GlobalDashboard/AreawiseReports/production/Production'

const Index = () => {
  return (
    <>
    <Production mainTitle={'Hello Aftab!'} type={'area'}/>
    </>
  )
}

export default Index
