import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function ModelChart({ filters }) {
    const [series2, setSeries2] = useState([
        {
            name: 'Accuracy',
            data: [75, 85, 60, 90, 80], // Default accuracy data
        },
    ]);

    const [lastFilter, setLastFilter] = useState(null); // Track the last filter change ('shift' or 'week')

    // Update data based on the latest filter (either shift or week)
    useEffect(() => {
        let updatedData = [75, 85, 60, 90, 80]; // Default data

        if (lastFilter === 'shifts') {
            if (filters?.shifts.length==1) {
                updatedData = [75 / 2, 85 / 2, 60 / 2, 90 / 2, 80 / 2]; // Shift 1 logic
            } else if (filters?.shifts.length==2) {
                updatedData = [40, 20, 10, 20, 30]; // Shift 2 logic
            } else if (filters?.shifts.length==3) {
                updatedData = [40 * 2, 20 * 2, 10 * 2, 20 * 2, 30 * 2]; // Shift 3 logic
            }
        } else if (lastFilter === 'areas') {
            if (filters?.areas.length==1) {
                updatedData = [60, 70, 50, 80, 65]; // Week 1 logic
            } else if (filters?.areas.length==2) {
                updatedData = [65, 75, 55, 85, 70]; // Week 2 logic
            } else if (filters?.areas.length==3) {
                updatedData = [70, 80, 58, 88, 75]; // Week 3 logic
            } else if (filters?.areas.length==4) {
                updatedData = [75, 85, 60, 90, 80]; // Week 4 logic
            }
        }

        setSeries2([
            {
                name: 'Accuracy',
                data: updatedData,
            },
        ]);
    }, [filters, lastFilter]); // Update when either filters or lastFilter change

    // Detect and set the latest applied filter
    useEffect(() => {
        
        setLastFilter('shifts'); // Set 'shifts' as the latest filter
    
}, [filters?.shifts]);
useEffect(() => {
   
        setLastFilter('areas'); // Set 'areas' as the latest filter

}, [ filters?.areas]); // Watch for week changes // Watch for both shift and week changes
console.log('last filter', lastFilter)
const secondOptions = {
    chart: {
        type: 'bar',
        width: '100%',
        toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            autoSelected: 'zoom'
        },
    },
    xaxis: {
        categories: ['Helmet', 'Vest', 'Emergency Exit', 'Machine Guard', 'MMHE'],
        labels: {
            style: {
                fontSize: '10px', // Set a default font size
            },
            rotate: 0, // Rotate the labels to fit in limited space
            trim: false, // Trim labels if they are too long
        },
    },
    yaxis: {
        title: {
            text: 'Accuracy (%)',
        },
        min: 0,
        max: 100,
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '45%', // Reduce the width of the bars to fit more categories
            // endingShape: 'rounded',
            borderRadius: 4,
        },
    },
    fill: {
        colors: ['#1E90FF', '#FF6347', '#32CD32', '#FFD700', '#9370DB'],
    },
    dataLabels: {
        enabled: false,
    },
    title: {
        text: 'Accuracy of AI Models',
        align: 'center',
        style: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
    },
    responsive: [
        {
        breakpoint:  1367,
        options: {
                        chart: {
                            width: '100%',
                        },
                        xaxis: {
                            labels: {
                                style: {
                                    fontSize: '15px', // Adjust font size for smaller screens
                                },
                                rotate: -45, // Rotate labels for better fit
                            },
                        },
                    }
        }
    ]
    
    // responsive: [
    //     {
    //         breakpoint: 1520,
    //         options: {
    //             chart: {
    //                 width: '100%',
    //             },
    //             xaxis: {
    //                 labels: {
    //                     style: {
    //                         fontSize: '12px', // Adjust font size for smaller screens
    //                     },
    //                     rotate: 45, // Rotate labels for better fit
    //                 },
    //             },
    //             plotOptions: {
    //                 bar: {
    //                     columnWidth: '50%', // Adjust bar width for smaller screens
    //                 },
    //             },
    //         },
    //     },
    //     {
    //         breakpoint: 1436,
    //         options: {
    //             chart: {
    //                 width: '100%',
    //             },
    //             xaxis: {
    //                 labels: {
    //                     style: {
    //                         fontSize: '10px', // Further adjust font size for smaller screens
    //                     },
    //                     rotate: -45, // Rotate labels for better fit
    //                 },
    //             },
    //             plotOptions: {
    //                 bar: {
    //                     columnWidth: '55%', // Adjust bar width for smaller screens
    //                 },
    //             },
    //         },
    //     },
    //     {
    //         breakpoint: 1435,
    //         options: {
    //             chart: {
    //                 width: '100%',
    //             },
    //             xaxis: {
    //                 labels: {
    //                     style: {
    //                         fontSize: '10px', // Smallest font size for very small screens
    //                     },
    //                     rotate: -45, // Rotate labels for better fit
    //                 },
    //             },
    //             plotOptions: {
    //                 bar: {
    //                     columnWidth: '60%', // Adjust bar width for smaller screens
    //                 },
    //             },
    //         },
    //     },
    // ],
};


    return (
        <>
            <ReactApexChart
                options={secondOptions}
                series={series2} // Updated series with new data
                type="bar"
                height={350}
                width={secondOptions.chart.width}
               
            />
        </>
    );
}
