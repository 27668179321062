import React, { useEffect, useState,useRe } from "react";
import { Download, FileText, Mail, Send, Type } from "react-feather";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import './header.css'
import { toast } from "react-toastify";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'; 
import { useLocation } from 'react-router-dom';
import LiveAnalysis from '../../../assets/ExcelData/Live_Analytics.xlsx';
import CameraConfig  from '../../../assets/ExcelData/Camera_Configuration.xlsx';
import LiveAlert from '../../../assets/ExcelData/Live_Alerts.xlsx'
import TargetControl from '../../../assets/ExcelData/Targets_and_Controls.xlsx'
import Reports from '../../../assets/ExcelData/Reports.xlsx'
const DownloadButton = ({ totalNotifications, data }) => { 
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control,
  } = useForm();
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false)
  const [downloadType, setDownloadType] = useState('')
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const location = useLocation(); 
  

  const handleSubmitEmail = (data) => {

    toast.success(`Send in ${downloadType} format to ${data.email}`);
    reset({
      email: ''  // Clear the email field after submission
    });

  };

  
  const handleClick = (type) => {
    if (type === "email") {
      setShow((prev) => !prev)
    }

    setDownloadType(type)
  }
  const handlePDFdownload  = () => { 
    toast.success("Downloading started")
  // const current=location.pathname.split('/')[2] 
    const input = document.body;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('UAM');
    });
  };

  const namesToFind = ['camera_configuration', 'analytics', 'reports', 'targets','live_alerts'];
 
  const handleExcelDownload = () => {
    // handleClick('excel')
    toast.success("Downloading started")

    const current=location.pathname.split('/') 
    const currentRoute = current.find(element => 
      namesToFind.includes(element.toLowerCase())
  ) || '';
   
    // let fileUrl;
    if(currentRoute=='analytics'){
      //  fileUrl = `/data/ExcelData/Live_Analytics.xlsx`;
      const link = document.createElement('a');
      link.href = LiveAnalysis;
      link.download = 'LiveAnalytics'; 
      document.body.appendChild(link);
      link.click();  
      document.body.removeChild(link);
      return
    }
    else if(currentRoute=='reports'){ 
      // fileUrl = `/data/ExcelData/AI_Model_And_Report.xlsx`;
      const link = document.createElement('a');
      link.href = Reports;
      link.download = 'Reports.xlsx'; 
      document.body.appendChild(link);
      link.click();  
      document.body.removeChild(link);
      return;
    }
    else if(currentRoute=='targets'){
      // fileUrl = `/data/ExcelData/Targets_and_Controls.xlsx`;
      const link = document.createElement('a');
      link.href = TargetControl;
      link.download = 'TargetandControl.xlsx'; 
      document.body.appendChild(link);
      link.click();  
      document.body.removeChild(link);
      return;
    }
    else if(currentRoute=='live_alerts'){
      // fileUrl = `/data/ExcelData/Live_Alerts.xlsx`;
      const link = document.createElement('a');
      link.href = LiveAlert;
      link.download = 'LiveAlert.xlsx'; 
      document.body.appendChild(link);
      link.click();  
      document.body.removeChild(link);
      return;
    }
    else if(currentRoute=='camera_configuration'){
      // fileUrl = `/data/ExcelData/Camera_Configuration.xlsx`;
      const link = document.createElement('a');
      link.href = CameraConfig;
      link.download = 'CameraConfiguration.xlsx'; 
      document.body.appendChild(link);
      link.click();  
      document.body.removeChild(link);
      return;
    }
    else{
      alert('error')
      return
    }
    // const link = document.createElement('a');
    // link.href = fileUrl;
    // link.setAttribute('download', currentRoute);
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }
 
 
  
  

  return (
    <li className="onhover-dropdown">
      <div
        className="notification-box"
        onClick={() => setNotificationDropDown(!notificationDropDown)}
      >
        <Download size={20} />
        <span className="badge rounded-pill badge-secondary">
          {totalNotifications}
        </span>
      </div>
      <div
        className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""
          }`}
      >
        <h6 className="f-18 mb-0 dropdown-title">Download Options</h6>
        <ul>
          <li onClick={handlePDFdownload} className="b-l-primary border-4">
            <p>
              Download PDF{" "}
              <span>
                <Type color={"#1e67d6"} size={18} />
              </span>
            </p>
          </li>
          <li onClick={handleExcelDownload} className="b-l-success border-4">
            <p>
              Download Excel
              <span className="font-success">
                <FileText color={"#1e67d6"} size={18} />
              </span>
            </p>
          </li>
          <li onClick={() => handleClick('email')} className="b-l-danger border-4">
            <p>
              Send as Email
              <span>
                <Mail color={"#1e67d6"} size={18} />
              </span>
            </p>
          </li>
          {show && <form className="mt-3" onSubmit={handleSubmit(handleSubmitEmail)}>

            {/* <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} /> */}
            <Label className="text-start" for="email">Email</Label>
            <FormGroup className="d-flex justify-content-between gap-2">
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email format",
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="email"
                    placeholder="Enter your email"
                  />
                )}
              />

              <Button color="light" className="download-btn-noti">
                <Send color='#ffffff' style={{ color: 'white' }} className="send-btn-noti" />
              </Button>

            </FormGroup>
            {errors.email && (
              <span className="text-danger">{errors.email.message}</span>
            )}

          </form>
          }
        </ul>
      </div>
    </li>
  );
};

export default DownloadButton;
