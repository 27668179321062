import React from 'react'
import DefautlDashboard from '../../Dashboard/Default/index'
const Index = () => {
  return (
    <>
    <DefautlDashboard />
    </>
  )
}

export default Index
