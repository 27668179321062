import React from 'react'
import ItSupport from '../../Dashboard/itSupport/index'

const Index = () => {
  return (
    <>
   <ItSupport />
  </>
  )
}

export default Index
