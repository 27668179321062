import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { H2, H5 } from '../../../../../AbstractElements';
import { Typeahead } from 'react-bootstrap-typeahead';
import GenerateButton from '../../../../Common/newButton/index';
import '../../../Super Admin Dashboard/Components/Form/form.css'
import '../../../ItDashboard/It Officer/Styling/itStyle.css'
import { generatePassword } from '../../../../../utils/generatePassword';
import { ArrowLeft, Eye, EyeOff } from 'react-feather';
import { validatePassword } from '../../../../../utils/passwordValidation';
import formDataContext from '../../../../../_helper/formData';
import { useNavigate } from 'react-router';
import { areaOptionsData, subareaOptionsData } from '../../../../../Data/staticData/data';

const AddUser = () => {
    const { register, handleSubmit, formState: { errors }, setValue, watch, control } = useForm();
    const initialRole = JSON.parse(localStorage.getItem('role') || '"user"');
    const { AddUserData, setAddUserData } = useContext(formDataContext)
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isAllSelectedSub, setIsAllSelectedSub] = useState(false);
    const [eyeOpen, seteyeOpen] = useState(false)
    const areaOptions = areaOptionsData;
    const subAreasOptions = subareaOptionsData;
    const navigate = useNavigate()

    const [role] = useState(initialRole);
    const [multiple] = useState(true);
    const [formData, setFormData] = useState({
        userId: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        role: '',

        id: '',
        status: true,
        createdBy: role,
        passwordReneval: '90 Days',
        inAppNotification: true,
        emailNotification: false,
        emailNotifications: [],
        whatsappNotification: false,
        whatsappNotifications: [],
        Areas: [],
        factories: [],
        subArea: [],
    });

    useEffect(() => {
        // Check if there is data to edit
        if (AddUserData && AddUserData.name) {
            // Populate the form with AddUserData
            setFormData({ ...AddUserData });
        }
    }, [AddUserData]);

    // Notification-specific states
    const [notificationAreas, setNotificationAreas] = useState([]);
    const [notificationSubAreas, setNotificationSubAreas] = useState([]);

    //Notification whatsapp
    const [notificationAreasWhatsapp, setNotificationAreasWhatsapp] = useState([]);
    const [notificationSubAreasWhatsapp, setNotificationSubAreasWhatsapp] = useState([]);


    const typeHeadChange = (key, selected) => {
        setFormData((prev) => ({
            ...prev,
            [key]: selected
        }));
    };

    const genPassword = (e) => {
        e.preventDefault();
        const password = generatePassword()
        setValue('password', password)
        setFormData((prev) => ({
            ...prev,
            password: password
        }));
    };

    const handleCancel = (e) => {
        e.preventDefault()
        setFormData({
            userId: '',
            name: '',
            email: '',
            phone: '',
            password: '',
            role: '',
            id: '',
            status: true,
            createdBy: role,
            passwordReneval: '90 Days',
            inAppNotification: true,
            emailNotification: false,
            emailNotifications: [],
            whatsappNotification: false,
            whatsappNotifications: [],
            Areas: [],
            factories: [],
            subArea: [],
        });
    }
    const generateID = (e) => {
        e.preventDefault(); // Prevent default form submission
        const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let UserID = '';
        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            UserID += chars[randomIndex];
        }
        setValue('userId', UserID); // Set user ID value using setValue
        setFormData((prev) => ({ ...prev, userId: UserID })); // Update the local state for display
    };

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
            status: 'Active',
            createdBy: role,
            passwordReneval: '90 Days'
        }));

    };

    const handleSave = () => {
        // if (!formData.name || !formData.password || !formData.email || !formData.role) {
        //     alert('Please fill all the required fields');
        //     return;
        // }
        // if (!formData.email.includes('@')) {
        //     alert('Please enter a valid email address');
        //     return;
        // }
        // Here you would typically send the data to your backend
        console.log('User data to be saved:', formData);
        setAddUserData({ ...formData })

        const role = JSON.parse(localStorage.getItem('role')); // Get the role from localStorage
        const url = `${process.env.PUBLIC_URL}/dashboard/default/${role}`; // Construct the URL

        // Navigate to the constructed URL with state
        navigate(url, { state: { addUser: true } });
        // navigate(`${process.env.PUBLIC_URL}/dashboard/default/${JSON.parse(localStorage.getItem('role'))}`, {state: {addUser: true}})

        // Reset form or navigate away after successful save
    };
 
    
    const getFilteredSubArea =()=> {
        if(formData.Areas.length === 0 || isAllSelected) {
           return Object.values(subAreasOptions).flat()
        }

        return formData.Areas.flatMap(area => subAreasOptions[area] || [])
    }

    console.log('formdata area', formData.Areas)

    console.log('getFilteredSubArea', getFilteredSubArea())

    const handleSelectAllAreas = (e) => {
        e.preventDefault();
        if (isAllSelected) {
            // Clear all selections
            setFormData((prev) => ({
                ...prev,
                Areas: []
            }));
        } else {
            // Select all areas
            setFormData((prev) => ({
                ...prev,
                Areas: areaOptions

            }));
        }
        setIsAllSelected(!isAllSelected); // Toggle selection state
    };
    const handleSelectAllSubAreas = (e) => {
        e.preventDefault();
        if (isAllSelectedSub) {
            // Clear all selections
            setFormData((prev) => ({
                ...prev,
                subArea: []
            }));
        } else {
            let sub;
            if(formData.Areas.length === 0 || isAllSelected) {
                sub = Object.values(subAreasOptions).flat()
             }else {
               sub = formData.Areas.flatMap(area => subAreasOptions[area] || [])
             }
            // Select all areas
            setFormData((prev) => ({
                ...prev,
                subArea: sub
            }));

        }
        setIsAllSelectedSub(!isAllSelectedSub); // Toggle selection state
    };

    const goBack = () => {

        window.history.back();
    }



    const style = { PaddingTop: '20px' }
    const classNamesForPara = 'mb-0 mt-3'
    return (
        <Container fluid={true} className="dashboard-first-page">
            <div className='d-flex gap-4'>
                <div>
                    <button className="back-button border" onClick={goBack}>
                        <ArrowLeft color='black' size={20} />

                    </button>
                </div>
                <H2 className="mb-4">Add User</H2>
            </div>



            <div className=" border rounded shadow-sm mb-5" style={{ padding: '30px' }}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <Row>
                        <Col lg={5} md={6} sm={12} className="mb-4">
                            <H5>Basic Information</H5>
                            <FormGroup>
                                <Label for="id">User ID</Label>
                             
                                <div className="d-flex mb-2">
                                    <Controller
                                        name="userId"
                                        control={control}
                                        rules={{ required: 'Name is required' }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                id="userId"
                                                value={formData.userId}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setFormData((prev) => ({ ...prev, userId: e.target.value }));
                                                }}
                                                className="me-2"
                                            />
                                        )}
                                    />
                                    <GenerateButton btnText='Generate ID' onClick={generateID} height='40px' width='160px' />
                                </div>
                                {errors.userId && <span className="text-danger">{errors.userId.message}</span>}

                            </FormGroup>
                          
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: 'Name is required' }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="name"
                                            value={formData.name}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setFormData((prev) => ({ ...prev, name: e.target.value }));
                                            }}
                                        />
                                    )}
                                />
                                {errors.name && <span className="text-danger">{errors.name.message}</span>}
                            </FormGroup>
                            <FormGroup>
                              
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{ required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email format' } }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                id="email"
                                                value={formData.email}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleInputChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.email && <span className="text-danger">{errors.email.message}</span>}
                                </FormGroup>
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="role">Role</Label>
                                <Controller
                                    name="role"
                                    control={control}
                                    rules={{ required: 'Role is required' }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="select"
                                            id="role"
                                            value={formData.role}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange(e);
                                            }}
                                        >
                                            <option value="">Select Role</option>
                                            <option value="Global">Global</option>
                                            <option value="Factory">Factory</option>
                                            <option value="Area">Area</option>
                                            <option value="Tech QA">Tech QA</option>
                                        </Input>
                                    )}
                                />
                                {errors.role && <span className="text-danger">{errors.role.message}</span>}
                            </FormGroup>
                            {(formData.role === 'Global' || formData.role === 'Tech QA') && (
                                <FormGroup>
                                    <Label>Select Factories</Label>
                                    <Typeahead
                                        id="factories"
                                        labelKey="name"
                                        multiple={multiple}
                                        options={['ICF', 'RYK', 'Foods']}
                                        selected={formData.factories}
                                        onChange={(selected) => typeHeadChange('factories', selected)}

                                    />
                                </FormGroup>
                            )}

                            {(formData.role === 'Global' || formData.role === 'Factory' || formData.role === 'Tech QA') && (
                                <FormGroup>
                                    <Label>Select Areas</Label>
                                    <div className="d-flex align-items-center gap-2 mb-2">
                                        <Typeahead
                                            className='w-100'
                                            id="areas"
                                            labelKey="name"
                                            multiple={multiple}
                                            options={areaOptions}
                                            selected={formData.Areas}
                                            onChange={(selected) => typeHeadChange('Areas', selected)}

                                        />
                                        <GenerateButton
                                            btnText={isAllSelected ? 'Clear All' : 'Select All'}
                                            onClick={handleSelectAllAreas}
                                            height='38px'
                                            width='160px'
                                            className="align-self-start"
                                            bgcolor={isAllSelected ? 'bg-danger' : ''} // Change color to red when "Clear All"
                                        />
                                    </div>
                                </FormGroup>
                            )}
                            {(formData.role === 'Global' || formData.role === 'Factory' || formData.role === 'Area' || formData.role === 'Tech QA') && (
                                <FormGroup>
                                    <Label>Select Sub Areas</Label>
                                    <div className="d-flex align-items-center gap-2 mb-2">
                                        <Typeahead
                                            className='w-100'
                                            id="subAreas"
                                            labelKey="name"
                                            multiple={multiple}
                                            options={getFilteredSubArea()}
                                            selected={formData.subArea}
                                            onChange={(selected) => typeHeadChange('subArea', selected)}

                                        />
                                        <GenerateButton
                                            btnText={isAllSelectedSub ? 'Clear All' : 'Select All'}
                                            onClick={handleSelectAllSubAreas}
                                            height='38px'
                                            width='160px'
                                            className="align-self-start"
                                            bgcolor={isAllSelectedSub ? 'bg-danger' : ''} // Change color to red when "Clear All"
                                        />
                                    </div>

                                </FormGroup>
                            )}
                            <FormGroup>
                                <Label for="phone">Mobile no</Label>
                                <Input type="tel" name="phone" id="phone" value={formData.phone} onChange={handleInputChange} />
                            </FormGroup>
                           
                            <FormGroup style={{ position: 'relative' }}>
                                <Label for="password">Password</Label>
                                <div className="d-flex align-items-center">
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{
                                            required: 'Password is required',
                                            minLength: { value: 8, message: 'Password must be at least 8 characters long' },
                                            validate: validatePassword // Add custom validation
                                        }} render={({ field }) => (
                                            <Input
                                                {...field}
                                                type={eyeOpen ? 'text' : 'password'}
                                                id="password"
                                                value={formData.password}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleInputChange(e);
                                                }}
                                                className="me-2"
                                            />
                                        )}
                                    />
                                    <GenerateButton btnText='Generate Password' onClick={genPassword} height='40px' width='200px' />
                                </div>
                                {errors.password && <span className="text-danger">{errors.password.message}</span>}
                                {eyeOpen ? <Eye onClick={() => seteyeOpen(!eyeOpen)} strokeWidth={2} size={20} color='#6c757d' style={{ position: 'absolute', top: '34px', right: '170px', cursor: 'pointer' }} /> : <EyeOff onClick={() => seteyeOpen(!eyeOpen)} strokeWidth={2} size={20} color='#6c757d' style={{ position: 'absolute', top: '34px', right: '170px', cursor: 'pointer' }} />}
                                <p className="text-muted mt-2">
                                    Please make a password that has at least one uppercase letter, one lowercase letter, one number, one special character, and a minimum length of 8 characters.
                                </p>
                            </FormGroup>
                        </Col>



                        <Col lg={7} md={12} sm={12} className="mb-4">

                            <Row className='d-flex justify-content-center'>
                                <Col xs={12} lg={11} className='d-flex flex-column justify-content-center'>
                                    <H5>Notifications</H5>
                                    <div className="border rounded p-3 mb-3" style={{ marginTop: '23px' }}>
                                        <FormGroup switch>
                                            <Input
                                                type="switch"
                                                checked={formData.emailNotification}
                                                // onChange={() => setFormData((prev) => ({ ...prev, emailNotification: !prev.emailNotification }))}
                                                onChange={() => {
                                                    setFormData((prev) => ({ ...prev, emailNotification: !prev.emailNotification }));
                                                    if (!formData.emailNotification) {
                                                        // Pre-fill with previously selected values if turning on
                                                        setNotificationAreas([...formData.Areas]);
                                                        setNotificationSubAreas([...formData.subArea]);
                                                    }
                                                }}
                                                style={{ border: '1px solid #dee2e6', cursor: 'pointer' }}
                                            />
                                            <Label check>
                                                <strong>Email Notification</strong>
                                            </Label>
                                            {formData.emailNotification && (
                                                <>
                                                    <p className={classNamesForPara} style={style}>Receive emails for the <b>Areas</b> below?</p>
                                                    <Typeahead
                                                        id="notificationEmailAreas"
                                                        labelKey="name"
                                                        multiple={multiple}
                                                        options={areaOptions}
                                                        selected={notificationAreas}
                                                        onChange={(selected) => setNotificationAreas(selected)}
                                                        placeholder='Areas'
                                                    />
                                                    <p className={classNamesForPara} style={style}>Receive emails for the <b>Sub Areas</b> below?</p>
                                                    <Typeahead
                                                        id="notificationEmailSubAreas"
                                                        labelKey="name"
                                                        multiple={multiple}
                                                        options={subAreasOptions}
                                                        selected={notificationSubAreas}
                                                        onChange={(selected) => setNotificationSubAreas(selected)}
                                                        placeholder='Sub Areas'
                                                    />
                                                </>
                                            )}
                                            <p className={classNamesForPara} style={style}>Receive Email notification when alerts get triggered. Who should be notified?</p>
                                            <Typeahead
                                                id="emailNotifications"
                                                labelKey="name"
                                                multiple={multiple}
                                                options={[]}
                                                selected={formData.emailNotifications}
                                                onChange={(selected) => typeHeadChange('emailNotifications', selected)}
                                                placeholder="Enter email addresses"
                                                allowNew
                                            />
                                        </FormGroup>

                                    </div>
                                    <div className="border rounded p-3">
                                        <FormGroup switch>

                                            <Input
                                                type="switch"
                                                checked={formData.whatsappNotification}
                                                // onChange={() => setFormData((prev) => ({ ...prev, whatsappNotification: !prev.whatsappNotification }))}
                                                onChange={() => {
                                                    setFormData((prev) => ({ ...prev, whatsappNotification: !prev.whatsappNotification }));
                                                    if (!formData.whatsappNotification) {
                                                        // Pre-fill with previously selected values if turning on
                                                        setNotificationAreasWhatsapp([...formData.Areas]);
                                                        setNotificationSubAreasWhatsapp([...formData.subArea]);
                                                    }
                                                }}
                                                style={{ border: '1px solid #dee2e6', cursor: 'pointer' }}
                                            />
                                            <Label check>
                                                <strong>Whatsapp Notification</strong>
                                            </Label>
                                            {formData.whatsappNotification && (
                                                <>
                                                    <p className={classNamesForPara} style={style}>Receive whatsapp notification for the <b>Areas</b>  below?</p>
                                                    <Typeahead
                                                        id="notificationWhatsappAreas"
                                                        labelKey="name"
                                                        multiple={multiple}
                                                        options={areaOptions}
                                                        selected={notificationAreasWhatsapp}
                                                        onChange={(selected) => setNotificationAreasWhatsapp(selected)}
                                                        placeholder='Areas'
                                                    />

                                                    <p className={classNamesForPara} style={style}>Receive whatsapp notification for the <b>Sub Areas</b> below?</p>
                                                    <Typeahead
                                                        id="notificationWhatsappSubAreas"
                                                        labelKey="name"
                                                        multiple={multiple}
                                                        options={subAreasOptions}
                                                        selected={notificationSubAreasWhatsapp}
                                                        onChange={(selected) => setNotificationSubAreasWhatsapp(selected)}
                                                        placeholder='Sub Areas'
                                                    />
                                                </>
                                            )}
                                            <p className={classNamesForPara} style={style}>Receive Whatsapp notification when alerts get triggered. Who should be notified?</p>
                                            <Typeahead
                                                id="whatsappNotifications"
                                                labelKey="name"
                                                multiple={multiple}
                                                options={[]}
                                                selected={formData.whatsappNotifications}
                                                onChange={(selected) => typeHeadChange('whatsappNotifications', selected)}
                                                placeholder="Enter phone numbers"
                                                allowNew
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row className="mt-4 pb-2">
                        <Col className="d-flex justify-content-center gap-2">
                            <GenerateButton btType='submit' btnText='Add User' width='150px' height='40px' />
                            <GenerateButton btnText='Cancel' onClick={handleCancel} bgcolor='bg-secondary' width='100px' height='40px' />
                        </Col>
                    </Row>
                </form>
            </div>

        </Container>
    );
};

export default AddUser;

