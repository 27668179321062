import React, { useContext, useState } from 'react';
import { Grid } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import CubaIcon from '../../assets/images/logo/login-logo.svg';
import uniIcon from '../../assets/images/logo/uni-logo.jpeg';
import CustomizerContext from '../../_helper/Customizer';
import './side.css'
const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, toggleIcon, layout, layoutURL } = useContext(CustomizerContext);
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('role')))
console.log("layoutURL", layoutURL)
console.log("layout", layout)
console.log("toggleSidebar", toggleSidebar)
console.log("toggleIcon", toggleIcon)

  const openCloseSidebar = () => {
    console.log("cons")
      toggleSidebar(!toggleIcon);
      const pageBody = document.querySelector('.page-body')
      const iconClass = document.querySelector('.close_icon')
      if (!iconClass) {
        pageBody.classList.add('icon-class-available');
    } else {
        pageBody.classList.remove('icon-class-available');
    }
  };
  let navigation = undefined
  switch (true) {
    case role === 'global':
      navigation = `${process.env.PUBLIC_URL}/analytics/global`
      break;
    case role === 'it-officer':
      navigation = `${process.env.PUBLIC_URL}/dashboard/default/it-officer`
      break;
    case role === 'super-admin':
      navigation = `${process.env.PUBLIC_URL}/dashboard/default/super-admin`;
      break;

    case role === 'company':
      navigation = `${process.env.PUBLIC_URL}/dashboard/default/company`;
      break;

    case role === 'area':
      navigation = `${process.env.PUBLIC_URL}/dashboard/default/area`;
      break

    case role === 'qa':
      navigation = `${process.env.PUBLIC_URL}/dashboard/default/qa`;
      break
  }

  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  return (
    <div className='logo-wrapper new-logo-wrapper'>
      {layout1 !== 'compact-wrapper dark-sidebar' && layout1 !== 'compact-wrapper color-sidebar' && mixLayout ? (
        navigation ? (<Link to={navigation}>
          <Image attrImage={{ className: 'img-fluid d-inline uni-logo', style: { height: '110px' }, src: `${role === 'global' ? uniIcon : CubaIcon}`, alt: 'logo-main' }} />
        </Link>) : (<div>
          <Image attrImage={{ className: 'img-fluid d-inline uni-logo', style: { height: '110px' }, src: `${role === 'global' ? uniIcon : CubaIcon}`, alt: 'logo-main' }} />
        </div>)
      ) : (
        navigation ? (<Link to={navigation} className='d-flex justify-content-center'>
          <Image attrImage={{ className: 'img-fluid d-inline uni-logo', src: `${uniIcon}`, alt: 'main-logo' }} />
          {/* <Image attrImage={{ className: 'img-fluid d-inline uni-logo', src:`${role === 'global' ? uniIcon : CubaIcon}`, alt: 'main-logo' }} /> */}
        </Link>): (<div className='d-flex justify-content-center'>
          <Image attrImage={{ className: 'img-fluid d-inline uni-logo', src: `${uniIcon}`, alt: 'main-logo' }} />
          {/* <Image attrImage={{ className: 'img-fluid d-inline uni-logo', src:`${role === 'global' ? uniIcon : CubaIcon}`, alt: 'main-logo' }} /> */}
        </div>)
        
      )}
      <div className='back-btn btn-back' role='button' onClick={openCloseSidebar}>
        <i className='fa fa-angle-left'></i>
      </div>
      <div className='toggle-sidebar' onClick={openCloseSidebar}>
        {/* <Grid className='status_toggle middle sidebar-toggle' /> */}
        <i className={`fa ${toggleIcon ? 'fa-angle-right' : 'fa-angle-left'} status_toggle middle sidebar-toggle`} ></i>
      </div>
    </div>
  );
};

export default SidebarLogo;
