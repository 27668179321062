export const isAuthenticateUser=(email, password, setRole)=> {
    let role = '';
    let isAuthenticated = false;
    let name= '';

    console.log('em ',email )
    console.log('em ',password )
    

    if (email === "bilal@gmail.com" && password === "test") {
        role = 'super-admin';
        isAuthenticated = true;
        name = 'Super Admin'
      } else if (email === "Hasan.Aurangzeb@unilever.com"  && password === "test") {
        role = 'it-officer';
        isAuthenticated = true;
        name = 'Hasan'
      } else if (email === "Ameer.Hamza@unilever.com" && password === "test") {
        role = 'global';
        isAuthenticated = true;
        name = 'Hamza'
      } else if (email === "factory@gmail.com" && password === "test") {
        role = 'company';
        isAuthenticated = true;
        name = 'Factory'
      } else if (email === "area@gmail.com" && password === "test") {
        role = 'area';
        name = 'Area Owner'
        isAuthenticated = true;
      }else if (email === "qa@gmail.com" && password === "test"){
        role = 'qa';
        isAuthenticated = true;
        name = 'Tech QA'
    }

      if(isAuthenticated){
        localStorage.setItem("login", JSON.stringify(true));
        localStorage.setItem("role", JSON.stringify(role));
        localStorage.setItem("name", JSON.stringify(name))
        if (typeof setRole === 'function') {
          setRole(role);
      }
        return true
      }else {
        return false
      }
}