import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

// import deleteIcon from '../../../assets/SuperAdminIcons/Delete.png';
import deleteIcon from '../../../../../assets/SuperAdminIcons/Delete.png';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import search from '../../../../../assets/SuperAdminIcons/Search.png';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ItDataTable from '../Components/Table/ItDataTable';
import Calender from '../Components/Calender';
import { H3, H4 } from '../../../../../AbstractElements';
import SearchInput from '../../../../Common/Search Input/searchInput';
import DateTime from '../../../CompanyDashbaord/Components/DateTime';


const ITNotifications = () => {
    const tableColumns = [
        { name: 'Operation', selector: row => row.operation, sortable: true },
        { 
            name: 'Status', 
            cell: row => (
                <button type="button" className="btn btn-success">{row.status}</button>
            )
        },
        { name: 'Timestamp', selector: row => row.timestamp, sortable: true },
        
        { 
            name: 'Delete',
            cell: row => (
                <div>
                    <button onClick={() => handleDeleteClick(row)} style={{ border: 'none', background: 'none' }}>
                        <img src={deleteIcon} alt="Delete" style={{ width: '20px', height: '20px' }} />
                    </button>
                </div>
            )
        },


    ];

    const [data, setData] = useState([]);
    const [searchItem, setSearchItem] = useState('');
    const [filteredRows, setFilteredRows] = useState(undefined);
    // const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    // const [showDate, setfirst] = useState(second)
   

    // useEffect(() => {
    //     const notificationsIT = JSON.parse(localStorage.getItem('notificationsIT')) || [];
    //     console.log("notificationsIT", notificationsIT)
    //     setData(notificationsIT);
    //     setFilteredRows(notificationsIT); // Initialize filteredRows with all data
    // }, []);
    useEffect(() => {
        const notificationsIT = JSON.parse(localStorage.getItem('notificationsIT')) || [];
    
        // Process notifications to capitalize role and split by hyphen if necessary
        // const processedNotifications = notificationsIT.map(notification => {
        //     const roleSplit = notification.role.split('-').map(el => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase());
        //     const capitalizedRole = roleSplit.join(' '); // Join with a space between words
        //     return {
        //         ...notification,
        //         role: capitalizedRole // Update the role field with the formatted role
        //     };
        // });
    
        // console.log("Processed Notifications:", processedNotifications);
    
        // Update state with processed data
        setData(notificationsIT);
        setFilteredRows(notificationsIT); // Initialize filteredRows with all data
    }, []);

    const handleDeleteClick = (row) => {
        const updatedData = data.filter(item => item !== row);
        setData(updatedData);
        setFilteredRows(updatedData);
        localStorage.setItem('notificationsIT', JSON.stringify(updatedData));
    };

    const handleEditClick = () => {
        // Functionality for handling edit clicks if needed
    };

    const handleSearch = (e) => {
        setSearchItem(e.target.value)
        if (!e.target.value.trim()) {
            setFilteredRows(e.target.value);   
            return;
        } 
        // Filter notifications based on the search input
        const filtered = data.filter(item =>
            item.operation.toLowerCase().includes(searchItem.toLowerCase())
        );

        setFilteredRows(filtered);
    };

  

    return (
        <div>
            <Container fluid={true} style={{ paddingTop: '30px', border: '0px solid' }}>
            <Row className='header d-flex justify-content-between align-items-center permissioncolres'>
                <Col xl='9' lg='8' md='8' sm='8' xs='12' className='permissioncolresCol'>   
                <H4>
                Activity Monitor
                    </H4>  
                </Col>
                <Col className='d-flex justify-content-start justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-start' xl='3' lg='4' md='4' sm='4' xs='12'>
                    {/* <button
                        className='btn addbtnres'
                        style={{ background: '#1C3664', color: 'white', padding: '8px 20px', height: '45px', maxHeight: '45px' }}
                        onClick={() => setShowDateTimePicker(!showDateTimePicker)}
                    >
                        Date/Time
                    </button> */}
                    {/* <Calender showDateTimePicker={showDateTimePicker} setShowDateTimePicker={setShowDateTimePicker}/> */}
                </Col>
            </Row>
            {/* {showDateTimePicker && (
                <Row className='d-flex justify-content-end'>
                    <Col xl='10' lg='8' md='8' sm='8' xs='12'>
                        <Card className='shadow-none'>
                            <CardBody className='d-flex flex-column align-content-center' style={{ padding: '12px 12px 12px 20px' }}>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select Date"
                                />
                                <DatePicker
                                    selected={startTime}
                                    onChange={handleStartTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Start Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Select Start Time"
                                    className='mt-2'
                                />
                                <DatePicker
                                    selected={endTime}
                                    onChange={handleEndTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="End Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Select End Time"
                                    className='mt-2'
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )} */}
                   <Row className='d-flex justify-content-between align-items-center '>
                    <Col style={{marginTop:'25px'}} xl='5' lg='5' md='5' sm='5' xs='12'>
                        <Card className='shadow-none '>
                            <CardBody className='d-flex justify-content-start align-items-center' style={{ padding: '12px 12px 12px 20px' }}>
                                <img src={search} alt="search icon" style={{ width: '16px', height: '16px', marginRight: '15px', marginTop: '2px' }} />
                                <input
                                    type="text"
                                    placeholder='Search activity'
                                    value={searchItem}
                                    onChange={handleSearch}
                                    style={{ width: '100%', border: '0px', fontSize: '16px', padding: '0px', margin: '0px' }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col   className=' d-flex justify-content-start justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center' xl='7' lg='7' md='7' sm='7' xs='12'>
                        {/* <button
                        className='btn addbtnres'
                        style={{ background: '#1C3664', color: 'white', padding: '8px 20px', height: '45px', maxHeight: '45px' }}
                        onClick={() => setShowDateTimePicker(!showDateTimePicker)}
                    >
                        Date/Time
                    </button> */}
                       <DateTime  data={data} filteredRows={filteredRows} setFilteredRows={setFilteredRows} />

                    </Col>
                </Row>
                <Card>
                    <CardHeader>
                        <h4 className='logsheadingres'>User Logs</h4>
                    </CardHeader>
                    <CardBody className='p-0 pb-3'>
                        <ItDataTable 
                            tableColumns={tableColumns} 
                            onRowClick={handleEditClick} 
                            staticData={filteredRows ? filteredRows : data} 
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default ITNotifications;
