import api from './api'

const AreaService = {

    getAreaDashboard (payload) {
        return api.put(`area/get_area_dashboard`,payload)
    },
    getRecentAlerts (payload) {
        return api.put(`area/get_recent_alerts`,payload)
    },
    getHelmetModule (payload) {
        return api.put(`area/get_helmet_dashboard`,payload)
    },
    getLiveCameras (payload) {
        return api.put(`area/get_live_cameras`,payload)
    },
    getWeeklyAlerts (payload) {
        return api.put(`area/get_weekly_alerts`,payload)
    },
    getMonthlyAlerts (payload) {
        return api.put(`area/get_monthly_alerts`,payload)
    },
    getDurationAlerts (payload) {
        return api.put(`area/get_duration_alerts`,payload)
    },

}

export default AreaService;