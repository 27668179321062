import React from "react";
import { Row, Col } from "reactstrap";

const Loader1 = () => {
  return (
    <Row>
      <Col sm="12" style={{ margin: "40px 0px" }}>
        <h5 className="sub-title mb-0 text-center">{"Loading"}</h5>
        <div className="loader-box">
          <div className="loader-17"></div>
        </div>
      </Col>
    </Row>
  );
};
export default Loader1;

export const SquareLoader = () => {
  return (
    <div className="loader-box" style={{ padding: 0, margin: 0, height: 60 }}>
      <div
        className="loader-38"
        style={{
          fontSize: 30,
        }}
      ></div>
    </div>
  );
};
