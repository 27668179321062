import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/appointment/app-ent.jpg";
import uniIcon from '../../../assets/images/logo/uni-logo.jpeg'

import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, BackgroundColor, Color, Inbox, LogOut, Taskboard } from "../../../Constant";
import PopupStateContext from "../../../_helper/popupState";
import './header.css'

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Emay Walter");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const { setResyncRole} = useContext(PopupStateContext)
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('role')))

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("name") ? JSON.parse(localStorage.getItem("name")) : name);
  }, []);

  const Logout = () => {
    localStorage.removeItem("login")
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.setItem("authenticated", false);
    setResyncRole(true)
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };
 

  return (
    <li className="profile-nav onhover-dropdown py-0 ms-2  px-2 py-2 rounded bg-primary
    " >
      <div className="media profile-media">
      
      <div className="uni-logo-div">

      
        <Image
          attrImage={{
            className: "b-r-10 m-0 uni-logoo",
            src: `${uniIcon}`,
            // src: `${role === 'global' ? uniIcon : man}`,
            alt: "",
          }}
        />
        </div>
        <div className="media-body pe-2" >
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            <span style={{color: 'white'}}>{Admin} <i className="middle fa fa-angle-down"></i></span>
          </P>
          <span>{authenticated ? auth0_profile.name : name}</span>
        </div>
      </div>
      <UL
        attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}
      >
        {/* <LI attrLI={{}}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI attrLI={{}}>
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI attrLI={{}}>
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}.
            
          </span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
