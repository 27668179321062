// import React, { Fragment } from "react";
// import Lightbox from "react-18-image-lightbox";
import "./zoom.css";
// import { Container } from "react-bootstrap";

// const SingleImage = ({ photo, isOpen, setIsOpen }) => {
//   // const onMovePrev = () => {
//   //   const prev = (photoIndex.index + images.length - 1) % images.length;
//   //   setPhotoIndex({ ...photoIndex, index: prev });
//   // };
//   // const onMoveNext = () => {
//   //   const next = (photoIndex.index + 1) % images.length;
//   //   setPhotoIndex({ ...photoIndex, index: next });
//   // };
//   return (
//     <Fragment>

//   {/* <Lightbox mainSrc={require(`../../assets/images/${images[photoIndex.index]}`)} nextSrc={require(`../../assets/images/${images[(photoIndex.index + 1) % images.length]}`)} prevSrc={require(`../../assets/images/${images[(photoIndex.index + images.length - 1) % images.length]}`)} imageTitle={photoIndex.index + 1 + "/" + images.length} onCloseRequest={() => setPhotoIndex({ ...photoIndex, isOpen: false })} onMovePrevRequest={onMovePrev} onMoveNextRequest={onMoveNext} /> */}
//   <Container fluid={true} className="zoom-image-container">
//          <Lightbox mainSrc={photo}
//           onCloseRequest={() => setIsOpen(false)}
//           width={400}
//            />
//            </Container>

//     </Fragment>
//   );
// };

// export default SingleImage;

import React, { Fragment } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Container, Button, Card } from "react-bootstrap";
import { X } from "react-feather";
import { H6, P } from "../../AbstractElements";
import { formateDate } from "../../utils/formatDate";

const SingleImage = ({ photo, setIsOpen, imageData }) => {
  // console.log('imageDataaa', imageData)
  return (
    <Fragment>
      <div className="zoom-image-container">
        <Container
          fluid={true}
          className="zoom"
          style={{ textAlign: "center" }}
        >
          <X
            type="button"
            width={35}
            onClick={() => setIsOpen(false)}
            className="x-icon"
          />
          <div className="zoom-image-div">
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <Fragment>
                  <div style={{ marginBottom: "10px" }}>
                    <Button
                      variant="primary"
                      onClick={() => zoomIn()}
                      style={{ marginRight: "5px" }}
                    >
                      Zoom In
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => zoomOut()}
                      style={{ marginRight: "5px" }}
                    >
                      Zoom Out
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => resetTransform()}
                    >
                      Reset
                    </Button>
                  </div>
                  <TransformComponent>
                    <img
                    className="zoom-image"
                      src={photo}
                      alt="Zoomable"
                      style={{
                        width: "",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </TransformComponent>
                </Fragment>
              )}
            </TransformWrapper>
          </div>
          <Card className='zoom-card'>
              <H6>Camera: {imageData?.cameraName}</H6>
              <P attrPara={{className:'pb-1 mb-0'}}>Date: {formateDate(imageData?.date)}</P>
              <P>Time: {imageData?.time}</P>
          </Card>
        </Container>
      </div>
    </Fragment>
  );
};

export default SingleImage;
