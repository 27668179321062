import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Media, Row } from 'reactstrap';
// import search from '../../../../assets/SuperAdminIcons/Search.png';
import search from '../../../../../assets/SuperAdminIcons/Search.png';
import editIcon from '../../../../../assets/SuperAdminIcons/Edit.png';
import deleteIcon from '../../../../../assets/SuperAdminIcons/Delete.png';
import ItTotalCard from '../Components/Cards/ItTotalCard';
import '../../../Super Admin Dashboard/styling/super.css';
import AddForm from '../Components/Forms/AddForm';
import ItDataTable from '../Components/Table/ItDataTable';
import AddUserForm from '../../../Super Admin Dashboard/Components/Form/AddUserForm';
import { H2, H3, H4 } from '../../../../../AbstractElements';
import { Lock } from 'react-feather';
import { applyButtonFilters } from '../../../../../_helper/helperFunctions/filter';
import '../Styling/itStyle.css'
import { FormGroup } from 'react-bootstrap';
import AddButton from '../../../../Common/newButton/index'
import SearchBar from '../../../../Common/Search Input/searchInput'
import { useLocation, useNavigate } from 'react-router';
import formDataContext from '../../../../../_helper/formData';
import AddUser from './AddUser';


const ItDashboard = ({ typee }) => {
    const navigate = useNavigate()
    const [allpermissions, setAllPermissions] = useState([
        { routeofPermission: 'PPE Model', isActive: false },
        { routeofPermission: 'Emergency Exit', isActive: false },
        { routeofPermission: 'Machine Guard', isActive: false },
        { routeofPermission: 'Fork Lift', isActive: false },
        // { routeofPermission: 'TechQA', isActive: false }
    ])
    const tableData = [
        {
            userId: 'unilever',
            id: 12,
            status: true,
            createdBy: 'Super Admin',
            name: "John Doe",
            email: "john.doe@example.com",
            role: "Global",
            date: "2024-08-01",
            action: "Approved",
            phone: "92313778899",
            passwordReneval: "50 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: false },
                { routeofPermission: 'Emergency Exit', isActive: true },
                { routeofPermission: 'Machine Guard', isActive: false },
                { routeofPermission: 'Fork Lift', isActive: true },

            ],
            inAppNotification: true,
            emailNotification: true,
            emailNotifications: [
                "bilal@gmail.com",
                "ali@gmail.com"
            ],
            whatsappNotification: true,
            whatsappNotifications: [
                "+923123432342", "+923534535345"
            ]
        },
        {
            userId: 'unilever',
            id: 53,
            status: true,
            createdBy: 'Super Admin',
            name: "Wajahat",
            email: "wajahat@example.com",
            role: "it-officer",
            date: "2024-08-01",
            action: "Approved",
            phone: "+92313778899",
            passwordReneval: "50 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: false },
                { routeofPermission: 'Emergency Exit', isActive: true },
                { routeofPermission: 'Machine Guard', isActive: false },
                { routeofPermission: 'Fork Lift', isActive: true },

            ],
            inAppNotification: true,
            emailNotification: true,
            emailNotifications: [
                "bilal@gmail.com",
                "ali@gmail.com"
            ],
            whatsappNotification: true,
            whatsappNotifications: [
                "+923123432342", "+923534535345"
            ]
        },
        {
            userId: 'unilever',
            id: 46,
            status: true,
            createdBy: 'IT Officer',
            name: "Jane Smith",
            email: "jane.smith@example.com",
            phone: "92313778899",
            role: "Global",
            date: "2024-08-02",
            action: "Pending",
            passwordReneval: "40 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: false },
                { routeofPermission: 'Emergency Exit', isActive: true },
                { routeofPermission: 'Machine Guard', isActive: true },
                { routeofPermission: 'Fork Lift', isActive: true },
            ],
            inAppNotification: true,
            emailNotification: false,
            emailNotifications: [],
            whatsappNotification: true,
            whatsappNotifications: [
                "+923123432342", "+923534535345"
            ]
        },
        {
            userId: 'unilever',
            id: 30,
            status: false,
            createdBy: 'IT Officer',
            name: "Michael Johnson",
            email: "michael.johnson@example.com",
            phone: "92313778899",
            role: "Factory",
            date: "2024-08-03",
            action: "Rejected",
            passwordReneval: "12 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: false },
                { routeofPermission: 'Emergency Exit', isActive: false },
                { routeofPermission: 'Machine Guard', isActive: false },
                { routeofPermission: 'Fork Lift', isActive: true },
            ],
            inAppNotification: true,
            emailNotification: true,
            emailNotifications: [
                "Foods@mail.com",
                "uniliver@mail.com"
            ],
            whatsappNotification: false,

        },
        {
            userId: 'unilever',
            id: 29,
            status: true,
            createdBy: 'IT Officer',
            name: "Emily Davis",
            phone: "92313778899",
            email: "emily.davis@example.com",
            role: "Area",
            date: "2024-08-04",
            action: "Approved",
            passwordReneval: "21 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: true },
                { routeofPermission: 'Emergency Exit', isActive: true },
                { routeofPermission: 'Machine Guard', isActive: false },
                { routeofPermission: 'Fork Lift', isActive: true },
            ],
            inAppNotification: true,
            emailNotification: true,
            emailNotifications: [
                "global@mail.com",
                "ali@mail.com"
            ],
            whatsappNotification: true,
            whatsappNotifications: [
                "+923134324234", "+92334234234"
            ]
        },
        {
            userId: 'unilever',
            id: 33,
            status: true,
            createdBy: 'IT Officer',
            name: "William Brown",
            email: "william.brown@example.com",
            role: "Tech QA",
            phone: "92313778899",
            date: "2024-08-05",
            action: "Pending",
            passwordReneval: "19 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: false },
                { routeofPermission: 'Emergency Exit', isActive: true },
                { routeofPermission: 'Machine Guard', isActive: false },
                { routeofPermission: 'Fork Lift', isActive: true },
            ],
            inAppNotification: true,
            emailNotification: true,
            emailNotifications: [
                "it@mail.com",
                "ali@gmail.com"
            ],
            whatsappNotification: true,
            whatsappNotifications: [
                "+923123432342", "+923534535345"
            ]
        },
        {
            userId: 'unilever',
            id: 21,
            status: false,
            createdBy: 'IT Officer',
            name: "William Brown",
            phone: "92313778899",
            email: "william.brown@example.com",
            role: "Area",
            date: "2024-08-05",
            action: "Pending",
            passwordReneval: "15 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: false },
                { routeofPermission: 'Emergency Exit', isActive: true },
                { routeofPermission: 'Machine Guard', isActive: false },
                { routeofPermission: 'Fork Lift', isActive: true },
            ],
            inAppNotification: true,
            emailNotification: true,
            emailNotifications: [
                "unilever@mail.com",
                "Foods@mail.com"
            ],
            whatsappNotification: true,
            whatsappNotifications: [
                "+92312343342342", "+9235344234234"
            ]
        },
        {
            userId: 'unilever',
            id: 36,
            status: true,
            createdBy: 'IT Officer',
            name: "William Brown",
            email: "william.brown@example.com",
            phone: "92313778899",
            role: "Area",
            date: "2024-08-05",
            action: "Pending",
            passwordReneval: "45 Days",
            permissions: [
                { routeofPermission: 'PPE Model', isActive: false },
                { routeofPermission: 'Emergency Exit', isActive: true },
                { routeofPermission: 'Machine Guard', isActive: false },
                { routeofPermission: 'Fork Lift', isActive: true },
            ],
            inAppNotification: true,
            emailNotification: true,
            emailNotifications: [
                "bilal@gmail.com",
                "ali@gmail.com"
            ],
            whatsappNotification: true,
            whatsappNotifications: [
                "+923123432342", "+923534535345"
            ]
        }
    ];
    const location = useLocation()
    const {AddUserData, setAddUserData} = useContext(formDataContext)
    const [data, setData] = useState(tableData)
    const [searchInput, setSearchInput] = useState()
    const [dataAfterFilteration, setDataAfterFilteration] = useState(undefined)
    const [selectedRow, setSelectedRow] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('add');
    const initialRole = JSON.parse(localStorage.getItem('role'))
    const [role, setRole] = useState(initialRole)
    const [filters, setFilters] = useState({
        role: '',
        creator: '',
        status: '', 
        id:''
    })

    const [formData, setFormData] = useState({
        userId: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        role: '',
        permissions: [
            { routeofPermission: 'PPE Model', isActive: false },
            { routeofPermission: 'Emergency Exit', isActive: false },
            { routeofPermission: 'Machine Guard', isActive: false },
            { routeofPermission: 'Fork Lift', isActive: false },
        ],
        id: 27,
        status: true,
        createdBy: role,
        passwordReneval: '90 Days',
        inAppNotification: true,
        emailNotification: true,
        emailNotifications: [],
        whatsappNotification: true,
        whatsappNotifications: [],
        Areas: [],
        factories: [],
        subArea: []
    });
    // useEffect(() => {
    //     console.log(formData, 'Updated formData');
    //     const settingRole = 'IT Officer'; 
    //       const newRole = localStorage.getItem('role')
    //     setRole(newRole);
    // }, [formData,role]);
    const toggleModal = () => setModal(!modal);

    const handleEditClick = (row) => {
      
        setFormData(row);
        setSelectedRow(row);
        setAddUserData(row)
        navigate(`${process.env.PUBLIC_URL}/dashboard/add-user/${role}`)
        setModalType('edit');
        toggleModal();
    }; 

    const handleDeleteClick = (row) => {
        setData(data.filter(item => item !== row));
        const newNotification = {
            operation: `${row.role} - User ${row.name} was Deleted`,
            status: `Success`,
            timestamp: new Date().toLocaleString(),
            role: role
        };

        const notificationsIT = JSON.parse(localStorage.getItem('notificationsIT')) || [];
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        // console.log('this is notification op', newNotification.operation)
        notificationsIT.push(newNotification);
        notifications.push(newNotification);
        localStorage.setItem('notificationsIT', JSON.stringify(notificationsIT));
        localStorage.setItem('notifications', JSON.stringify(notifications));
    };

    const handleAddUserClick = () => { 

        // previous add user by modal Code //
        setFormData({
            name: '', email: '', role: '', date: new Date().toLocaleDateString(),
            role: "",
            action: "",
        });
        // setModalType('add');
        // toggleModal();
        // END previous add user by modal Code//

        //New Add user Code
        navigate(`${process.env.PUBLIC_URL}/dashboard/add-user/${JSON.parse(localStorage.getItem('role'))}`, {state:{type: 'add'}})
    


    };

    const handleInputChange = (e) => {
        const { name, type, checked } = e.target;
        console.log('handle input')

        if (type === 'checkbox') {
            const updatedPermissions = formData.permissions.map(permission =>
                permission.routeofPermission === name
                    ? { ...permission, isActive: checked }
                    : permission
            );

            console.log('updated permissions', updatedPermissions)



            setFormData(prevFormData => ({
                ...prevFormData,
                permissions: updatedPermissions
            }));
        }
        else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: e.target.value, 
                status: 'Active',
                createdBy: role,
                passwordReneval: '90 Days'
            }));
        }

        console.log({ [name]: type === 'checkbox' ? checked : e.target.value });
        setTimeout(20000, console.log(formData, 'from set time out'))
    };


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        // Update the specific permission in the allpermissions array
        const updatedPermissions = allpermissions.map(permission =>
            permission.routeofPermission === name
                ? { ...permission, isActive: checked }
                : permission
        );

        // Update the allpermissions state with the updated permissions
        setAllPermissions(updatedPermissions);

        // Ensure form data also persists the state properly
        setFormData(prevFormData => ({
            ...prevFormData,
            permissions: updatedPermissions // Store the updated permissions in the form data
        }));


    };
console.log('loca', location)
    // useEffect(()=> {
    //   if(AddUser.name !== '', location.state?.addUser === true){
    //     location.state.addUser = false
    //     const newData = [...data, AddUserData, AddUserData.date='2024-10-05', AddUserData.id='34'
    //     ];
    //     console.log(newData)
    //     setData(newData)
    //     location.state.addUser = false
    //     }
    // }, [AddUserData, location])
    useEffect(() => {
        if (AddUserData.name !== '' && location.state?.addUser === true) {
            // Check if all required fields in AddUserData are present and valid
            if (AddUserData.name && AddUserData.email && AddUserData.role) {
                // Create new data only if AddUserData is valid
                const newData = [
                    ...data,
                    {
                        ...AddUserData,
                        date: '2024-10-05', // or dynamically set
                        id: '34' // or generate dynamically
                    }
                ];
                console.log(newData);
                setData(newData);
                // Reset addUser state
                location.state.addUser = false;
            } else {
                // Handle empty data case, e.g., show an alert or log an error
                console.warn('Invalid AddUserData: Missing required fields');
            }
        }
    }, [AddUserData, location]);
    const handleSave = () => {

        if (!formData.name || !formData.password || !formData.email || !formData.role) {
            alert('Please fill all the fields');
            return;
        }
        else if ((formData.permissions) == undefined) {
            alert('Select atleast one permission')
            return;
        }

        const checkingEmial = formData.email.includes('@');
        if (checkingEmial == false) {
            alert('please enter correct email')
            return
        }

        if (modalType === 'edit') {
            setData(data.map(item => item === selectedRow ? formData : item));
            console.log("selected row", selectedRow)
            var newNotification = {
                operation: `${formData.role} - User ${formData.name} was Updated`,
                status: `Success`,
                timestamp: new Date().toLocaleString(),
                role: role

            };
        } else {
            const newData = [...data, formData];
            console.log(newData)
            setData(newData);

            // Create a new notification
            var newNotification = {
                operation: `${formData.role} - User ${formData.name} Created`,
                status: `Success`,
                timestamp: new Date().toLocaleString(),
                role: role

            };

            // Save the notification to localStorage
        }
        const notificationsIT = JSON.parse(localStorage.getItem('notificationsIT')) || [];
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        // console.log('this is notification op', newNotification.operation)
        notificationsIT.push(newNotification);
        notifications.push(newNotification);
        localStorage.setItem('notificationsIT', JSON.stringify(notificationsIT));
        localStorage.setItem('notifications', JSON.stringify(notifications));
        toggleModal();
        setAllPermissions([
            { routeofPermission: 'PPE Model', isActive: false },
            { routeofPermission: 'Emergency Exit', isActive: false },
            { routeofPermission: 'Machine Guard', isActive: false },
            { routeofPermission: 'Fork Lift', isActive: false },
            // { routeofPermission: 'TechQA', isActive: false }
        ])
    };

    const handleFilterChange = (e, field) => {
        setFilters((prev) => ({
            ...prev,
            [field]: e.target.value
        }))

        filterDataa({ ...filters, [field]: e.target.value })
        // setDataAfterFilteration(fil);
    }

    const filterDataa = (currentFilters) => {
        let filteredData = data;
        console.log('filteredDataaa', filteredData)
        if (currentFilters.role) {
            filteredData = filteredData.filter((item) => item.role === currentFilters.role);
        }
        if (currentFilters.creator) {
            filteredData = filteredData.filter((item) => item.createdBy === currentFilters.creator);
        }
        if (currentFilters.status) {
            filteredData = filteredData.filter((item) => item.status === (currentFilters.status === 'Active' ? true : false));

            // filteredData = filteredData.filter((item) => item.status === currentFilters.status);
        }

        setDataAfterFilteration(filteredData);
    };

    const filterData = (e) => {
        const normalizedSearchInput = e.target.value.trim().toLowerCase();
        console.log('this is normal', normalizedSearchInput)
        if (!e.target.value.trim()) {
            filterDataa(filters);  // Reapply the button filters when search input is cleared
            return;
        }

        const filterVar = dataAfterFilteration ? dataAfterFilteration : data

        const filtering = filterVar.filter((item) => {
            const name = item.name.toLowerCase();
            // const role = item.role.toLowerCase();
            // const createdBy = item.createdBy.toLowerCase();
            // const status = item.status.toLowerCase();
            const email = item.email.toLowerCase();
            return name.includes(normalizedSearchInput) || email.includes(normalizedSearchInput);
        });

        setDataAfterFilteration(filtering);
    };

    const handleStatusChange = (row) => {

        // const updatedData = data.map(item =>
        //     item.id === row.id ? { ...item, status: !item.status } : item
        // );
        // setData(updatedData);
        // console.log('Updated data:', updatedData);

        // // If filtering is active, reapply the filters
        // if (filters.role || filters.creator || filters.status) {
        //     filterDataa(filters);
        // }
        // Toggle status for the selected row in the original data
        const updatedData = data.map(item =>
            item.id === row.id ? { ...item, status: !item.status } : item
        );

        // Set the updated data in state
        setData(updatedData);

        // If filters are applied, reapply them to the filtered data
        if (filters.role || filters.creator || filters.status) {
            filterDataa(filters, updatedData);
        } else {
            setDataAfterFilteration(null); // Reset the filtered data if no filters are applied
        }
    };

    const tableColumns = [
        {
            name: 'Id',
            selector: row => row.id, 
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            // sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
        },
        {
            name: 'Created By',
            selector: row => row.createdBy,
        },
        {
            name: 'Password Reneval',
            selector: row => row.passwordReneval,
        },

        {
            name: 'Created At',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'Status',
            // sortable: true,

            cell: row => (
 
                    <Media body className={`text-end switch-size d-flex justify-content-center align-items-center  `} style={{width:'100%'}}>
                        <Label className="switch mt-2" style={{marginLeft:'15px'}}>
                            <Input
                                type="checkbox"
                                checked={row.status}
                                onChange={() => handleStatusChange(row)} 

                            />
                            <span className={`switch-state`} style={{ height: '25px', width: '45px' }}></span>
                        </Label>
                    </Media>
        
            ),
        },
        {
            name: 'Action',
            cell: row => (
                <div className='d-flex align-items-center justify-content-center'>
                    <button onClick={() => handleEditClick(row)} style={{ border: 'none', background: 'none' }}>
                        <img src={editIcon} alt="Edit" style={{ width: '20px', height: '20px', marginRight: '2px' }} />
                    </button>
                    <button onClick={() => handleDeleteClick(row)} style={{ border: 'none', background: 'none' }}>
                        <img src={deleteIcon} alt="Delete" style={{ width: '20px', height: '20px' }} />
                    </button>
                    <button onClick={hancleRenewPassword} style={{ border: 'none', background: 'none' }}>
                        {/* <img src={deleteIcon} alt="Delete" style={{ width: '20px', height: '20px' }} /> */}
                        <Lock color='#c5cee0' width={20} />
                    </button>
                </div>
            ),
        },
    ];

    const hancleRenewPassword = () => {
        alert('Change password from here')
    }

    const roles = data && [...new Set(data.map((item) => item.role))]
    const creaters = data && [...new Set(data.map((item) => item.createdBy))]
    const statuses = data && [...new Set(data.map((item) => item.status ? 'Active' : 'Inactive'))];
    console.log('statuses', statuses);



    return (
        <>
            <Container fluid={true} className='' style={{ paddingTop: '30px', border: '0px solid' }}>
                <H4 attrH4={{ className: 'mb-3' }}>{typee === 'super' ? 'Super Admin Dashboard' : 'IT Officer Dashboard'}</H4>
                <Row>
                    <ItTotalCard data={data} />
                </Row> 
                <H4>Member List</H4>
                <Row className='d-flex justify-content-between align-items-center mb-3'>
                    <Col xs='12' md='8' lg='5' xl='4'>
                    <SearchBar onchange={(e)=>{filterData(e)}} />
                    </Col>
                    <Col xl='2' lg='3' xs='12' sm='mb-2' md='4 mb-2' className='d-flex mb-lg-0 align-items-center  justify-content-xs-end justify-content-sm-end justify-content-md-end justify-content-xl-start  addUser'>
                         <AddButton btnText='Add User +' onClick={handleAddUserClick} /> 
                    </Col>
                    <Col xl='6' lg='12' md='12' xs='12' className='d-flex justify-content-end'>
                        <Row xs='12' className='d-flex justify-content-end'>
                            <FilterButton filters={roles} firstOption='Select Role' OnChange={handleFilterChange} inputChangeText={'role'} />
                            <FilterButton filters={creaters} firstOption='Select Creator' OnChange={handleFilterChange} inputChangeText={'creator'} />
                            <FilterButton filters={statuses} firstOption='Select Status' OnChange={handleFilterChange} inputChangeText={'status'} />
                        </Row>
                    </Col>

                </Row>
                <div style={{ background: '#EFF4FA' }}>
                    <ItDataTable
                        tableColumns={tableColumns}
                        staticData={dataAfterFilteration ? dataAfterFilteration : data}
                    // onRowClick={handleEditClick}
                    />
                </div>
                {/* <AddForm
                    modal={modal}
                    toggleModal={toggleModal}
                    formData={formData} 
                    handleInputChange={handleInputChange}
                    handleSave={handleSave}
                    modalType={modalType}
                    handleCheckboxChange={handleCheckboxChange}
                /> */}
                <AddUserForm
                    modal={modal}
                    toggleModal={toggleModal}
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                    handleSave={handleSave}
                    modalType={modalType}
                    handleCheckboxChange={handleCheckboxChange}
                    allpermissions={allpermissions}
                    typee={typee}
                // setAllPermissions={setAllPermissions}
                />
            </Container>
        </>
    );
};

function FilterButton({ filters, firstOption, OnChange, inputChangeText }) {
    return <Col xl='4' xs='4' md='4' lg='4'><Input
        className='form-control rounded-3'
        type='select'
        name='role'
        id='role'
        style={{ width: '145px', fontSize: '15px', height: '47px' }}
        // value={formData.role}
        onChange={(e) => OnChange(e, inputChangeText)}
    >
        <option value=''>{firstOption}</option>
        {filters.map((item, i) => (
            <option key={i} value={item}>{item} {console.log('itemmm', item)}</option>
        ))}
    </Input>
    </Col>



}
export default ItDashboard;


