import React, { Fragment, useEffect, useState } from "react";
import { Table, Card, Badge, OverlayTrigger, Tooltip, Container, Row, Col, CardBody, CardHeader } from 'react-bootstrap'
import Chart from "react-apexcharts";
import {
  progress1,
  progress2,
  progress3,
  progress4,
  progress5,
  RecentOrderChart,
  TurnoverChart,
} from "./analytics_data_temp";
import ReactApexChart from "react-apexcharts";
import { ArrowDown, ArrowUp } from "react-feather";
import '../AIModelReports/custom.css'
import { Input } from "reactstrap";
import { getWeek } from "../../../../_helper/helper";
import CommonFIlterButton from "../../../Common/commonFilterButton/CommonFIlterButton";
import { Shifts, monthlyData, weeklyData, shiftData } from "../../../../Data/staticData/data";


const LiveAnalyticsScreen = () => {
  const tableDummyData = ["Helmet", "Vest", "E. Exit", "Guard", "MMHE"];
  const [TurnoverChartState, setTurnoverChartState] = useState(TurnoverChart)
  const [CurrentWeek, setCurrentWeek] = useState(undefined)
  const [filters, setFilters] = useState({
    weekly: undefined,
    month: new Date().toISOString().slice(0, 7),
    shift: ''
  })

  useEffect(() => {
    // setLoader(true);
    const curr_date = new Date().toLocaleDateString("en-CA");
    // let unsubscribe = fetchAlerts(curr_date);
    const now = new Date();
    const year = now.getFullYear();
    const week = getWeek(now);
    const maxWeek = `${year}-W${String(week).padStart(2, "0")}`;
    setCurrentWeek(maxWeek);


    // return () => unsubscribe;
  }, []);

  const handleWeekChange = (e) => {
    const { value } = e.target;
    setFilters({
      ...filters,
      week: value,
    });
    applyFilters('week')
  }

  const handleMonthChange = (e) => {
    const { name, value } = e.target;
    console.log(name, 'name', value, 'value')
    setFilters({
      ...filters,
      [name]: value,
    });


    applyFilters('month');

  };
  const handleInputChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });

   
    applyFilters('shift');
  };

  const applyFilters =(name)=> {
    if (name === "shift") {
      setTurnoverChartState({
        ...TurnoverChartState,
        series: shiftData.series,
        options: {
          ...TurnoverChartState.options,
          xaxis: { categories: shiftData.categories },
        },
      });
    }

    if (name === "week") {
      setTurnoverChartState({
        ...TurnoverChartState,
        series: weeklyData.series,
        options: {
          ...TurnoverChartState.options,
          xaxis: { categories: weeklyData.categories },
        },
      });
    }

    if (name === "month") {
      setTurnoverChartState({
        ...TurnoverChartState,
        series: monthlyData.series,
        options: {
          ...TurnoverChartState.options,
          xaxis: { categories: monthlyData.categories },
        },
      });
    }
  }




  const MarketGraphOption = {
    series: [
      {
        name: "TEAM A",
        type: "column",
        data: [0, 30, 30, 30, 30, 30, 30, 30, 30, 0],
      },
      {
        name: "TEAM C",
        type: "line",
        data: [2, 3, 2, 6, 8, 12, 9, 7, 9, 7],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: [1],
          top: 0,
          left: 0,
          blur: 15,
          color: "var(--theme-default)",
          opacity: 0.3,
        },
      },
      stroke: {
        width: [0, 3],
        curve: "smooth",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      colors: ["rgba(170, 175, 203, 0.2)", "var(--theme-default)"],
      grid: {
        borderColor: "var(--chart-border)",
      },
      plotOptions: {
        bar: {
          columnWidth: "20%",
        },
      },

      fill: {
        type: ["solid", "gradient"],
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["var(--theme-default)", "#d867ac"],
          opacityFrom: 0.8,
          opacityTo: 0.8,
          colorStops: [
            {
              offset: 0,
              color: "#d867ac",
              opacity: 1,
            },
            {
              offset: 30,
              color: "#d867ac",
              opacity: 1,
            },
            {
              offset: 50,
              color: "var(--theme-default)",
              opacity: 1,
            },
            {
              offset: 80,
              color: "var(--theme-default)",
              opacity: 1,
            },
            {
              offset: 100,
              color: "var(--theme-default)",
              opacity: 1,
            },
          ],
        },
      },
      labels: [
        "Sep 10",
        "Sep 15",
        "Sep 20",
        "Sep 25",
        "Sep 30",
        "Oct 05",
        "Oct 10",
        "Oct 15",
        "Oct 20",
        "Oct 25",
      ],
      markers: {
        size: 0,
      },
      yaxis: {
        min: 0,
        max: 20,
        tickAmount: 5,
        labels: {
          formatter: function (val) {
            return val + "k";
          },
          style: {
            fontSize: "12px",
            fontFamily: "Rubik, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: "10px",
            fontFamily: "Rubik, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: true,
      },
      legend: {
        show: false,
      },
    },
  };

  const squareStyle = { width: "20px", height: "20px", borderRadius: "2px" };

  const subAreas = ['SA-1', 'SA-2', 'SA-3', 'SA-4', 'SA-5', 'SA-6', 'SA-7', 'SA-8', 'SA-9', 'SA-10', 'SA-11', 'SA-12', 'SA-13', 'SA-14', 'SA-15'];
  const areaOwner = ['Adil', 'Aftab', 'Arsalan', 'Ayesha Khaliq', 'Dr. Amjad', 'Meraj', 'Moazzam Ali', 'Muhammd Shahbaz', 'Muhammd Wasi', 'Nazir', 'Sadia', 'Shafiq', 'Shahbaz', 'Sheraz', 'Umair Pervaiz'];
  const areas = ['Helmet', 'Vest', 'E. Exit', 'Gaurd', 'MMHE'];

  const matrixData = {
    'Helmet': [90, '', 85, 83, 95, 91, 30, 86, 83, 60, 66, 70, 57, 67, 58],
    'Vest': [90, 66, 83, 90, 83, 90, 60, '', 30, 83, 90, 60, 30, 83, 90],
    'E. Exit': [83, 90, '', 90, 83, '', 83, 90, 83, 90, 83, '', 83, 90, 60],
    'Gaurd': [90, 30, 83, '', 60, 88, 60, 30, 60, '', 60, 65, 30, 83, 90],
    'MMHE': [60, 77, 78, 83, 90, 72, 30, 83, 90, 66, 83, 90, 83, 90, 60]
  };

  const getStatus = (value) => {
    if (value === '') return 'notconnected';
    const numValue = Number(value);
    if (numValue >= 80) return 'compliant';
    return 'noncompliant';
  };

  const series = areas.map((area) => ({
    name: area,
    data: subAreas.map((_, colIndex) => {
      const value = matrixData[area][colIndex];
      return {
        x: `${subAreas[colIndex]} (${areaOwner[colIndex]})`,
        y: value === '' ? 0 : Number(value),
        status: getStatus(value),
      };
    }),
  }));

  const options = {
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['black'], // Set text color to black
      },
      formatter: (val) => (val === 0 ? '' : `${val}%`),
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            { from: 80, to: 100, color: '#05d9c2', name: 'Compliant' },
            { from: 50, to: 79, color: '#7aead9', name: 'Non-Compliant' },
            { from: 0, to: 49, color: '#d0f7f1', name: 'Not Connected' },
          ],
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => `${val}%`,
      },
    },
    legend: {
      position: 'bottom',  // Move the legend to the bottom
    },
    responsive: [{
      breakpoint: 768, // Screen width less than or equal to 480px (typically mobile)
      options: {
        dataLabels: {
          style: {
            fontSize: '10px', // Font size for mobile screens
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '10px', // Font size for y-axis labels on mobile screens
            },
          },
        },
      }
    }],
    responsive: [{
      breakpoint: 480, // Screen width less than or equal to 480px (typically mobile)
      options: {
        dataLabels: {
          style: {
            fontSize: '7px', // Font size for mobile screens
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '10px', // Font size for y-axis labels on mobile screens
            },
          },
        },
      }
    }],
  };

  console.log('new Date().toISOString().slice(0, 7)', new Date().toISOString().slice(0, 7))

  const filterButtonstyle = {
    width: "155px",
    height: "38px",
    fontSize: 13,
    margin: "0",
  };
  const cardClass = `d-flex flex-column gap-2`
  return (
    <Fragment>
      <br />
      <Container fluid={true}>
        <Row>
          <Col md={6} xxl={4}>
            <Card style={{ height: "399px" }}>
              <CardHeader>
                <Row>
                  <Col xs="9">
                    <h5>Overall Compliance Score</h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="">
                  <ReactApexChart
                    type="radialBar"
                    height={290}
                    options={RecentOrderChart.options}
                    series={RecentOrderChart.series}
                  />
                </div>
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-content-center"
                >
                  <span
                    style={{ width: "80%" }}
                    className="text-center f-light"
                  >
                    70% since last 7 days overall Compliance score
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xxl={4}>
            <Card style={{ height: "399px" }}>
              <CardBody className="">
                <div className="chart-container progress-chart pt-2">
                  <div id="" className="" style={{ position: 'relative' }}>
                    <Chart
                      options={progress1.options}
                      series={progress1.series}
                      height="65"
                      type="bar"
                    />
                    <ArrowUp style={{ position: 'absolute', top: '3px', right: '40' }} color="green" size={16} />
                  </div>
                  <div id="progress2" style={{ position: 'relative' }}>
                    <Chart
                      options={progress2.options}
                      series={progress2.series}
                      height="65"
                      type="bar"
                    />
                    <ArrowDown style={{ position: 'absolute', top: '3px', right: '40' }} color="red" size={16} />
                  </div>
                  <div id="progress3" style={{ position: 'relative' }}>
                    <Chart
                      options={progress3.options}
                      series={progress3.series}
                      height="65"
                      type="bar"
                    />
                    <ArrowUp style={{ position: 'absolute', top: '3px', right: '40' }} color="green" size={16} />
                  </div>
                  <div id="progress4" style={{ position: 'relative' }}>
                    <Chart
                      options={progress4.options}
                      series={progress4.series}
                      height="65"
                      type="bar"
                    />
                    <ArrowDown style={{ position: 'absolute', top: '3px', right: '40' }} color="red" size={16} />
                  </div>
                  <div id="progress5" style={{ position: 'relative' }}>
                    <Chart
                      options={progress5.options}
                      series={progress5.series}
                      height="65"
                      type="bar"
                    />
                    <ArrowUp style={{ position: 'absolute', top: '3px', right: '40' }} color="green" size={16} />
                  </div>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-content-center"
                >
                  <span
                    style={{ width: "90%" }}
                    className="text-center f-light"
                  >
                    Based on last 7 days data
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            md={6} xxl={4}
            style={{ justifyContent: "center", alignItems: "center", margin: '0', padding: '0' }}
          >
            <Col
              className="d-flex gap-2"
              xs='12'
              style={{ justifyContent: "center", alignItems: "center", width: '100%', }}
            >
              <Card className="anlyticsTopRightCards" style={{ marginBottom: 10, padding: "30px", width: '50%' }}>
                {/* <CardHeader>
                        <h6>AI Accuracy</h6>              
              </CardHeader> */}
                <CardBody className={`${cardClass}`}>
                  <h6>AI Accuracy</h6>
                  <h4 className="mb-0"> <ArrowDown color="red" size={20} />85%</h4>
                  <span className="f-light">Based on last 7 days data</span>
                </CardBody>
              </Card>
              <Card className="anlyticsTopRightCards" style={{ marginBottom: 10, padding: "30px", width: '50%' }}>
                <CardBody className={`${cardClass}`}>
                  <h6>Connectivity</h6>
                  <h4 className="mb-0"><ArrowUp color="green" size={20} />100%</h4>
                  <span className="f-light">All systems operational</span>
                </CardBody>
              </Card>
            </Col>
            <Card className="anlyticsTopRightCards" style={{ marginBottom: 10, padding: "30px" }}>
              <CardBody className={`${cardClass}`}>
                <h6>High severty Alerts</h6>
                <h4 className="mb-0"><ArrowUp color="green" size={20} />20</h4>
                <span className="f-light">
                  Max Alerts in SA-1(Aftab) 
                </span>
                <span className="f-light">
                 Helmet module (Camera #1)
                </span>
              </CardBody>
              {/* <CardBody>
                <div className={`${cardClass}`} >
                  <div className="mb-1">
                    <ArrowUp color="green" size={20} />{" "}
                    <h4 style={{ display: "inline" }} className="mb-0">
                      10
                    </h4>{" "}
                    <span className="f-light"> High severity Alerts</span>
                  </div>
                  <div className="mb-1">
                    <ArrowDown color="red" size={20} />{" "}
                    <h4 style={{ display: "inline" }} className="mb-0">
                      15
                    </h4>{" "}
                    <span className="f-light"> Medium severity Alerts</span>
                  </div>
                  <div className="mb-1">
                    <ArrowDown color="red" size={20} />{" "}
                    <h4 style={{ display: "inline" }} className="mb-0">
                      35
                    </h4>{" "}
                    <span className="f-light"> Low severity Alerts</span>
                  </div>
                </div>
              </CardBody> */}
            </Card>
          </Col>
          {/* <div
              style={{
                display: "grid",
                // flexDirection: "column",
                justifyContent: "space-between",
                gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                height: "100%",
                paddingBottom: "2rem",
                gap: "1rem",
              }}
            >
              <div xs="12" className="">
                <Card style={{ height: "100%" , marginBottom:10}}>
                  <CardHeader>
                    <Row>
                      <Col xs="9">
                        <h5>AI Accuracy</h5>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div
                      style={{
                        paddingTop: "1rem",
                      }}
                    >
                      <h4 className="mb-0">95%</h4>
                      <span className="f-light">Based on last 7 days data</span>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div xs="12">
                <Card style={{ height: "100%" , marginBottom:10}}>
                  <CardHeader>
                    <Row>
                      <Col xs="9">
                        <h5>Connectivity</h5>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div
                      style={{
                        paddingTop: "1rem",
                      }}
                    >
                      <h4 className="mb-0">100%</h4>
                      <span className="f-light">All systems operational</span>
                    </div>
                   
                  </CardBody>
                </Card>
              </div>
            </div> */}

        </Row>
        <Row className=" ">
          <Col xs={12} lg={12} md={12} sm={12} xl={12}>
            <Card className="shadow-sm">
              <Card.Header className="bg-primary text-white">
                <h4 className="mb-0">Sub Area Matrix</h4>
              </Card.Header>
              <Card.Body>

                <div className="table-responsive">
                  <div className="mb-4">
                    <Chart options={options} series={series} type="heatmap" height={350} />
                  </div>





                  {/* <Table hover size="sm" className="mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th className="text-center">ID</th>
                        {subAreas.map(sa => (
                          <th key={sa} className="text-center">{sa}</th>
                        ))}
                      </tr>
                    </thead>
                    <thead className="bg-light">
                      <tr>
                        <th className="text-center">Owner</th>
                        {areaOwner.map(sa => (
                          <th key={sa} className="text-center">{sa}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {areas.map(area => (
                        <tr key={area}>
                          <td className="font-weight-bold text-center align-middle">{area}</td>
                          {matrixData[area].map((value, index) => {
                            const status = getStatus(value);
                            return (
                              <td key={`${area}-${subAreas[index]}`} className="p-2 text-center">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      <strong>{area} - {subAreas[index]}</strong><br />
                                      Status: {status.charAt(0).toUpperCase() + status.slice(1)}
                                    </Tooltip>
                                  }
                                >
                                  {
                                    status === 'notconnected' ?

                                      <div style={{ backgroundColor: '#837e7e', paddingBlock: '13px' }} className="text-white rounded-2"></div>

                                      :


                                      <Badge
                                        bg={statusColors[status]}

                                        className={`d-inline-block w-100 py-2 `}
                                        style={{ opacity: status === 'notconnected' ? '100%' : 1 }}
                                      >
                                        {value == '' ? '' : `${value}%`}
                                      </Badge>
                                  }
                                </OverlayTrigger>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                      <tr>
                        <td className="font-weight-bold text-center align-middle">
                          Overall
                        </td>
                        {overallAverages.map((avg, index) => {
                          const status = getStatus(avg);
                          return (
                            <td key={`overall-${subAreas[index]}`} className="p-2 text-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    <strong>Overall - {subAreas[index]}</strong><br />
                                    Status: {status.charAt(0).toUpperCase() + status.slice(1)}
                                  </Tooltip>
                                }
                              >

                                <Badge
                                  bg={statusColors[status]}
                                  className="d-inline-block w-100 py-2"
                                  style={{ opacity: status === 'notconnected' ? 0.5 : 1 }}
                                >
                                  {avg}%
                                </Badge>
                              </OverlayTrigger>
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </Table> */}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="6" className="">
                    <h5>Alerts Trend</h5>
                  </Col>
                  <Col xs="6"
                    className="d-flex justify-content-end filter-container gap-2 ">
                  
                      <CommonFIlterButton
                        data={Shifts}
                        handleInputChange={handleInputChange}
                        style={filterButtonstyle}
                        selectedItem={filters?.shift}
                        firstOption={"Select Shift"}
                        inputChangeOption={"shift"}
                      />
                    <Input
                      className="form-control rounded-3 m-0"
                      type="week"
                      name="week"
                      id="week" s
                      max={CurrentWeek}
                      value={filters?.weekly}
                      style={filterButtonstyle}
                      onChange={handleWeekChange}
                    />
                    <Input
                      className="form-control rounded-3 m-0"
                      type="month"
                      name="month"
                      id="role"
                      max={new Date().toISOString().slice(0, 7)}
                      value={filters?.month}
                      style={filterButtonstyle}
                      onChange={handleMonthChange}
                    />
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-container">
                  <Row>
                    <Col>
                      <div id="chart-widget7">


                        <Chart
                          options={TurnoverChartState.options}
                          series={TurnoverChartState.series}
                          // type="area"
                          height={320}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
};

export default LiveAnalyticsScreen;
