import React from 'react';
// import LiveCamera from '../../Dashboard/liveCamera/liveCamera';
import LiveCamera from '../../GlobalDashboard/liveCamera/liveCamera'

const Index = () => {
  return (
    <>
    {/* <LiveCamera /> */}
    <LiveCamera />
    </>

  )
}

export default Index
