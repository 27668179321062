import React, { Fragment, useState, useEffect } from "react";
import AlertList from "../../../Tables/DatatableForArea/DataTable/Index";

import { CardBody, Col, Container, Modal, Row } from "react-bootstrap";
import { H4 } from "../../../../AbstractElements";
import {
  areas,
  DateFilters,
  dummyDataForLiveAlerts,
  modulesforAlerts,
  modulesForRefrigeration,
  Shifts,
  statuses,
  ViolationSeverity,
} from "../../../../Data/staticData/data";
import AreaService from "../../../../api/areaService";
import Loader1 from "../../../../CommonElements/Spinner/loader";
import moment from "moment";
import SingleImage from "../../../../Gallery/zoomin/SingleImage";
import CommonFIlterButton from "../../../Common/commonFilterButton/CommonFIlterButton";
import { Button, FormGroup, Input, Card } from "reactstrap";
import { getWeek } from "../../../../_helper/helper";
import CameraImage from "../../../../assets/images/cameras/camera.jpeg";
import { Typeahead } from "react-bootstrap-typeahead";
import { dateChoose } from "../../../Screens/GlobalUser/AIModelReports/Components/data/staticData";

const Index = () => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const todayy = new Date().toISOString().split("T")[0];
  const dummy = [
    // Original 45 records
    {
      sr: 1,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-2",
      date: "2024-10-01",
      time: "02:35 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 2,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-2",
      date: "2024-10-02",
      time: "12:03 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 3,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-3",
      date: "2024-10-03",
      time: "11:03 AM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 4,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-1",
      date: "2024-10-04",
      time: "09:31 AM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 5,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-3",
      date: "2024-10-05",
      time: "01:18 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 6,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-1",
      date: "2024-10-06",
      time: "02:48 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 7,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-3",
      date: "2024-10-07",
      time: "05:38 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 8,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-1",
      date: "2024-10-08",
      time: "01:58 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 9,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-3",
      date: "2024-10-09",
      time: "07:24 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 10,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-1",
      date: "2024-10-10",
      time: "03:19 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 11,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-2",
      date: "2024-10-11",
      time: "01:00 PM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 12,
      VioSeverity: "Low",
      module: "Vest",
      violation: "Vest Missing",
      violationArea: "SA-4",
      date: "2024-10-12",
      time: "02:30 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 13,
      VioSeverity: "High",
      module: "Machine Guard",
      violation: "Guard Missing",
      violationArea: "SA-3",
      date: "2024-10-13",
      time: "03:10 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 14,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-5",
      date: "2024-10-14",
      time: "06:30 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 15,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-4",
      date: "2024-10-15",
      time: "07:45 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 16,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-2",
      date: "2024-10-16",
      time: "08:15 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 17,
      VioSeverity: "Low",
      module: "Vest",
      violation: "Vest Missing",
      violationArea: "SA-3",
      date: "2024-10-17",
      time: "10:30 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 18,
      VioSeverity: "High",
      module: "Machine Guard",
      violation: "Guard Missing",
      violationArea: "SA-1",
      date: "2024-10-18",
      time: "01:00 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 19,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-5",
      date: "2024-10-19",
      time: "04:45 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 20,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-2",
      date: "2024-10-20",
      time: "07:00 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 21,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-3",
      date: "2024-10-21",
      time: "09:30 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 22,
      VioSeverity: "Low",
      module: "Vest",
      violation: "Vest Missing",
      violationArea: "SA-2",
      date: "2024-10-22",
      time: "11:15 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 23,
      VioSeverity: "High",
      module: "Machine Guard",
      violation: "Guard Missing",
      violationArea: "SA-4",
      date: "2024-10-23",
      time: "02:45 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 24,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-5",
      date: "2024-10-24",
      time: "05:00 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 25,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-1",
      date: "2024-10-25",
      time: "07:00 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 26,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-3",
      date: "2024-10-26",
      time: "09:15 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 27,
      VioSeverity: "Low",
      module: "Vest",
      violation: "Vest Missing",
      violationArea: "SA-4",
      date: "2024-10-27",
      time: "11:45 AM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 28,
      VioSeverity: "High",
      module: "Machine Guard",
      violation: "Guard Missing",
      violationArea: "SA-2",
      date: "2024-10-28",
      time: "03:15 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 29,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-5",
      date: "2024-10-29",
      time: "05:45 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 30,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-4",
      date: "2024-10-30",
      time: "08:00 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 31,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-3",
      date: "2024-09-01",
      time: "09:30 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 32,
      VioSeverity: "Low",
      module: "Vest",
      violation: "Vest Missing",
      violationArea: "SA-5",
      date: "2024-09-02",
      time: "11:45 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 33,
      VioSeverity: "High",
      module: "Machine Guard",
      violation: "Guard Missing",
      violationArea: "SA-2",
      date: "2024-09-03",
      time: "02:00 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 34,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-1",
      date: "2024-09-04",
      time: "04:30 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 35,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-3",
      date: "2024-09-05",
      time: "06:15 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 36,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-5",
      date: "2024-09-06",
      time: "07:45 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 37,
      VioSeverity: "Low",
      module: "Vest",
      violation: "Vest Missing",
      violationArea: "SA-1",
      date: "2024-09-07",
      time: "10:00 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 38,
      VioSeverity: "High",
      module: "Machine Guard",
      violation: "Guard Missing",
      violationArea: "SA-2",
      date: "2024-09-08",
      time: "01:30 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 39,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-4",
      date: "2024-09-09",
      time: "03:00 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 40,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-3",
      date: "2024-09-10",
      time: "04:45 PM",
      factory: "ICF",
      shift: "Shift C",
    },
    {
      sr: 41,
      VioSeverity: "High",
      module: "Helmet",
      violation: "No Helmet",
      violationArea: "SA-1",
      date: "2024-09-11",
      time: "07:00 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 42,
      VioSeverity: "Low",
      module: "Vest",
      violation: "Vest Missing",
      violationArea: "SA-4",
      date: "2024-09-12",
      time: "09:30 AM",
      factory: "ICF",
      shift: "Shift A",
    },
    {
      sr: 43,
      VioSeverity: "High",
      module: "Machine Guard",
      violation: "Guard Missing",
      violationArea: "SA-2",
      date: "2024-09-13",
      time: "12:00 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 44,
      VioSeverity: "High",
      module: "Emergency Exit",
      violation: "Exit Blocked",
      violationArea: "SA-5",
      date: "2024-09-14",
      time: "02:45 PM",
      factory: "ICF",
      shift: "Shift B",
    },
    {
      sr: 45,
      VioSeverity: "Medium",
      module: "MMHE",
      violation: "MMHE Violation",
      violationArea: "SA-1",
      date: "2024-09-15",
      time: "04:00 PM",
      factory: "ICF",
      shift: "Shift C",
    },
  ];
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      name: "Sr.",
      selector: (row) => row.sr,
      // sortable: true,
    },

    {
      name: "Violation severity",
      selector: (row) => row.VioSeverity,
      // sortable: true,
    },

    {
      name: "Module",
      selector: (row) => row.module,
      // sortable: true,
    },
    {
      name: "Violation",
      selector: (row) => row.violation,
      // sortable: true,
    },
    {
      name: "Violation Area",
      selector: (row) => row.violationArea,
      // sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      sortFunction: (a, b) => new Date(b.date) - new Date(a.date),
    },
    {
      name: "Time",
      selector: (row) => row.time,
      sortable: true,
      sortFunction: (a, b) => {
        // First, compare by date in descending order
        const dateComparison = new Date(b.date) - new Date(a.date);
        if (dateComparison !== 0) return dateComparison;

        // If dates are the same, compare by time in descending order (AM/PM format)
        const convertTo24HourFormat = (timeStr) => {
          const [time, modifier] = timeStr.split(" "); // e.g., "02:30 PM" becomes ["02:30", "PM"]
          let [hours, minutes] = time.split(":").map(Number);

          // Convert hours to 24-hour format
          if (modifier === "PM" && hours !== 12) {
            hours += 12;
          } else if (modifier === "AM" && hours === 12) {
            hours = 0;
          }

          return { hours, minutes };
        };

        // Convert both times to 24-hour format
        const { hours: hoursA, minutes: minutesA } = convertTo24HourFormat(
          a.time
        );
        const { hours: hoursB, minutes: minutesB } = convertTo24HourFormat(
          b.time
        );

        // Compare by hours and minutes in descending order
        return hoursB - hoursA || minutesB - minutesA;
      },
      // sortFunction: (a, b) => {
      //   // First, compare by date
      //   const dateComparison = new Date(b.date) - new Date(a.date);
      //   if (dateComparison !== 0) return dateComparison;

      //   // If dates are the same, compare by time (AM/PM format)
      //   const convertTo24HourFormat = (timeStr) => {
      //     // Split time and AM/PM part
      //     const [time, modifier] = timeStr.split(' '); // e.g., "02:30 PM" becomes ["02:30", "PM"]
      //     let [hours, minutes] = time.split(':').map(Number);

      //     // Convert hours to 24-hour format
      //     if (modifier === 'PM' && hours !== 12) {
      //       hours += 12;
      //     } else if (modifier === 'AM' && hours === 12) {
      //       hours = 0;
      //     }

      //     return { hours, minutes };
      //   };

      //   // Convert both times to 24-hour format
      //   const { hours: hoursA, minutes: minutesA } = convertTo24HourFormat(a.time);
      //   const { hours: hoursB, minutes: minutesB } = convertTo24HourFormat(b.time);

      //   // Compare by hours and minutes
      //   return hoursA - hoursB|| minutesB - minutesA;
      // },
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={row?.image ? row?.image : CameraImage}
          // src={row?.image}
          alt="Violation"
          onClick={() => handleRowClick(row)}
          style={{
            width: "70px",
            height: "50px",
            borderRadius: "10px",
            marginBlock: "10px",
          }}
        />
      ),
    },
  ]);

  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [filters, setFilters] = useState({
    module: "",
    severity: "",
    status: "",
    shift: "",
    month: "",
    week: "",
    date_filter: "Daily",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    // setLoader(true);
    const curr_date = new Date().toLocaleDateString("en-CA");
    setSelectedDate(curr_date);
    // let unsubscribe = fetchAlerts(curr_date);
    const now = new Date();
    const year = now.getFullYear();
    const week = getWeek(now);
    const maxWeek = `${year}-W${String(week).padStart(2, "0")}`;
    setCurrentWeek(maxWeek);
    const filtered_list = filterSelectedDateAlerts(dummy, curr_date);
    setAllData(dummy);
    setData(dummy);
    setFilteredData(filtered_list);

    // return () => unsubscribe;
  }, []);

  const fetchAlerts = (selected_date) => {
    const payload = {
      user_id: 9,
      date: selected_date,
    };

    AreaService.getRecentAlerts(payload)
      .then((res) => {
        const resp = res?.data?.data;
        // const selected_date_alerts = sortByDateTime(
        //   filterSelectedDateAlerts(resp, selected_date)
        // )?.map((i, index) => ({
        //   ...i,
        //   sr: index + 1,
        // }));

        // const updated_alerts = sortByDateTime(resp)?.map((i, index) => ({
        //   ...i,
        //   sr: index + 1,
        // }));
        const updated_alerts = sortByDateTime(resp)?.map((i, index) => ({
          ...i,
          sr: index + 1,
        }));
        // console.log(updated_alerts)
        setAllData(updated_alerts);
        setData(updated_alerts);
        setFilteredData(updated_alerts);
        setLoader(false);

        applyFilters(filters, updated_alerts);
        // console.log("DONE");
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   const today_date = new Date().toLocaleDateString("en-CA");
  //   // console.log("BEFORE: ",filters?.date_filter,selectedDate)
  //     if (filters?.date_filter?.trim() === "Daily" && selectedDate && selectedDate === today_date) {
  //       const interval = setInterval(() => {
  //         // console.log("RUN: ",filters?.date_filter,selectedDate)
  //         fetchAlerts(selectedDate);
  //       }, 10000);
  //       return () => clearInterval(interval);
  //     }
  //   return () => {};
  // }, [selectedDate]);

  function filterSelectedDateAlerts(violations, selected_date) {
    const current = moment(selected_date).format("YYYY-MM-DD");
    return violations?.filter((violation) => {
      // Create moment object for violation date and time
      const violationDateTime = moment(
        `${violation.date} ${violation.time}`,
        "YYYY-MM-DD hh:mm A"
      );
      // Check if the formatted violation date matches the current date
      return violationDateTime.format("YYYY-MM-DD") === current;
    });
  }

  const sortByDateTime = (data_list) => {
    return data_list?.sort((a, b) => {
      const dateA = new Date(`${a.date} ${a.time}`);
      const dateB = new Date(`${b.date} ${b.time}`);

      return dateB - dateA; // Sort in descending order
    });
  };

  function handleDateChange(e) {
    setFilters({
      ...filters,
      module: "",
      severity: "",
      status: "",
      shift: "",
    });
    if (!e.target.value) {
      const today_date = new Date().toLocaleDateString("en-CA");
      setSelectedDate(today_date);
      // setLoader(true);
      // fetchAlerts(today_date);
    } else {
      setSelectedDate(e.target.value);
      // setLoader(true);
      // fetchAlerts(e.target.value);
    }
  }

  const handleInputChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
    // applyFilters({ ...filters, [field]: e.target.value }, data);
  };

  useEffect(() => {
    applyFilters(filters, data);
  }, [filters, data]);

  const applyFilters = (currentFilters, dataList) => {
    let filtered = dataList;

    if (selectedDate !== "") {
      filtered = filterSelectedDateAlerts(dummy, selectedDate);
    }

    // Apply Shift filter
    if (currentFilters?.shift) {
      filtered = filtered?.filter(
        (item) => item.shift === currentFilters.shift
      );
    }

    // Apply factory filter
    if (currentFilters.module) {
      filtered = filtered.filter((item) =>
        currentFilters.module.trim().includes(item.module.trim())
      );
    }

    // Apply filter
    if (currentFilters.severity) {
      filtered = filtered.filter(
        (item) => item.VioSeverity === currentFilters.severity
      );
    }

    // Apply module filter
    if (currentFilters.status) {
      filtered = filtered.filter(
        (item) => item.status === currentFilters.status
      );
    }

    const updated_data = sortByDateTime(filtered)?.map((i, index) => ({
      ...i,
      sr: index + 1,
    }));
    setFilteredData(updated_data);
  };

  const filterByShift = (items, selectedShift) => {
    const filteredItems = sortByDateTime(items)
      .reverse()
      ?.filter((item) => {
        const time = item?.time;
        const hour =
          parseInt(time.split(":")[0]) +
          (time.includes("PM") && time.split(":")[0] !== "12" ? 12 : 0);
        const isFirstDay =
          items?.length > 0 ? item?.date === items[0]?.date : "";

        if (selectedShift === "Shift A") {
          // Shift 1: 8 AM to 4 PM (8 - 14)
          return isFirstDay && hour >= 8 && hour <= 14;
        } else if (selectedShift === "Shift B") {
          // Shift 2: 4 PM to 12 AM (16 - 24)
          return hour >= 16 && hour < 24;
        } else if (selectedShift === "Shift C") {
          // Shift 3: 12 AM to 8 AM (0 - 8)
          return (hour >= 0 && hour < 8) || (hour === 8 && !isFirstDay);
        }
        return false; // Return false if selectedShift is not valid
      });
    return filteredItems;
  };

  const style = {
    minWidth: "132px",
    maxWidth: "140px",
    height: "38px",
    fontSize: 13,
  };

  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [imageData, setImageData] = useState({});
  const handleRowClick = (item) => {
    setImageData({
      cameraName: item?.camera_name,
      date: item?.date,
      time: item?.time,
    });
    setModalData(item);
    setShowModal(!showModal);
  };

  const fetchWeeklyAlerts = (selected_week) => {
    const payload = {
      user_id: 9,
      date: getTuesdayOfWeek(selected_week),
    };
    AreaService.getWeeklyAlerts(payload)
      .then((res) => {
        const resp = res?.data?.data;

        const updated_alerts = sortByDateTime(resp)?.map((i, index) => ({
          ...i,
          sr: index + 1,
        }));
        setAllData(updated_alerts);
        setData(updated_alerts);
        setFilteredData(updated_alerts);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const fetchMonthlyAlerts = (selected_date) => {
    const payload = {
      user_id: 9,
      date: selected_date + "-01",
    };
    AreaService.getMonthlyAlerts(payload)
      .then((res) => {
        const resp = res?.data?.data;

        const updated_alerts = sortByDateTime(resp)?.map((i, index) => ({
          ...i,
          sr: index + 1,
        }));
        setAllData(updated_alerts);
        setData(updated_alerts);
        setFilteredData(updated_alerts);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const handleMonthChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
      week: "",
      module: "",
      severity: "",
      status: "",
      shift: "",
    });
    if (value?.trim() !== "") {
      setSelectedDate("");
      setLoader(true);
      fetchMonthlyAlerts(value);
    } else {
      setFilteredData([]);
      setData([]);
      setAllData([]);
    }
  };

  const handleWeekChange = (e) => {
    const { value } = e.target;
    setFilters({
      ...filters,
      week: value,
      month: "",
      module: "",
      severity: "",
      status: "",
      shift: "",
    });
    if (value?.trim() !== "") {
      setSelectedDate("");
      setLoader(true);
      fetchWeeklyAlerts(value);
    } else {
      setFilteredData([]);
      setData([]);
      setAllData([]);
    }
  };

  const getTuesdayOfWeek = (weekString) => {
    // Parse the week string to get the year and week number
    const [year, week] = weekString.split("-W").map(Number);

    // Get the start of the week (Monday) for the given year and week
    const startOfWeek = moment().year(year).week(week).startOf("isoWeek");

    // Get the Tuesday of that week
    const tuesdayOfWeek = startOfWeek.clone().add(1, "days"); // Adding 1 day to Monday

    return tuesdayOfWeek.format("YYYY-MM-DD"); // Format it as you prefer
  };

  const handleFilters = (e, name) => {
    const val = e.target.value;
    if (name === "date_filter") {
      setFilteredData([]);
      setData([]);
      setAllData([]);
      if (val?.trim() === "") {
        // select date automatically
        const today_date = new Date().toLocaleDateString("en-CA");
        setSelectedDate(today_date);
        setLoader(true);
        fetchAlerts(today_date);
        setFilters({
          ...filters,
          date_filter: "",
          month: "",
          week: "",
          start_date: "",
          end_date: "",
          module: "",
          severity: "",
          status: "",
          shift: "",
        });
      } else {
        if (val === "Daily") {
          setFilters({
            ...filters,
            month: "",
            week: "",
            start_date: "",
            end_date: "",
            date_filter: val,
            module: "",
            severity: "",
            status: "",
            shift: "",
          });
        } else if (val === "Weekly") {
          setSelectedDate("");
          setFilters({
            ...filters,
            month: "",
            start_date: "",
            end_date: "",
            date_filter: val,
            module: "",
            severity: "",
            status: "",
            shift: "",
          });
        } else if (val === "Monthly") {
          setSelectedDate("");
          setFilters({
            ...filters,
            week: "",
            start_date: "",
            end_date: "",
            date_filter: val,
            module: "",
            severity: "",
            status: "",
            shift: "",
          });
        } else if (val === "Custom") {
          setSelectedDate("");
          setFilters({
            ...filters,
            month: "",
            week: "",
            date_filter: val,
            module: "",
            severity: "",
            status: "",
            shift: "",
          });
        }
      }
    }
  };

  const handleCustomDateFilter = (e) => {
    const { name, value } = e.target;
    if (name === "start_date") {
      if (filters?.end_date?.trim() !== "") {
        if (filters?.end_date < value) {
          setFilters({
            ...filters,
            start_date: value,
            end_date: "",
            module: "",
            severity: "",
            status: "",
            shift: "",
          });
        } else {
          setFilters({
            ...filters,
            start_date: value,
            module: "",
            severity: "",
            status: "",
            shift: "",
          });
        }
      } else {
        setFilters({
          ...filters,
          start_date: value,
          module: "",
          severity: "",
          status: "",
          shift: "",
        });
      }
    } else {
      setFilters({
        ...filters,
        [name]: value,
        module: "",
        severity: "",
        status: "",
        shift: "",
      });
    }
  };

  const fetchCustomDurationAlerts = () => {
    setLoader(true);
    const payload = {
      user_id: 9,
      start_date: filters?.start_date,
      end_date: filters?.end_date,
    };
    AreaService.getDurationAlerts(payload)
      .then((res) => {
        const resp = res?.data?.data;
        const updated_alerts = sortByDateTime(resp)?.map((i, index) => ({
          ...i,
          sr: index + 1,
        }));
        setAllData(updated_alerts);
        setData(updated_alerts);
        setFilteredData(updated_alerts);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const [hoveredCard, setHoveredCard] = useState(null);

  const [dateShow, setDateShow] = useState(false);
  const [monthShow, setMonthShow] = useState(false);
  const [weeklyShow, setWeeklyShow] = useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select Date");
  const multiple = true;
  const typeHeadChange = (field, selected) => {
    setFilters({
      ...filters,
      [field]: selected, // Update the field dynamically (factories or areas)
    });
  };

  const handleDateDrop = (e) => {
    setSelectedOption(e.target.value); // Update the dropdown value based on user selection
    if (e.target.value == "Select Date") {
      setMonthShow(false);
      setDateShow(false);
      setWeeklyShow(false);
      setCustomDate(false);
    } else if (e.target.value == "Month") {
      setMonthShow(true);
      setDateShow(false);
      setWeeklyShow(false);
      setCustomDate(false);
    } else if (e.target.value == "Date") {
      setMonthShow(false);
      setDateShow(true);
      setWeeklyShow(false);
      setCustomDate(false);
    } else if (e.target.value == "Week") {
      setMonthShow(false);
      setDateShow(false);
      setWeeklyShow(true);
      setCustomDate(false);
    } else if (e.target.value == "Custom") {
      setMonthShow(false);
      setDateShow(false);
      setWeeklyShow(false);
      setCustomDate(true);
    }
  };

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    // Get the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const pastDaysOfYear = (currentDate - firstDayOfYear) / 86400000;

    // Calculate the current week number
    const currentWeekNumber = Math.ceil(
      (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
    );

    // Return the formatted value in YYYY-Wxx format
    return `${year}-W${currentWeekNumber.toString().padStart(2, "0")}`;
  };

  const currentWeekk = getCurrentWeek();
  const monthtoday = new Date();
  const currentMonth = monthtoday.toISOString().slice(0, 7);

  const [newFilters, setNewFilters] = useState({
    module: "",
    severity: "",
    shift: "",
    date: todayy,
    week: currentWeekk,
    month: currentMonth,
    starting: "",
    ending: "",
  });

  const handleNewInputChange = (e, field) => {
    setNewFilters({
      ...newFilters,
      [field]: e.target.value,
    });
    // applyFilters({ ...filters, [field]: e.target.value }, data);
  };

  const severityOrder = {
    High: 1,
    Medium: 2,
    Low: 3,
  };

  // Sort the dummy array based on severity
  const sortedDummy = dummy.sort(
    (a, b) => severityOrder[a.VioSeverity] - severityOrder[b.VioSeverity]
  );

  // Apply filters based on selected severity
  const applyNewFilters = () => {
    let filtereddData = sortedDummy; // Start with all data

    // If severity is selected, filter by severity
    if (newFilters.severity && newFilters.severity !== "Select Severity") {
      filtereddData = sortedDummy.filter(
        (item) => item.VioSeverity === newFilters.severity
      );
    }

    if (newFilters.module && newFilters.module !== "Select Module") {
      filtereddData = filtereddData.filter(
        (item) => item.module === newFilters.module
      );
    }

    if (newFilters.shift && newFilters.shift !== "Select Shift") {
      filtereddData = filtereddData.filter(
        (item) => item.shift === newFilters.shift
      );
    }

    if (newFilters.month && newFilters.month !== "") {
      filtereddData = filtereddData.filter((item) => {
        const itemMonth = item.date.slice(0, 7); // Extract the YYYY-MM part from the date
        console.log("monthhh", itemMonth, newFilters.month);
        return itemMonth === newFilters.month;
      });
    }
    if (newFilters.date && newFilters.date !== "") {
      filtereddData = filtereddData.filter((item) => {
        const itemDay = item.date.split("-")[2]; // Extract the day from item.date
        const filterDay = newFilters.date.split("-")[2]; // Extract the day from newFilters.date
        return itemDay === filterDay; // Compare only the day part
      });
    }

    // You can apply other filters here, such as module or shift
    // For now, we are only filtering by severity.

    return filtereddData;
  };

  useEffect(() => {}, [
    newFilters.module,
    newFilters.severity,
    newFilters.shift,
    newFilters.month,
    newFilters.date,
    newFilters.week,
  ]);

  const filtereddData = applyNewFilters();

  function handleCardClick(item) {
    console.log("this is item", item);
    setImageData({
      cameraName: undefined,
      violation: item.violation,
      date: item?.date,
      time: item?.time,
    });
    setShowModal(!showModal);
  }

  return (
    <Fragment>
      <Container className="dashboard-first-page" fluid={true}>
        <Row className="d-flex align-items-center ">
          <Col
            xl={`${customDate ? "12" : "3"}`}
            lg="12"
            md="12"
            sm="12"
            xs="12"
          >
            <h4>Live Alerts </h4>
          </Col>
          <Col
            xl={`${customDate ? "12" : "9"}`}
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className={`d-xl-flex align-items-start justify-content-start justify-content-xl-${
              customDate ? "start" : "end"
            } gap-2`}
          >
            {/* <CommonFIlterButton
              data={modulesforAlerts}
              handleInputChange={handleInputChange}
              style={style}
              selectedItem={filters?.module}
              firstOption={"Select Module"}
              inputChangeOption={"module"}
            />
            <CommonFIlterButton
              data={ViolationSeverity}
              handleInputChange={handleInputChange}
              style={style}
              selectedItem={filters?.severity}
              firstOption={"Select Severity"}
              inputChangeOption={"severity"}
            />
            {filters?.date_filter === "Daily" && (
              <CommonFIlterButton
                data={Shifts}
                handleInputChange={handleInputChange}
                style={style}
                selectedItem={filters?.shift}
                firstOption={"Select Shift"}
                inputChangeOption={"shift"}
              />
            )}
            <CommonFIlterButton
              data={DateFilters}
              handleInputChange={handleFilters}
              style={style}
              selectedItem={filters?.date_filter}
              hideFirstOption={true}
              inputChangeOption={"date_filter"}
            />
            {filters?.date_filter?.trim() === "Daily" && (
              <Input
                className="form-control rounded-3"
                type="date"
                name="role"
                id="role"
                max={new Date().toLocaleDateString("en-CA")}
                value={selectedDate}
                style={style}
                onChange={handleDateChange}
              />
            )}
            {filters?.date_filter?.trim() === "Weekly" && (
              <Input
                className="form-control rounded-3"
                type="week"
                name="week"
                id="week"
                max={currentWeek}
                value={filters?.week}
                style={style}
                onChange={handleWeekChange}
              />
            )}
            {filters?.date_filter?.trim() === "Monthly" && (
              <Input
                className="form-control rounded-3"
                type="month"
                name="month"
                id="role"
                max={new Date().toISOString().slice(0, 7)}
                value={filters?.month}
                style={style}
                onChange={handleMonthChange}
              />
            )}
            {filters?.date_filter?.trim() === "Custom" && (
              <>
                <Input
                  className="form-control rounded-3"
                  type="date"
                  name="start_date"
                  id="start_date"
                  multiple={true}
                  max={new Date().toLocaleDateString("en-CA")}
                  value={filters?.start_date}
                  style={style}
                  onChange={handleCustomDateFilter}
                />
                <Input
                  className="form-control rounded-3"
                  type="date"
                  name="end_date"
                  id="end_date"
                  placeholder="End Date"
                  disabled={filters?.start_date?.trim() === ""}
                  min={filters?.start_date}
                  max={new Date().toLocaleDateString("en-CA")}
                  value={filters?.end_date}
                  style={style}
                  onChange={handleCustomDateFilter}
                />
                <Button
                  color="success"
                  style={{
                    padding: "0px 10px",
                    height: "38px",
                    margin: "10px 3px",
                  }}
                  onClick={() => fetchCustomDurationAlerts()}
                >
                  Save
                </Button>
              </>
            )} */}

            <CommonFIlterButton
              data={modulesforAlerts}
              handleInputChange={handleNewInputChange}
              style={style}
              selectedItem={newFilters?.module}
              firstOption={"Select Module"}
              inputChangeOption={"module"}
            />
            <CommonFIlterButton
              data={ViolationSeverity}
              handleInputChange={handleNewInputChange}
              style={style}
              selectedItem={newFilters?.severity}
              firstOption={"Select Severity"}
              inputChangeOption={"severity"}
            />
            <CommonFIlterButton
              data={Shifts}
              handleInputChange={handleNewInputChange}
              style={style}
              selectedItem={newFilters?.shift}
              firstOption={"Select Shift"}
              inputChangeOption={"shift"}
            />
            <FormGroup className=" my-0">
              <Input
                className="rounded-3"
                type="select"
                name="role"
                id="role"
                style={style}
                value={selectedOption}
                onChange={handleDateDrop} // Make sure it's `onChange` not `OnChange`
              >
                {/* <option value="Select Date">
          {selectedOption === 'Select Date' ? 'Select Date' : selectedOption}
        </option> */}
                {dateChoose.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Input>
            </FormGroup>

            {dateShow && (
              <>
                <Input
                  className="form-control rounded-3"
                  type="date"
                  name="role"
                  id="role"
                  max={new Date().toLocaleDateString("en-CA")}
                  value={newFilters.date}
                  style={style}
                  onChange={(e) => handleNewInputChange(e, "date")}
                />
              </>
            )}
            {monthShow && (
              <>
                <Input
                  className="form-control rounded-3"
                  type="month"
                  name="month"
                  id="role"
                  max={new Date().toISOString().slice(0, 7)}
                  value={newFilters.month}
                  style={style}
                  onChange={(e) => handleNewInputChange(e, "month")}
                />
              </>
            )}
            {weeklyShow && (
              <>
                <Input
                  className="form-control  rounded-3"
                  type="week"
                  name="week"
                  id="week"
                  max={currentWeek}
                  value={newFilters?.week}
                  style={style}
                  onChange={(e) => handleNewInputChange(e, "week")}
                />
              </>
            )}
            {customDate && (
              <>
                <div className="d-flex align-items-start gap-2">
                  <Input
                    className="form-control rounded-3 "
                    type="date"
                    name="start_date"
                    id="start_date"
                    multiple={true}
                    max={new Date().toLocaleDateString("en-CA")}
                    value={newFilters?.starting}
                    style={style}
                    onChange={(e) => handleNewInputChange(e, "starting")}
                  />
                  <Input
                    className="form-control rounded-3 "
                    type="date"
                    name="end_date"
                    id="end_date"
                    placeholder="End Date"
                    // disabled={filters?.start_date?.trim() === ""}
                    // min={filters?.start_date}
                    max={new Date().toLocaleDateString("en-CA")}
                    value={newFilters?.ending}
                    style={style}
                    onChange={(e) => handleNewInputChange(e, "ending")}
                  />
                  <Button
                    color="success"
                    style={{
                      padding: "0px 10px",
                      height: "38px",
                      // margin: "10px 3px",
                    }}
                    // onClick={() => fetchCustomDurationAlerts()}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>

        {loader ? (
          <div style={{ marginTop: 90 }}>
            <Loader1 />
          </div>
        ) : (
          <>
            {/* <AlertList
            pagination_options={[10, 20, 30, 40]}
            dummytabledata={filteredData.length>0 ? filteredData : dummy}
            tableColumns={columns}
            noCardHeader={true}
            defaultSortFieldId={8}
          /> */}

            {/* {filteredData
  ?.sort((a, b) => {
    const severityOrder = { 'High': 1, 'Medium': 2, 'Low': 3 }; // Define the order for severity
    return severityOrder[a.VioSeverity] - severityOrder[b.VioSeverity];
  })
  .map((item) => {
    return (
      <>
      {data.map((item, index) => {
       
   

        return (
          <div key={index}>
             <Card
        className={`card-absolute }`}
        style={{
          backgroundColor: hoveredCard === index ? '#3626ec' : '',
          color: hoveredCard === index ? 'white' : '',
          transition: 'all 0.3s ease',
        }}
        onMouseEnter={() => setHoveredCard(index)} // Set the hover state on mouse enter
        onMouseLeave={() => setHoveredCard(null)} // Reset hover state on mouse leave
        onClick={() => setSelectedCard(index)} // Set the card as selected on click
      >
        <div
          className={`bg-${
            item.VioSeverity === 'Low'
              ? 'info'
              : item.VioSeverity === 'High'
              ? 'danger'
              : 'warning'
          } card-header my-2 p-2`}
        >
          <h5 className="txt-light">{item.VioSeverity} Severity</h5>
        </div>
        <CardBody className="pt-4">
          <Row className="d-flex align-items-center">
            <Col xl="3" lg="3" md="3">
              <img className="rounded-2" src={CameraImage} width="100%" />
            </Col>
            <Col xl="9" lg="9" md="9">
              <Row className="d-flex align-items-center">
                <Col xl="4" lg="4" md="4" sm="4" xs="6">
                  <ul>
                    <li style={{ fontWeight: '600' }}>
                      Violation: {item.violation}
                    </li>
                    <li className="d-flex" style={{ fontWeight: '200' }}>
                     Module: {item.module}
                    </li>
                  </ul>
                </Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="6">
                  <ul>
                    <li style={{ fontWeight: '200' }}>
                      Area: {item.violationArea}
                    </li>
                    <li style={{ fontWeight: '600' }}>
                      Factory: {item.factory}
                    </li>
                  </ul>
                </Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="6">
                  <ul>
                    <li >{item.date}</li>
                    <li >{item.time}</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
          </div>
        );
      })}
    </>
    );
  })} */}

            {filtereddData.map((item, index) => {
              return (
                <>
                  <div key={index}>
                    <Card
                      className={`card-absolute }`}
                      style={{
                        backgroundColor: hoveredCard === index ? "#8f8d8d" : "",
                        color: hoveredCard === index ? "white" : "",
                        transition: "all 0.3s ease",
                      }}
                      onMouseEnter={() => setHoveredCard(index)} // Set the hover state on mouse enter
                      onMouseLeave={() => setHoveredCard(null)} // Reset hover state on mouse leave
                      onClick={() => handleCardClick(item)} // Set the card as selected on click
                    >
                      <div
                        className={`bg-${
                          item.VioSeverity === "Low"
                            ? "info"
                            : item.VioSeverity === "High"
                            ? "danger"
                            : "warning"
                        } card-header my-2 p-2`}
                      >
                        <h5 className="txt-light">
                          {item.VioSeverity} Severity
                        </h5>
                      </div>
                      <CardBody className="pt-4">
                        <Row className="d-flex align-items-center">
                          <Col xl="3" lg="3" md="3">
                            <img
                              className="rounded-2"
                              src={CameraImage}
                              width="100%"
                            />
                          </Col>
                          <Col xl="9" lg="9" md="9">
                            <Row className="d-flex align-items-center">
                              <Col xl="4" lg="4" md="4" sm="4" xs="6">
                                <ul>
                                  <li style={{ fontWeight: "600" }}>
                                    Violation: {item.violation}
                                  </li>
                                  <li
                                    className="d-flex"
                                    style={{ fontWeight: "200" }}
                                  >
                                    Module: {item.module}
                                  </li>
                                  <li
                                    className="d-flex"
                                    style={{ fontWeight: "200" }}
                                  >
                                    {item.shift}
                                  </li>
                                </ul>
                              </Col>
                              <Col xl="4" lg="4" md="4" sm="4" xs="6">
                                <ul>
                                  <li style={{ fontWeight: "200" }}>
                                    Area: {item.violationArea}
                                  </li>
                                  <li style={{ fontWeight: "600" }}>
                                    Factory: {item.factory}
                                  </li>
                                </ul>
                              </Col>
                              <Col xl="4" lg="4" md="4" sm="4" xs="6">
                                <ul>
                                  <li>{item.date}</li>
                                  <li>{item.time}</li>
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </>
              );
            })}

            {showModal && (
              <SingleImage
                // photo={modalData?.image}
                photo={CameraImage}
                setIsOpen={setShowModal}
                imageData={imageData}
              />
            )}
          </>
        )}

        {/* {showModal && (
          <Modal
            show={showModal}
            
            onHide={() => {
              setShowModal(false);
            }}
            
            centered
            style={{ width: "100%" }} 
          >
            <div className="imageshow">
              <img src={modalData?.image} alt="image" className="img-fluid" />
            </div>
          </Modal>
        )} */}
      </Container>
    </Fragment>
  );
};

export default Index;
