import React, { useState } from 'react';
import { Card, CardBody, Button, Col, Row, CardHeader } from 'react-bootstrap';
import { ImCheckboxChecked } from "react-icons/im";
import { TbClockCancel } from "react-icons/tb";
import '../style/style.css';

const PreviousSupportTicketCard = ({ dataa, handleEditTicket, ticketno }) => {
    const [showAllModel, setShowAllModel] = useState(false);
    const [showAllArea, setShowAllArea] = useState(false);
    const [showAllSubArea, setShowAllSubArea] = useState(false);

    const toggleModel = () => setShowAllModel(!showAllModel);
    const toggleArea = () => setShowAllArea(!showAllArea);
    const toggleSubArea = () => setShowAllSubArea(!showAllSubArea);

    const renderShortened = (text, limit = 10) => {
        if (text.length <= limit) return text;
        return `${text.substring(0, limit)}...`;
    };

    return (
        <Card className='ribbon-wrapper p-0 PreviousCard'>
            <CardHeader style={{ background: dataa.status === 'Resolved' ? '#ddf5d8' : '#f5d8d8' }}>
                <div className='d-flex justify-content-between flex-wrap'>
                    <h6>Ticket no. {ticketno}</h6>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <h6 style={{ paddingRight: '10px' }}>{dataa.status}</h6>
                        {dataa.status === 'Resolved' ?
                            <ImCheckboxChecked style={{ color: '#91c837', background: 'white', fontSize: '18px', borderRadius: '3px', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.5)' }} className='m-0 p-0' />
                            :
                            <TbClockCancel style={{ color: '#8a0303', fontSize: '18px', borderRadius: '3px' }} className='m-0 p-0' />
                        }
                    </div>
                </div>
            </CardHeader>
            <CardBody className='previousCardStyle'>
                <Row>
                    <Col sm='6' xs='12' >
                        <ul>
                            <li>
                                Model | <b>{showAllModel ? dataa.models.join(', ') : renderShortened(dataa.models.join(', '))}</b>
                                {dataa.models.length > 1 && (
                                    <a onClick={toggleModel} style={{ cursor: 'pointer' }}>
                                        {showAllModel ? ' See Less' : ' See More'}
                                    </a>
                                )}
                            </li>
                            <li>
                                Generated by | <b>{dataa.owner}</b>
                            </li>
                            <li>
                                Area | <b>{showAllArea ? dataa.areas.join(', ') : renderShortened(dataa.areas.join(', '))}</b>
                                {dataa.areas.length > 1 && (
                                    <a onClick={toggleArea} style={{ cursor: 'pointer' }}>
                                        {showAllArea ? ' See Less' : ' See More'}
                                    </a>
                                )}
                            </li>
                            <li>
                                Sub Area | <b>{showAllSubArea ? dataa.subAreas.join(', ') : renderShortened(dataa.subAreas.join(', '))}</b>
                                {dataa.subAreas.length > 1 && (
                                    <a onClick={toggleSubArea} style={{ cursor: 'pointer' }}>
                                        {showAllSubArea ? ' See Less' : ' See More'}
                                    </a>
                                )}
                            </li>
                            <li>
                                Priority | <b>{dataa.priority}</b>
                            </li>
                            <li>
                                Issue Date | <b>{dataa.ticketDate}</b>
                            </li>
                            {dataa.status === 'Resolved' &&
                                <li>
                                    Response Date | <b>{dataa.responseDate}</b>
                                </li>
                            }
                            <li>
                                Status | <b>{dataa.status}</b>
                            </li>
                        </ul>
                    </Col>
                    <Col sm='6' xs='12'>
                        <div className='previousCardQuery'>
                            <label>Query</label>
                            <textarea value={dataa.query} readOnly className='shadow-sm p-2'></textarea>
                        </div>
                    </Col>
                    {dataa.status !== 'Resolved' &&
                        <Col style={{ width: '100%', height: '100%', }} className='d-flex justify-content-end align-items-end'>
                            <Button variant="primary" className='mt-2 mx-2 py-1 px-4' onClick={handleEditTicket}>Edit</Button>
                        </Col>
                    }
                </Row>
            </CardBody>
        </Card>
    );
};

export default PreviousSupportTicketCard;
