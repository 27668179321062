// import React, { useEffect, useState } from 'react';
// import ContactForm from '../Components/Forms/ContactForm'
// import { Container, Row } from 'reactstrap';
// import { H3, H4 } from '../../../../../AbstractElements';

// const ItSupport = () => {

//     const [contactName, setContactName] = useState('')
//     const [role, setRole] = useState('')


//     const [contactformData, setcontactformData] = useState();
//     const [formShow, setformShow] = useState(false)

//     useEffect(() => {
//       setContactName(localStorage.getItem('Name'))
//         const newRole = localStorage.getItem('Role')
//       setRole(newRole);
      
        
    
     
//     }, [contactName,role])
    

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setcontactformData({
//             ...contactformData,
//             [name]: value,
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault(); 
//         console.log('Form Data:', contactformData);
//         setformShow(true)

//         var newNotification = {
//             operation: `Form sent by ${contactName}` ,
//             status: `Success`,
//             timestamp: new Date().toLocaleString(),
//             role: role
            
//         };
//         const notificationsIT = JSON.parse(localStorage.getItem('notificationsIT')) || [];
//         const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
//         // console.log('this is notification op', newNotification.operation)
//         notificationsIT.push(newNotification);
//         notifications.push(newNotification);
//         localStorage.setItem('notificationsIT', JSON.stringify(notificationsIT));
//         localStorage.setItem('notifications', JSON.stringify(notifications));

//     };
//     return (
//         <div>
//             <Container fluid={true} className='' style={{ paddingTop: '30px', border: '0px solid' }}>
//                <H4>
//                 Contact Us
//                </H4>
//                <ContactForm handleChange={handleChange} handleSubmit={handleSubmit} contactformData={contactformData} formShow={formShow}/>
//             </Container>
//         </div>
//     );
// }

// export default ItSupport;

import React from 'react'
import SupportAndTicketsScreen from '../../../../Screens/GlobalUser/SupportAndTickets/support_and_tickets'

const ItSupport = () => {
  return (
    <>
      <SupportAndTicketsScreen />
    </>
  )
}

export default ItSupport

