import React, { Fragment } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'react-bootstrap'
import DataTableModel from './Components/DataTable';
import AllFilters from '../../../Common/allFilters/AllFilters';
import { useState, useRef } from 'react';
import { shifts, weeks } from '../../../../Data/staticData/data';
import { H3, H4, H5 } from '../../../../AbstractElements';
import TotalAlerts from './Components/Charts/TotalAlerts';
import ModelChart from './Components/Charts/ModelChart';
import CameraImage from "../../../../assets/images/cameras/camera.jpeg"
import { useEffect } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import AIModal from './Components/Modal/AIModal';
import { area1, areaaa, dateChoose, SA } from './Components/data/staticData';
import './custom.css'
import { toast } from "react-toastify";
import { getWeek } from '../../../../_helper/helper';
import { Typeahead } from 'react-bootstrap-typeahead';
import SingleImage from '../../../../Gallery/zoomin/SingleImage';




const AIModelAndReportsScreen = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isOpen, setIsOpen] = useState(false)
  const generateRandomIP = () => {
    // Generate a random IP address
    const randomIP = `${getRandomOctet()}.${getRandomOctet()}.${getRandomOctet()}.${getRandomOctet()}`;
    return randomIP;
  };

  const getRandomOctet = () => {
    // Generate a random number between 0 and 255
    return Math.floor(Math.random() * 256);
  };
  const generateRandomLocation = () => {
    const randomLocation = Math.random() < 0.5 ? "Indoor" : "Outdoor"; // Randomly choose between Indoor and Outdoor
    return randomLocation;
  };
  const generateRandomNVR = () => {
    const randomNumber = Math.floor(Math.random() * 100) + 1; // Generates a random number between 1 and 100
    const nvrID = `DHI-NVR${randomNumber}`; // Construct the NVR ID
    return nvrID;
  };
  const generateRandomResponse = () => {
    const randomResponse = Math.random() < 0.5 ? "Yes" : "No"; // Randomly choose between Yes and No
    return randomResponse;
  };

  const pageRef = useRef();

  const [modal, setModal] = useState(false);
  const [sub, setSubmit] = useState(false);

  const flattenData = (areas) => {
    // Create an object to group sub-areas by `AreaOwner`
    const groupedData = {};

    areas.forEach((area) => {
      const areaOwner = area.AreaOwner;

      // Check if this `AreaOwner` has already been added to `groupedData`
      if (!groupedData[areaOwner]) {
        groupedData[areaOwner] = {
          AreaName: area.AreaName,
          AreaOwner: area.AreaOwner,
          Compliance: area.Compliance,
          SubAreas: [], // This will hold all sub-areas for this `AreaOwner`
        };
      }

      // Add the sub-areas to the `AreaOwner`
      area?.SubAreas?.forEach((subArea) => {
        groupedData[areaOwner].SubAreas.push({
          SubAreaName: subArea.SubAreaName,
          CameraIP: subArea.CameraIP,
          OutdoorIndoor: generateRandomLocation(),
          NVR: generateRandomNVR(),
          CameraID: subArea.CameraID,
          Picture: CameraImage,
          Helmet: generateRandomResponse(),
          Vest: generateRandomResponse(),
          EmergencyExit: generateRandomResponse(),
          MachineGuard: generateRandomResponse(),
          Forklift: generateRandomResponse(),
        });
      });
    });

    // Convert the grouped data object into an array
    return Object.values(groupedData);
  };

  const data = flattenData(areaaa);

  const tableColumns = [
    {
      name: "Area Name",
      selector: (row) => row.AreaName,
      sortable: true,
      center: true,
    },
    {
      name: "Area Owner",
      selector: (row) => row.AreaOwner,
      sortable: true,
    },

    {
      name: "Sub Area",
      minWidth: "240px",
      maxWidth: "30rem",
      selector: (row) => row.SubAreaName,
    },
    {
      name: "Camera IP",
      selector: (row) =>
        row.CameraIP == "N/A"
          ? `192.168.10.${row.CameraID.split("-")[5]}`
          : row.CameraIP,
    },
    {
      name: "Outdoor/Indoor",
      selector: (row) =>
        row.OutdoorIndoor == null ? "N/A" : row.OutdoorIndoor,
    },
    {
      name: "NVR",
      selector: (row) => (row.NVR == null ? "N/A" : row.NVR),
    },
    {
      name: "Camera ID",
      minWidth: "200px",
      selector: (row) => row.CameraID,
    },
    {
      name: "Picture",
      selector: (row) => (
        <img
          src={CameraImage}
          alt="Violation"
          style={{
            width: "70px",
            height: "50px",
            borderRadius: "10px",
            marginBlock: "10px",
          }}
        />
      ),
    },
    // {
    //   name: 'Helmet',
    //   selector: row => row.Helmet == null ? 'N/A' : row.Helmet,
    // },
    // {
    //   name: 'Vest',
    //   selector: row => row.Vest == null ? 'N/A' : row.Vest,
    // },
    // {
    //   name: 'Emergency Exit',
    //   minWidth:"120px",
    //   selector: row => row.EmergencyExit == '' ? 'Not Listed' : row.EmergencyExit,
    // },
    // {
    //   name: 'Machine Guard',
    //   minWidth:"120px",
    //   selector: row => row.MachineGuard == '' ? 'Not Listed' : row.MachineGuard,
    // },
    // {
    //   name: 'Forklift',
    //   selector: row => row.Forklift == null ? 'N/A' : row.Forklift,
    // },
  ];

  // Use the `adil_data` array for the data prop
const [currentWeek, setCurrentWeek] = useState()
  const [selectedOption, setSelectedOption] = useState('Select Date');
  const [selectedArea, setSelectedArea] = useState('Select Area')
  const [hideFirstOption, setHide] = useState(true)
  const [dateDrop, setDrop] = useState('')
  const style = { minWidth:'100px',maxWidth:'140px' , height: "38px", fontSize: 13, }
  const monthtoday = new Date();
  const currentMonth = monthtoday.toISOString().slice(0, 7);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [monthFormatChange, setmonthFormatChange] = useState();
  const newtoday = new Date();
  const startDat = newtoday.toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(startDat);
  const today = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedMonth(value);

    const [year, month] = value.split("-");

    const monthIndex = Number(month) - 1;
    const monthName = new Date(year, monthIndex, 1).toLocaleString("default", {
      month: "long",
    });

    // console.log(monthName);
    setmonthFormatChange(`${monthName} ${year}`);
  };

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    
    // Get the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const pastDaysOfYear = (currentDate - firstDayOfYear) / 86400000;
    
    // Calculate the current week number
    const currentWeekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    
    // Return the formatted value in YYYY-Wxx format
    return `${year}-W${currentWeekNumber.toString().padStart(2, '0')}`;
  };

  const currentWeekk = getCurrentWeek();

  const [filters, setFilters] = useState({
    areas: [],
    shifts: [],
    date:today,
    week:currentWeekk,
    month:currentMonth,
})
  const handleInputChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
    console.log("all filters", filters);
  };


  const handlePdfDownload = () => {
    toast.success("Downloaded Successfully");
    const element = pageRef.current;
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('ai_model_reports.pdf');





    });
  };
  const handlePrint = () => {
    window.print(); // Opens the print dialog
  };

  function toggleModal() {
    setModal(!modal);
    console.log("modal", modal);
    setSubmit(false);
  }
  const [dateShow, setDateShow] = useState(false)
  const [monthShow, setMonthShow] = useState(false)
  const [weeklyShow, setWeeklyShow] = useState(false)
  const [customDate, setCustomDate] = useState(false)

  useEffect(() => {

    const now = new Date();
    const year = now.getFullYear();
    const week = getWeek(now);
    const maxWeek = `${year}-W${String(week).padStart(2, "0")}`;
    setCurrentWeek(maxWeek);

  }, [])

  const handleDateDrop = (e) => {
    setSelectedOption(e.target.value); // Update the dropdown value based on user selection
    if (e.target.value == 'Select Date') {
      setMonthShow(false)
      setDateShow(false)
      setWeeklyShow(false)
      setCustomDate(false)
    }
    else if (e.target.value == 'Month') {
      setMonthShow(true)
      setDateShow(false)
      setWeeklyShow(false)
      setCustomDate(false)

    }
    else if (e.target.value == 'Date') {
      setMonthShow(false)
      setDateShow(true)
      setWeeklyShow(false)
      setCustomDate(false)
    }
    else if (e.target.value == 'Week') {
      setMonthShow(false)
      setDateShow(false)
      setWeeklyShow(true)
      setCustomDate(false)
    }
    else if (e.target.value == 'Custom') {
      setMonthShow(false)
      setDateShow(false)
      setWeeklyShow(false)
      setCustomDate(true)
    }
  };




 

  // To handle changes in the Typeahead
  const typeHeadChange = (field, selected) => {
    setFilters({
      ...filters,
      [field]: selected  // Update the field dynamically (factories or areas)
    });
  };

  const multiple = true;

  const addRandomCompliance = (areas) => {
    return areas.map(area => ({
      ...area,
      SubAreas: area.SubAreas.map(subArea => ({
        ...subArea,
        Compliance: Math.floor(Math.random() * (100 - 50 + 1)) + 50 // Generate a random number between 50 and 100
      }))
    }));
  };
  
  // Adding random Compliance to area1 data
  const updatedArea1 = addRandomCompliance(area1);
  const [hoveredCard, setHoveredCard] = useState(null); 
  const [imageData, setImageData] = useState({});

  const [showModal, setShowModal] = useState(false)

  function handleCardClick(item){
    console.log('this is item', item)
    setImageData({
      cameraName: undefined,
      violation:item.violation,
      areaName:item.AreaName,
      areaOwner:item.AreaOwner,
      subareas:item.SubAreas,
      date: item?.date,
      time: item?.time,
    });
    setShowModal(!showModal)
  }

  return (
    <Fragment> 
      {isOpen && <SingleImage photo={CameraImage} isOpen={isOpen} setIsOpen={setIsOpen} />}
      <br />

      <div className="p-0 m-0" ref={pageRef}>
        <Container fluid={true}>
        {showModal && (
        <SingleImage
          // photo={modalData?.image}
          photo={CameraImage}
          setIsOpen={setShowModal}
          imageData={imageData}
        />
      )}
          <Row className='d-flex align-items-center'>
            <Col xl='3' lg='12' md='12' sm='12' xs='12'>
              <h4>AI Model and Reports </h4>
            </Col>
            <Col className='d-xl-flex align-items-start justify-content-start justify-content-xl-end gap-2 ' xl='9' lg='12' md='12' sm='12' xs='12'>
   
            <FormGroup>
        <Typeahead
                style={{minWidth:'112px'}}

          id="areas"
          labelKey="name"
          multiple={multiple}
          options={['SA-1', 'SA-2', 'SA-3', 'SA-4', 'SA-5', 'SA-6', 'SA-7', 'SA-8', 'SA-9', 'SA-10', 'SA-11', 'SA-12', 'SA-13', 'SA-14', 'SA-15']}
          selected={filters.areas}
          placeholder="Select Area"
          onChange={(selected) => typeHeadChange('areas', selected)}
        />
      </FormGroup>
      <FormGroup>
        <Typeahead
        style={{minWidth:'112px'}}
          id="areas"
          labelKey="name"
          multiple={multiple}
          options={['Shift A', 'Shift B', 'Shift C']}
          selected={filters.shifts}
          placeholder="Select Shift"
          onChange={(selected) => typeHeadChange('shifts', selected)}
        />
      </FormGroup>
      <FormGroup className=' my-0'>
                <Input
                  className='rounded-3'
                  type="select"
                  name="role"
                  id="role"
                  style={style}
                  value={selectedOption}
                  onChange={handleDateDrop}  // Make sure it's `onChange` not `OnChange`
                >
                  {/* <option value="Select Date">
          {selectedOption === 'Select Date' ? 'Select Date' : selectedOption}
        </option> */}
                  {dateChoose.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Input>
              </FormGroup>
     
              {
                dateShow && (
                  <>
                    <Input
                      className="form-control rounded-3"
                      type="date"
                      name="role"
                      id="role"
                      max={new Date().toLocaleDateString("en-CA")}
                      value={filters.date}
                      style={style}
                      onChange={(e)=>handleInputChange(e,'date')}
                    />
                  </>
                )
              }
              {
                monthShow && (
                  <>
                   <Input
                className="form-control rounded-3"
                type="month"
                name="month"
                id="role"
                max={new Date().toISOString().slice(0, 7)}
                value={filters.month}
                style={style}
                onChange={(e)=>handleInputChange(e,'month')}
              />
                  </>
                )
              }
              {
                weeklyShow && (
                  <>
                    <Input
                      className="form-control  rounded-3"
                      type="week"
                      name="week"
                      id="week"
                      max={currentWeek}
                      value={filters?.week}
                      style={style}
                    onChange={(e)=>handleInputChange(e,'week')}
                    />
                  </>
                )
              }
              {
                customDate && (
                  <>
                    <div className='d-flex align-items-start gap-2'>
                      <Input
                        className="form-control rounded-3 "
                        
                        type="date"
                        name="start_date"
                        id="start_date"
                        multiple={true}
                        max={new Date().toLocaleDateString("en-CA")}
                        // value={filters?.start_date}
                        style={style}
                      // onChange={handleCustomDateFilter}
                      />
                      <Input
                        className="form-control rounded-3 "
                        type="date"
                        name="end_date"
                        id="end_date"
                        placeholder="End Date"
                        // disabled={filters?.start_date?.trim() === ""}
                        // min={filters?.start_date}
                        max={new Date().toLocaleDateString("en-CA")}
                        // value={filters?.end_date}
                        style={style}
                      // onChange={handleCustomDateFilter}
                      />
                      <Button
                        color="success"
                        style={{
                          padding: "0px 10px",
                          height: "38px",
                          // margin: "10px 3px",
                        }}
                      // onClick={() => fetchCustomDurationAlerts()}
                      >
                        Save
                      </Button>
                    </div>
                  </>
                )
              }

              
             



            </Col>
          </Row>
          <Card className='pb-0 mt-2'>
            <CardBody className='pb-0'>
              <CardHeader className=''>
                <Row className='p-0 m-0 d-flex align-items-center justify-content-between'>
                  <Col className='p-0 m-0'>
                    <H5>Module Analytics</H5>
                  </Col>
                  <Col className='p-0 m-0 gap-2 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-end' >

                    <Button onClick={handlePdfDownload}>PDF</Button>
                    <Button onClick={toggleModal} className='' color='primary'>Email</Button>
                    <Button onClick={handlePrint} color='success'>Print</Button>

                  </Col>
                </Row>
              </CardHeader>
              <Row>
                <Col xl="6" lg="6" md="12" sm="12" xs="12">
                  <Card>
                    <CardBody>
                      <TotalAlerts filters={filters} />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6" lg="6" md="12" sm="12" xs="12">
                  <Card>
                    <CardBody className="">
                      <ModelChart filters={filters} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col className="mb-2" xl="12" lg="12" md="12" sm="12" xs="12">
              {/* <DataTableModel dummytabledata={data} tableColumns={tableColumns} /> */}
              {
                updatedArea1.map((item, index) => {
                  // Determine if this card is selected
                  // const isSelected = selectedCard === index;

                  return (
                    <div key={index}>
             <Card
          key={index}
          className={`card-absolute`} 
          onClick={()=>handleCardClick(item)}
          style={{
            backgroundColor: hoveredCard === index ? '#8f8d8d' : 'white', // Change color on hover
            color: hoveredCard === index ? 'white' : 'black', // Change text color on hover
            transition: 'all 0.3s ease',
          }}
          onMouseEnter={() => setHoveredCard(index)} // Set the index of the hovered card
          onMouseLeave={() => setHoveredCard(null)} // Reset hover when mouse leaves
        >
          <div
            className={`bg-info card-header my-2 p-2`}
            style={{
              backgroundColor: hoveredCard === index ? '#8f8d8d' : '#007bff', // Adjust header color on hover
              color: hoveredCard === index ? 'white' : 'black', // Adjust text color on hover
            }}
          >
            <h5 className="txt-light">{item.AreaName}</h5>
          </div>
          <CardBody className="pt-4">
            <Row className="d-flex align-items-center">
              <Col xl="3" lg="12" md="12">
                <img className="rounded-2" src={CameraImage} width="100%" />
              </Col>
              <Col xl="9" lg="12" md="12">
                <Row className="d-flex align-items-center">
                  <Col xl="2" lg="2" md="2" sm="2" xs="2">
                    <ul>
                      <li style={{ fontWeight: '600', color: hoveredCard === index ? 'white' : 'black' }}>
                        {item.AreaOwner}
                      </li>
                    </ul>
                  </Col>
                  <Col className=" " xl="10" lg="10" md="10" sm="10" xs="10">
                    <Card
                      className="m-0 custom-scrollbarrr"
                      style={{
                        maxHeight: '150px',
                        overflow: 'auto',
                        boxShadow: 'none',
                        backgroundColor: hoveredCard === index ? '#8f8d8d' : 'white',
                        color: hoveredCard === index ? 'white' : 'black',
                      }}
                    >
                      <CardBody className="m-0">
                        <Row className="d-flex align-items-center justify-content-between m-0">
                          <Col style={{ fontWeight: '600', color: hoveredCard === index ? 'white' : 'black' }} className="d-flex justify-content-center pb-2" xl="4" lg="4" md="4" sm="4" xs="4">
                            SubArea
                          </Col>
                          <Col style={{ fontWeight: '600', color: hoveredCard === index ? 'white' : 'black' }} className="d-flex justify-content-center pb-2" xl="4" lg="4" md="4" sm="4" xs="4">
                            Cameras
                          </Col>
                          <Col style={{ fontWeight: '600', color: hoveredCard === index ? 'white' : 'black' }} className="d-flex justify-content-center pb-2" xl="4" lg="4" md="4" sm="4" xs="4">
                            Compliance
                          </Col>
                        </Row>
                        {item.SubAreas.map((sub, subIndex) => {
                          return (
                            <Row className="d-flex align-items-center justify-content-between m-0" key={subIndex}>
                              <Col className="" xl="4" lg="4" md="4" sm="4" xs="4" style={{ color: hoveredCard === index ? 'white' : 'black' }}>
                                {sub.SubAreaName}
                              </Col>
                              <Col xl="4" lg="4" md="4" sm="4" xs="4" className="d-flex justify-content-center" style={{ color: hoveredCard === index ? 'white' : 'black' }}>
                                {sub.Cameras}
                              </Col>
                              <Col className="d-flex justify-content-center" xl="4" lg="4" md="4" sm="4" xs="4" style={{ color: hoveredCard === index ? 'white' : 'black' }}>
                                {sub.Compliance}
                              </Col>
                            </Row>
                          );
                        })}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
                  </div>
                  );
                })
              }
            </Col>
          </Row>

          <AIModal
            modal={modal}
            toggleModal={toggleModal}
            sub={sub}
            setSubmit={setSubmit}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default AIModelAndReportsScreen;
