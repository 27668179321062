import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

export const isTokenExpired = () => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data && data?.accessToken) {
      const tokenData = jwtDecode(data?.accessToken);
      const expirationTimestamp = tokenData.exp;
      const currentTimestamp = Date.now() / 1000; // Convert milliseconds to seconds
      const isExpired = currentTimestamp > expirationTimestamp;
      if (isExpired) {
        // toast.info("Token has expired, Please Login again");
        window.location.href = `/login`;
        localStorage.removeItem("userData");
        localStorage.setItem("authenticated", JSON.stringify(false));
        localStorage.setItem("login", JSON.stringify(false));
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

export const getWeek = (date) => {
  const target = new Date(date.valueOf());
  const dayNum = (date.getUTCDay() + 6) % 7; // Adjusting to get correct day number
  target.setUTCDate(target.getUTCDate() + 4 - dayNum); // Set to Thursday of the week
  const yearStart = new Date(Date.UTC(target.getUTCFullYear(), 0, 1)); // Start of the year
  return Math.ceil(((target - yearStart) / 86400000 + 1) / 7); // Calculate week number
};

export const infoToast = (message) =>
  toast.info(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    pauseOnFocusLoss: true,
  });

  export const ShowDisclaimer = (text) =>{
    Swal.fire({
      text: text,
      icon: "info",
      iconColor: "#1e67d6",
      showCancelButton: false,
      cancelButtonText: "Cancel",
      reverseButtons: false,
      cancelButtonColor: "#bd2130",
      confirmButtonText: "Close",
      showConfirmButton: true,
    })
  }