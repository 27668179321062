


import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Input } from 'reactstrap';

const AreaOwnerModal = ({ toggleModal, isOpen, updatedTicket, formatDate, setTicketsData, TicketsData }) => {
    const [selectedAreaOwner, setSelectedAreaOwner] = useState([]);
    const [selectedAreaId, setSelectedAreaId] = useState([]);
    const [selectedSubarea, setSelectedSubarea] = useState([]);
    const [model, setModel] = useState('');
    const [priority, setPriority] = useState('');
    const [query, setQuery] = useState('');
    const [allAreaIds, setAllAreaIds] = useState([]);
    const [models, setModels] = useState([]);

    const modelOptions = ['Helmet', 'Vest', 'Emergency Exit', 'Forklift', 'Machine Guard'];
    const priorityOptions = ['High', 'Medium', 'Low'];

    const areaOwnerData = [
        {
            name: 'Adil',
            areas: [
                {
                    id: 'SA-1',
                    subareas: [
                        'Roof Tops (Palletizing)',
                        'Palletizing 1,2 & 3',
                        'palletizing office',
                        'palletizing corridor',
                        'waste window area'
                    ]
                }
            ]
        },
        {
            name: 'Aftab',
            areas: [
                {
                    id: 'SA-2',
                    subareas: [
                        'Roof Tops (Service Building)',
                        'Ammonia Compressor room',
                        'Catch pit area',
                        'Ref workshop',
                        'Ref Control Room',
                        'Ammonia CCR',
                        'Diffusion tank'
                    ]
                }
            ]
        },
        {
            name: 'Arslan',
            areas: [
                {
                    id: 'SA-3',
                    subareas: [
                        'Void Area (Production, Mixing)',
                        'Admin Building Roof Top',
                        'AHU Room above Canteen',
                        'Main Asset scrap yard',
                        'motor / panel scrap yard',
                        'R&D front side scrap yard',
                        'Contractor Workshops',
                        'DP store area',
                        'engineering store',
                        'safety office',
                        'safety storage area',
                        'engineering store placement yard',
                        'Fabrication workshop & surrounding area',
                        'Lathe Machine Workshop',
                        'MAMz workshop'
                    ]
                }
            ]
        },
        {
            name: 'Ayesha Khaliq',
            areas: [
                {
                    id: 'SA-4',
                    subareas: [
                        'Roof Tops (Cone Baking)',
                        'Cone Baking',
                        'Mixing',
                        'LI room',
                        'aging room',
                        'chocolate plant',
                        'mixing pits',
                        'oil/glucose decanting area',
                        'sauce plant',
                        'chilled room',
                        'day store area',
                        'mixing control room',
                        'tank form'
                    ]
                }
            ]
        },
        {
            name: 'Dr.Amjab',
            areas: [
                {
                    id: 'SA-5',
                    subareas: [
                        'OHC',
                        'Medical Roof Top'
                    ]
                }
            ]
        },
        {
            name: 'Meraj',
            areas: [
                {
                    id: 'SA-6',
                    subareas: [
                        'Roof Tops (Dry Store)',
                        'Roof Tops (Pulp Store)',
                        'Scrap Yard (Packmat area/ drums)',
                        'Dry Store 1, 2',
                        'chemical store',
                        'dry store driver room',
                        'docking stations',
                        'washrooms',
                        'Pulp Store',
                        'Hot room',
                        'flavour room',
                        'Pallet washing room'
                    ]
                }
            ]
        },
        {
            name: 'Moazzam Ali',
            areas: [
                {
                    id: 'SA-7',
                    subareas: [
                        'Machine Parts Room',
                        'Ultra Clean',
                        'Production floor',
                        'production offices',
                        'TPM room',
                        'Day store',
                        'Parts room',
                        'room 10',
                        'OPC chemical room'
                    ]
                }
            ]
        },
        {
            name: 'Muhammza Shahbaz',
            areas: [
                {
                    id: 'SA-8',
                    subareas: [
                        'ETP',
                        'Boiler',
                        'Air compressor',
                        'boiler control room',
                        'HFO tank',
                        'water filter area'
                    ]
                }
            ]
        },
        {
            name: 'Muhammza Wasi',
            areas: [
                {
                    id: 'SA-9',
                    subareas: [
                        'Roof Tops (Canteen)',
                        'Roof Tops (Security)',
                        'Time Office',
                        'ETMS',
                        'Medical OHC',
                        'Security Office',
                        'Parkings',
                        'Cycle Stand',
                        'Smoking Area',
                        'Area between Multan road gate to inner factory entrance gate',
                        'Admin Building',
                        'reception',
                        'canteen',
                        'kitchen',
                        'galleries',
                        'washrooms',
                        'locker area',
                        'masjid',
                        'changing rooms',
                        'waiting area',
                        'girls room',
                        'exit routes',
                        'brains lab',
                        'recharge room',
                        "Humail's office",
                        'meeting rooms',
                        'IT room',
                        'Outside Taris'
                    ]
                }
            ]
        },
        {
            name: 'Nazir Sb',
            areas: [
                {
                    id: 'SA-10',
                    subareas: [
                        'Solar Area (Panels, Transformer rooms & entire area)',
                        'Diesel Storage area',
                        'earth pit area',
                        'electrical power house',
                        'LT room',
                        'HT room',
                        'gen set area',
                        'transformer room',
                        'ammonia soft starter room'
                    ]
                }
            ]
        },
        {
            name: 'Sadia',
            areas: [
                {
                    id: 'SA-11',
                    subareas: [
                        'R&D Innovation Centre (Complete)'
                    ]
                }
            ]
        },
        {
            name: 'Shafiq',
            areas: [
                {
                    id: 'SA-12',
                    subareas: [
                        'QA',
                        'Pathogen Lab',
                        'QA storeroom'
                    ]
                }
            ]
        },
        {
            name: 'Shahbaz',
            areas: [
                {
                    id: 'SA-13',
                    subareas: [
                        'LPG Area',
                        'Pump House',
                        'Water treatment plant & roof',
                        'Biomass Boiler (including fuel storage shed)'
                    ]
                }
            ]
        },
        {
            name: 'Sheraz',
            areas: [
                {
                    id: 'SA-14',
                    subareas: [
                        'Roof Tops (Cold Stores)',
                        'Wooden Pallets Area',
                        'FG BOF',
                        'Cold Store 1&2',
                        'Cold store offices',
                        'Dispatch area'
                    ]
                }
            ]
        },
        {
            name: 'Umair Pervaiz',
            areas: [
                {
                    id: 'SA-15',
                    subareas: [
                        'UE Projects',
                        'Projects Store'
                    ]
                }
            ]
        }
    ];

    // Initialize allAreaIds once when the component mounts
    useEffect(() => {
        const ids = areaOwnerData.flatMap(owner => owner.areas.map(area => area.id));
        setAllAreaIds(ids);
    }, []);

    // Set initial data when the modal opens or updatedTicket changes
    useEffect(() => {
        if (isOpen && updatedTicket) {
            const owner = areaOwnerData.find(o => o.name === updatedTicket.owner);
            setSelectedAreaOwner(owner ? [owner] : []);
            setSelectedAreaId(updatedTicket.areas);
            setSelectedSubarea(updatedTicket.subAreas);
            setModel(updatedTicket.models[0]);
            setPriority(updatedTicket.priority);
            setQuery(updatedTicket.query);
            setModels(updatedTicket.models)
        }
    }, [isOpen, updatedTicket]);

    // Automatically populate area IDs based on selected Area Owner
    useEffect(() => {
        if (selectedAreaOwner.length > 0) {
            const ownerAreas = selectedAreaOwner[0].areas.map(area => area.id);
            setSelectedAreaId(prevSelectedIds => [
                ...new Set([...prevSelectedIds, ...ownerAreas]) // Add new IDs without duplicates
            ]);
        }
    }, [selectedAreaOwner]);

    // Dynamically filter subareas based on selected area IDs
    const filteredSubareas = selectedAreaId.length > 0
        ? areaOwnerData
            .flatMap(owner => owner.areas)
            .filter(area => selectedAreaId.includes(area.id))
            .flatMap(area => area.subareas.map(sub => ({ sub })))
        : [];

    const handleSubmit = () => {
        const formattedData = {
            areas: selectedAreaId,
            id: updatedTicket.id,
            models: models, // Use the array of selected models
            owner: selectedAreaOwner.map(owner => owner.name).join(''),
            priority,
            query,
            status: 'Pending',
            subAreas: selectedSubarea,
            ticketDate: formatDate()
        };

        if (
            formattedData.areas.length === 0 ||
            formattedData.models.length === 0 ||
            formattedData.subAreas.length === 0 ||
            !formattedData.owner ||
            !formattedData.priority ||
            !formattedData.query
        ) {
            alert('Please fill all the fields');
            return;
        }

        const updatedTicketsData = TicketsData.map(ticket =>
            ticket.id === updatedTicket.id ? formattedData : ticket
        );

        setTicketsData(updatedTicketsData); // This updates the state with the modified data
        console.log(formattedData);
        toggleModal(); // Close the modal after updating
    };

    // Select All and Clear All for Area IDs
    const handleSelectAllAreaIds = () => {
        setSelectedAreaId(allAreaIds);
    };

    const handleClearAllAreaIds = () => {
        setSelectedAreaId([]);
    };

    // Select All and Clear All for Subareas
    const handleSelectAllSubareas = () => {
        setSelectedSubarea(filteredSubareas.map(sub => sub.sub));
    };

    const handleClearAllSubareas = () => {
        setSelectedSubarea([]);
    };

    // Select All and Clear All for Models
    const handleSelectAllModels = () => {
        setModels(modelOptions);
    };

    const handleClearAllModels = () => {
        setModels([]);
    };

    return (
        <Modal show={isOpen} onHide={toggleModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col lg='6' md='12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Area Owner</Form.Label>
                                <Typeahead
                                    id="area-owner-typeahead"
                                    labelKey="name"
                                    onChange={selected => setSelectedAreaOwner(selected)}
                                    options={areaOwnerData}
                                    placeholder="Choose an area owner..."
                                    selected={selectedAreaOwner}
                                    clearButton
                                />
                            </Form.Group>
                        </Col>
                        <Col lg='6' md='12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Priority</Form.Label>
                                <Input type='select'>
                                    {priorityOptions.map((opt, idx) => (
                                        <option key={idx} onClick={() => setPriority(opt)}>
                                            {opt}
                                        </option>
                                    ))}
                                </Input>
                            </Form.Group>
                        </Col>
                        <Col lg='6' md='12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Area ID</Form.Label>
                                <Typeahead
                                    id="area-id-typeahead"
                                    labelKey="id"
                                    multiple
                                    onChange={selected => setSelectedAreaId(selected.map(item => item.id))}
                                    options={allAreaIds.map(id => ({ id }))}
                                    placeholder="Select area IDs..."
                                    selected={selectedAreaId.map(id => ({ id }))}

                                />
                                <div className='d-flex justify-content-end'>
                                    <Button variant="primary" className='m-2' onClick={handleSelectAllAreaIds}>Select All</Button>
                                    {selectedAreaId.length > 0 ?
                                        <Button variant="danger" className='m-2' onClick={handleClearAllAreaIds}>Clear All</Button>
                                        : ''
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg='6' md='12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Subarea</Form.Label>

                                <Typeahead
                                    id="subarea-typeahead"
                                    labelKey="sub"
                                    multiple
                                    onChange={selected => setSelectedSubarea(selected.map(item => item.sub))}
                                    options={filteredSubareas}
                                    placeholder="Select subareas..."
                                    selected={selectedSubarea.map(sub => ({ sub }))}

                                />
                                <div className='d-flex justify-content-end'>
                                    <Button variant="primary" className='m-2' onClick={handleSelectAllSubareas}>Select All</Button>
                                    {selectedSubarea.length > 0 ?
                                        <Button variant="danger" className='m-2' onClick={handleClearAllSubareas}>Clear All</Button>
                                        : ''}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg='6' md='12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Model</Form.Label>

                                <Typeahead
                                    id="model-typeahead"
                                    labelKey="model"
                                    multiple // Enable multiple selections
                                    onChange={selected => setModels(selected.map(item => item.model))}
                                    options={modelOptions.map(model => ({ model }))}
                                    placeholder="Select models..."
                                    selected={models.map(model => ({ model }))} // Multi-selection state handling

                                />
                                <div className='d-flex justify-content-end'>
                                    <Button variant="primary" className='m-2' onClick={handleSelectAllModels}>Select All</Button>
                                    {models.length > 0 ?
                                        <Button variant="danger" className='m-2' onClick={handleClearAllModels}>Clear All</Button>
                                        : ''}
                                </div>
                            </Form.Group>
                        </Col>

                        <Col lg='6' md='12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Query</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter your query"
                                    value={query}
                                    onChange={e => setQuery(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                    </Row>











                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Update Ticket
                </Button>
                <Button variant="dark" onClick={toggleModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AreaOwnerModal;
