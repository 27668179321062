import React, { Fragment, useEffect, useState } from 'react';
import Language from './Language';
import Searchbar from './Searchbar';
import Notificationbar from './Notificationbar';
import MoonLight from './MoonLight';
import CartHeader from './CartHeader';
import BookmarkHeader from './BookmarkHeader';
import UserHeader from './UserHeader';
import { UL } from '../../../AbstractElements';
import { Col } from 'reactstrap';
import AreaService from '../../../api/areaService';
import DownloadButton from './DownloadButton ';
import { useLocation } from 'react-router-dom';

const RightHeader = () => { 
  const [currentRoute, setcurrentRoute] = useState()
  const location = useLocation();

  const today = new Date();
  const startDat = today.toLocaleDateString("en-CA");
  const [startDate, setStartDate] = useState(startDat);
  const [selectedDate, setSelectedDate] = useState(startDat);
  const [totalNotifications, setTotalNotifications] = useState()
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState([])

  //fetch dashboard dashboard data
  useEffect(() => {
    setLoader(true);
    let unsubscribe = fetchDashboard(selectedDate);
    return () => unsubscribe;
  }, []);

  const fetchDashboard = (selected_date) => {
    const payload = {
      user_id: 9,
      date: selected_date,
    };
    AreaService.getAreaDashboard(payload)
      .then((res) => {
        const resp = res?.data?.data;
        setTotalNotifications(resp?.total_daily_alerts)
        setData(resp?.daily_alerts)
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    const current=location.pathname.split('/')
    const currentRoute = current.find(element => element.toLowerCase() === 'support') || '';
    // console.log(currentRoute,'right header')
    setcurrentRoute(currentRoute) 
    // -----------x-----------------x-------------
    const today_date = new Date().toLocaleDateString("en-CA");
    if (selectedDate === today_date) {
      const interval = setInterval(() => {
        fetchDashboard(selectedDate);
      }, 5000);
      return () => clearInterval(interval); 
    }
    return () => {};
  }, [selectedDate,location]);
  return (
    <Fragment>
      <Col xs='4' md='3' xxl='5' xl='5' lg='4' className='nav-right pull-right right-header col-8 p-0 ms-auto'>
        {/* <Col md="8"> */}
        <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
          {/* <Language /> */}
          {/* <Searchbar /> */}
         
          {/* <BookmarkHeader /> */}
          {/* <MoonLight /> */}
          {/* <CartHeader /> */}
          {currentRoute==='support'?'':<DownloadButton />}
          <Notificationbar totalNotifications={totalNotifications} data={data}  />
          <UserHeader />
        </UL>
        {/* </Col> */}
      </Col>
    </Fragment>
  );
};

export default RightHeader;
