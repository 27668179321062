import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function TotalAlerts({ filters }) {
    const defaultData = [
        { category: 'Helmet', value: 85 },
        { category: 'Vest', value: 30 },
        { category: 'Emergency Exit', value: 55 },
        { category: 'Machine Guard', value: 70 },
        { category: 'MMHE', value: 35 },
    ];

    const [data, setData] = useState(defaultData); // State to store and update data based on filters
    const [lastFilter, setLastFilter] = useState(null); // Track the last applied filter

    // Track shift changes
    useEffect(() => {
        
            setLastFilter('shifts'); // Set 'shifts' as the latest filter
        
    }, [filters?.shifts]);
    useEffect(() => {
       
            setLastFilter('areas'); // Set 'areas' as the latest filter
    
    }, [ filters?.areas]);

    // Update the data based on the latest filter (shift or week)
    useEffect(() => {
        let updatedData = [...defaultData]; // Default data

        
            
            console.log('shift ssss', filters?.shifts[0])
            if(lastFilter=='shifts'){
            if (filters?.shifts.length==1) {
                updatedData = updatedData.map(item => ({
                    ...item,
                    value: item.value*1.5,
                })); // Modify data for Shift 1
            } else if (filters?.shifts.length==2) {
                updatedData = [
                    { category: 'Helmet', value: 40 },
                    { category: 'Vest', value: 20 },
                    { category: 'Emergency Exit', value: 65 },
                    { category: 'Machine Guard', value: 70 },
                    { category: 'MMHE', value: 75 },
                ]; // Shift 2 data
            } else if (filters?.shifts.length==3) {
                updatedData = [
                    { category: 'Helmet', value: 80 },
                    { category: 'Vest', value: 40 },
                    { category: 'Emergency Exit', value: 20 },
                    { category: 'Machine Guard', value: 40 },
                    { category: 'MMHE', value: 60 },
                ]; // Shift 3 data
            }
        }
        else if(lastFilter=='areas'){
            if (filters?.areas.length==1) {
                updatedData = [
                    { category: 'Helmet', value: 60 },
                    { category: 'Vest', value: 25 },
                    { category: 'Emergency Exit', value: 50 },
                    { category: 'Machine Guard', value: 60 },
                    { category: 'MMHE', value: 30 },
                ]; // Week 1 data
            } else if (filters?.areas.length==2) {
                updatedData = [
                    { category: 'Helmet', value: 65 },
                    { category: 'Vest', value: 30 },
                    { category: 'Emergency Exit', value: 55 },
                    { category: 'Machine Guard', value: 65 },
                    { category: 'MMHE', value: 35 },
                ]; // Week 2 data
            } else if (filters?.areas.length==3) {
                updatedData = [
                    { category: 'Helmet', value: 70 },
                    { category: 'Vest', value: 35 },
                    { category: 'Emergency Exit', value: 60 },
                    { category: 'Machine Guard', value: 70 },
                    { category: 'MMHE', value: 40 },
                ]; // Week 3 data
            } else if (filters?.areas.length==4) {
                updatedData = [...defaultData]; // Week 4 is the default data
            }
        }

        setData(updatedData); // Update the data state with new values
    }, [lastFilter, filters]);

    // Create series for the different segments (Low, Medium, Severe)
    // const createSeries = () => {
    //     const blueSeries = [];
    //     const orangeSeries = [];
    //     const redSeries = [];

    //     data.forEach((item) => {
    //         // Blue segment (up to 40)
    //         blueSeries.push(Math.min(item.value, 40));

    //         // Orange segment (41 to 60)
    //         orangeSeries.push(item.value > 40 ? Math.min(item.value, 60) - 40 : 0);

    //         // Red segment (above 60)
    //         redSeries.push(item.value > 60 ? item.value - 60 : 0);
    //     });

    //     return [
    //         { name: 'Low', data: blueSeries, color: '#40a1ff' },
    //         { name: 'Medium', data: orangeSeries, color: '#f9c50a' },
    //         { name: 'Severe', data: redSeries, color: '#ff7340' },
    //     ];
    // };

    // const createSeries = () => {
    //     return [{
    //         name: 'Alerts',
    //         data: data.map(item => item.value),  // Use the values from the `data`
    //         colors: data.map(item => {
    //             if (item.category === 'Helmet' || item.category === 'Emergency Exit' || item.category === 'Machine Guard') {
    //                 return '#ff0000'; // Red for Helmet, Emergency Exit, and Machine Guard
    //             } else if (item.category === 'MMHE') {
    //                 return '#f9c50a'; // Yellow for MMHE
    //             } else if (item.category === 'Vest') {
    //                 return '#40a1ff'; // Blue for Vest
    //             }
    //             // Default color (black) if category doesn't match
    //         }),
    //     }];
    // };

    const createSeries = () => {
        return [
            {
                name: 'High Severity',
                data: data.map(item => 
                    (item.category === 'Helmet' || item.category === 'Emergency Exit' || item.category === 'Machine Guard') 
                        ? item.value : 0),  // Include value for High Severity categories
                color: '#ff0000',  // Red for High Severity
            },
            {
                name: 'Medium Severity',
                data: data.map(item => 
                    item.category === 'MMHE' 
                        ? item.value : 0),  // Include value for Medium Severity category (MMHE)
                color: '#f9c50a',  // Yellow for Medium Severity
            },
            {
                name: 'Low Severity',
                data: data.map(item => 
                    item.category === 'Vest' 
                        ? item.value : 0),  // Include value for Low Severity category (Vest)
                color: '#40a1ff',  // Blue for Low Severity
            }
        ];
    };
    
    

    const options = {
        chart: {
            type: 'bar',
            stacked: true, // Disable stacked bars
        },
        toolbar: {
            show: false
        },
        dataLabels: {
            enabled: false, // Hide the values inside the bars
        },
        tooltip: {
            enabled: true, // Disable tooltip on hover
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadiusWhenStacked: 'all',
                columnWidth: '45%',
                distributed: false, // Ensures each bar gets its assigned color
                borderRadiusApplication: 'around',
                endingShape: 'rounded',
                borderRadius: 8,
            },
        
        },
        colors: data.map((item) => {
            if (item.category === 'Helmet' || item.category === 'Emergency Exit' || item.category === 'Machine Guard') {
                return '#ff0000';  // Red for high severity
            } else if (item.category === 'Vest') {
                return '#40a1ff';  // Blue for low severity
            } else if (item.category === 'MMHE') {
                return '#f9c50a';  // Yellow for medium severity
            }
        }),
        // Manually setting the colors based on the categories
        xaxis: {
            categories: data.map((item) => item.category), // Category labels for the x-axis
            labels: {
                style: {
                    fontSize: '10px', // Set a default font size
                },
                rotate: 0, // Rotate the labels to fit in limited space
                trim: false, // Trim labels if they are too long
            },
        },
        yaxis: {
            title: {
                text: 'Value',
            },
            min: 0, // Set the minimum y-axis value to 0
        },
        title: {
            text: 'Summary of Alerts',
            align: 'center',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: true,
            position: 'bottom',  // Position legend at the bottom
            horizontalAlign: 'center',
            labels: {
                useSeriesColors: false,  // Use the colors defined in the series
            },
            markers: {
                width: 12,
                height: 12,
                radius: 12, // Circular markers for the legend
            },
            formatter: function(val, opts) {
                if (opts.seriesIndex === 0) return 'High Severity'; // Red
                if (opts.seriesIndex === 1) return 'Medium Severity'; // Yellow
                if (opts.seriesIndex === 2) return 'Low Severity'; // Blue
            },
        },
        responsive: [
            {
                breakpoint: 1367,
                options: {
                    chart: {
                        width: '100%',
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '13px', // Adjust font size for smaller screens
                            },
                            rotate: -45, // Rotate labels for better fit
                        },
                    },
                }
            }
        ]
    };
    
    
    

    return (
        <>
            <ReactApexChart
                options={options}
                series={createSeries()} // Series based on updated data
                type="bar"
                height={350}
            />
        </>
    );
}
